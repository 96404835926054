import React, { Fragment } from 'react';

function AlertMessage({ message, title, type }) {
  return (
    <Fragment>
      <div className="py-4 w-full">
        <div className={`${type} ' px-4 py-3 rounded relative'`} role="alert">
          <strong className="font-bold">{title}</strong>{' '}
          <span className="block sm:inline">{message}</span>
        </div>
      </div>
    </Fragment>
  );
}

export default AlertMessage;