import React from 'react';
import { ImageWithFallback } from 'components/ImageWithFallback/ImageWithFallback';
import { ItemInfoAndActions } from './ItemInfoAndActions';

export function ClarityChildArrowLine(props: any) {
	return (<svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} viewBox="0 0 36 36" {...props}><path fill="#7f56d9" d="M24.82 15.8a1 1 0 0 0-1.41 0a1 1 0 0 0 0 1.41L27.2 21H9V3.78a1 1 0 1 0-2 0V21a2 2 0 0 0 2 2h18.15l-3.74 3.75a1 1 0 0 0 0 1.41a1 1 0 0 0 .7.29a1 1 0 0 0 .71-.29L31 22Z" className="clr-i-outline clr-i-outline-path-1"></path><path fill="none" d="M0 0h36v36H0z"></path></svg>);
}

export const ProductListItem = ({
  key, loading, mlItem, catalogProduct, amazonProduct, href, onPublishCatalogProduct, onQuickOptin, onPublishFromAmazon, onConvertToCatalog
}: {
  key: string;
  loading: boolean;
  mlItem: any;
  catalogProduct: any;
  amazonProduct: any;
  href: string;
  onPublishCatalogProduct: () => void;
  onQuickOptin: () => void;
  onPublishFromAmazon: () => void;
  onCancel: () => void;
  onConvertToCatalog: () => void;
}) : any => {
  if(loading) return null;
  return <>
    <div
      key={key}
      className={`h-[80px] w-full px-4 py-2 border-b hover:bg-gray-50 border-gray-200 justify-start items-center gap-3 flex`}>
      { mlItem?.isChild && <ClarityChildArrowLine className='w-8 h-8' /> }
      <ImageWithFallback
        className='w-[75px] h-[75px] rounded-[10px] flex-none'
        src={mlItem?.pictures?.[0]?.url || catalogProduct?.pictures?.[0]?.url || amazonProduct?.images?.[0] || ''}
        alt={mlItem?.title || catalogProduct?.name || amazonProduct?.title} />
      <div className="w-[72%]">
          <div className="text-slate-600 text-sm font-normal font-['Inter'] leading-tight inline-block align-baseline">
            <a href={href} target='_blank'>
              { mlItem?.title || catalogProduct?.name || amazonProduct?.title }
            </a>
          </div>
      </div>
      <div className='w-[20%] flex-none inline-block align-baseline'>
        <ItemInfoAndActions
          className='relative -left-12'
          dataLoaded={!loading}
          mlItem={mlItem}
          catalogProduct={catalogProduct}
          amazonProduct={amazonProduct}
          onQuickOptin={onQuickOptin}
          onPublishFromAmazon={onPublishFromAmazon}
          onPublishCatalogProduct={onPublishCatalogProduct} 
          onConvertToCatalog={onConvertToCatalog}/>
      </div>
    </div>
  </>;
};
