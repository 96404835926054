const CheckIcon = ({ color }) => {
  return (
    <svg
      width='10'
      height='7'
      viewBox='0 0 10 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.33317 1.5L3.74984 6.08333L1.6665 4'
        stroke={color || 'white'}
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CheckIcon;
