const { useQuery, useManualQuery } = require('graphql-hooks');

export const GET_ATTRIBUTE_MATCHES_QUERY = `
query GetAttributeMatches($asin: String!, $categoryId: String!) {
    getAttributesMatches(asin: $asin, categoryId: $categoryId) {
        matches {
            mlAttributeId
            amazonAttributeId
        }
    }
}
`

export const useGetAttributeMatches = (asin: string, categoryId: string) => {
    const { loading, error, data } = useQuery(GET_ATTRIBUTE_MATCHES_QUERY, {
        variables: {
            asin,
            categoryId
        }
    });
    return {
        loading,
        error,
        data
    }
}

export const useGetAttributeMatchesManual = () => {
    const [getAttributeMatches, { loading, data, error }] = useManualQuery(GET_ATTRIBUTE_MATCHES_QUERY);
    return {
        loading,
        error,
        data,
        getAttributeMatches
    }
}