import React, { useState } from 'react';

const DownloadIcon: React.FC<{ className?: string }> = ({ className }) => (
  <div className={className}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.6665 14.1667L9.99984 17.5M9.99984 17.5L13.3332 14.1667M9.99984 17.5V10M16.6665 13.9524C17.6844 13.1117 18.3332 11.8399 18.3332 10.4167C18.3332 7.88536 16.2811 5.83333 13.7498 5.83333C13.5677 5.83333 13.3974 5.73833 13.3049 5.58145C12.2182 3.73736 10.2119 2.5 7.9165 2.5C4.46472 2.5 1.6665 5.29822 1.6665 8.75C1.6665 10.4718 2.36271 12.0309 3.48896 13.1613" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
);

const InvoiceIcon: React.FC<{ className?: string }> = ({ className }) => (
  <div className={className}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6668 1.89105V5.33317C11.6668 5.79988 11.6668 6.03324 11.7577 6.2115C11.8376 6.3683 11.965 6.49578 12.1218 6.57568C12.3001 6.6665 12.5335 6.6665 13.0002 6.6665H16.4423M16.6668 8.32336V14.3332C16.6668 15.7333 16.6668 16.4334 16.3943 16.9681C16.1547 17.4386 15.7722 17.821 15.3018 18.0607C14.767 18.3332 14.067 18.3332 12.6668 18.3332H7.3335C5.93336 18.3332 5.2333 18.3332 4.69852 18.0607C4.22811 17.821 3.84566 17.4386 3.60598 16.9681C3.3335 16.4334 3.3335 15.7333 3.3335 14.3332V5.6665C3.3335 4.26637 3.3335 3.56631 3.60598 3.03153C3.84566 2.56112 4.22811 2.17867 4.69852 1.93899C5.2333 1.6665 5.93336 1.6665 7.3335 1.6665H10.01C10.6215 1.6665 10.9272 1.6665 11.2149 1.73558C11.47 1.79682 11.7139 1.89783 11.9375 2.0349C12.1898 2.18951 12.406 2.4057 12.8384 2.83808L15.4953 5.49493C15.9276 5.92731 16.1438 6.1435 16.2984 6.39579C16.4355 6.61947 16.5365 6.86333 16.5978 7.11842C16.6668 7.40614 16.6668 7.71188 16.6668 8.32336Z" stroke="#7F56D9" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
);

const CheckIcon: React.FC = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 3L4.5 8.5L2 6" stroke="#027A48" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export interface Invoice {
  id: string;
  date: string;
  amount: number;
  plan: string;
  status: 'Pago' | 'Pendiente';
}

interface InvoiceListProps {
  invoices: Invoice[];
}

export const InvoiceList: React.FC<InvoiceListProps> = ({ invoices }) => {
  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
  const [allSelected, setAllSelected] = useState(false);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedInvoices(invoices.map(invoice => invoice.id));
      setAllSelected(true);
    } else {
      setSelectedInvoices([]);
      setAllSelected(false);
    }
  };

  const handleSelectInvoice = (id: string) => {
    setSelectedInvoices(prev => {
      const newSelected = prev.includes(id) ? prev.filter(i => i !== id) : [...prev, id];
      setAllSelected(newSelected.length === invoices.length);
      return newSelected;
    });
  };

  return (
    <div className="mt-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-gray-800">Recibos</h2>
        <button 
          className={`bg-white text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded-lg shadow-sm flex items-center ${
            selectedInvoices.length > 0 ? 'hover:bg-gray-50' : 'opacity-50 cursor-normal'
          }`}
          disabled={selectedInvoices.length === 0}
        >
          <DownloadIcon className="w-5 h-5 mr-2" />
          {allSelected ? 'Descargar todos' : 'Descargar seleccionados'}
        </button>
      </div>
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="w-12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <input
                  type="checkbox"
                  checked={allSelected}
                  onChange={handleSelectAll}
                  className="form-checkbox h-5 w-5 text-primary-600 border-gray-300 rounded focus:ring-primary-500 focus:ring-offset-0"
                />
              </th>
              <th className="w-1/3 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Factura</th>
              <th className="w-1/6 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Fecha de facturación
                <span className="ml-1 inline-block">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 2.5V9.5M6 9.5L9 6.5M6 9.5L3 6.5" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </span>
              </th>
              <th className="w-1/6 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Estado</th>
              <th className="w-1/6 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Monto</th>
              <th className="w-1/6 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Plan</th>
              <th className="w-1/6 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {invoices.map((invoice) => (
              <tr key={invoice.id} className="hover:bg-gray-50">
                <td className="w-12 px-6 py-4 whitespace-nowrap">
                  <input
                    type="checkbox"
                    checked={selectedInvoices.includes(invoice.id)}
                    onChange={() => handleSelectInvoice(invoice.id)}
                    className="form-checkbox h-5 w-5 text-primary-600 border-gray-300 rounded focus:ring-primary-500 focus:ring-offset-0"
                  />
                </td>
                <td className="w-1/3 px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <InvoiceIcon className="flex-shrink-0 h-5 w-5 text-primary-600 mr-3" />
                    <span className="text-sm font-medium text-gray-900">{invoice.id}</span>
                  </div>
                </td>
                <td className="w-1/6 px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invoice.date}</td>
                <td className="w-1/6 px-6 py-4 whitespace-nowrap">
                  {invoice.status === 'Pago' ? (
                    <span className="px-2 py-1 inline-flex items-center text-xs leading-5 font-medium rounded-full bg-green-50 text-green-700">
                      <CheckIcon />
                      <span className="ml-1">Pago</span>
                    </span>
                  ) : (
                    <span className="px-2 py-1 inline-flex text-xs leading-5 font-medium rounded-full bg-yellow-100 text-yellow-700">
                      {invoice.status}
                    </span>
                  )}
                </td>
                <td className="w-1/6 px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-medium">USD {invoice.amount.toFixed(2)}</td>
                <td className="w-1/6 px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invoice.plan}</td>
                <td className="w-1/6 px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <a href="#" className="text-primary-600 hover:text-primary-900">Descargar</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
