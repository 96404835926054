import { useQuery, useManualQuery } from 'graphql-hooks';

const UseUploadImageToMLQuery = `
mutation UploadImageToML($base64: String!) {
  uploadImageToML(base64: $base64) {
    id
    variations {
      url
      size
      secure_url
    }
  }
}`;

const useUploadImageToML = (base64) => {
  const { loading, error, data } = useQuery(UseUploadImageToMLQuery, {
    variables: {
      base64,
    },
  });
  return {
    loading,
    error,
    data: data?.uploadImageToML ? data.uploadImageToML : data,
  };
};

const useUploadImageToMLManual = () => {
  const [uploadImageToML, { loading, error, data }] = useManualQuery(
    UseUploadImageToMLQuery
  );
  return {
    uploadImageToML,
    loading,
    error,
    data: data?.uploadImageToML ? data.uploadImageToML : data,
  };
};

export {
  UseUploadImageToMLQuery,
  useUploadImageToML,
  useUploadImageToMLManual,
};
