import React, { useEffect, useState } from 'react';
import { AddManualProductCopilot } from './AddManualProductCopilot';
import { AddCatalogProduct } from '../catalog/AddCatalogProduct';
import { useGetProductByAsinManual } from 'hooks/useGetProductByAsin';
import { useGetCatalogProductByUPCManual } from 'hooks/useGetCatalogProductByUPC';
import Spinner from 'components/utils/iconComponents/Spinner';

interface Product {
    id: string;
    price: number;
    gtin: string;
}

interface ProductData {
    getProductByAsin: Product | null;
    getProductByUpc: Product | null;
}

export const SelectCatalogOrCopilot = ({
  identifier,
  onCancel,
  onPublish,
}: {
  identifier: string;
  onCancel: () => void;
  onPublish: (permalink: string) => void;
}) => {

    const { loading: loadingAsin, data: dataAsin, error: errorAsin, getProductByAsin } = useGetProductByAsinManual();
    const { loading: loadingUpc, data: dataUpc, error: errorUpc, getCatalogProductByUPC } = useGetCatalogProductByUPCManual();
    const [product, setProduct] = useState<Product | null>(null);

    const isAsin = /^B0[A-Z0-9]{8}$/.test(identifier);

    useEffect(() => {
        if (isAsin) {
            getProductByAsin({
                variables: {
                    asin: identifier,
                },
            });
        } else {
            getCatalogProductByUPC({
                variables: {
                    upc: identifier,
                },
            });
        }
    }, [identifier, isAsin]);

    useEffect(() => {
        if (isAsin && dataAsin?.getProductByAsin && dataAsin.getProductByAsin.id !== product?.id) {
            setProduct(dataAsin.getProductByAsin);
        } else if (!isAsin && dataUpc?.getCatalogProductByUPC && dataUpc.getCatalogProductByUPC.id !== product?.id) {
            setProduct(dataUpc.getCatalogProductByUPC);
        }
    }, [dataAsin, dataUpc, isAsin]);

    const loading = isAsin ? loadingAsin : loadingUpc;
    const error = isAsin ? errorAsin : errorUpc;
    const data = isAsin ? dataAsin : dataUpc;

    if (loading) {
        return (
            <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50'>
                <div className='bg-white pt-4 pr-4 pb-4 pl-4 rounded-md shadow-sm w-[52%] h-40 flex flex-col items-center justify-center'>
                    <Spinner />
                </div>
            </div>
        );
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return product?.id ? (
        <AddCatalogProduct
            product={product}
            visible={true}
            onCancel={onCancel}
            onPublish={onPublish}
            asinPrice={data?.getProductByAsin?.price || data?.getProductByUpc?.price}
            gtin={data?.getProductByAsin?.gtin || data?.getProductByUpc?.gtin}
            asin={isAsin ? identifier : data?.getCatalogProductByUPC?.upc}
            closeButtonLabel="Enviar link"
        />
    ) : (
        <AddManualProductCopilot
            asin={identifier}
            onCancel={onCancel}
            onPublish={onPublish}
        />
    );
};
