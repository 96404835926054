import { useState } from 'react';
import ArticleIcon from './utils/iconComponents/ArticleIcon';
import MessageIcon from './utils/iconComponents/MessageIcon';

const QuestionFilterTab = ({
  title,
  selectedTab,
  notificationsCount,
  onClick,
}) => {
  return (
    <div
      className={`m-2 cursor-pointer relative mr-7 ${
        selectedTab === title ? 'text-primary-700' : 'text-gray-500'
      }`}
      onClick={() => onClick(title)}
    >
      {notificationsCount && notificationsCount > 0 ? (
        <span className='bg-red absolute -right-7 -top-3 bg-red-500 text-white text-xs rounded-full py-1 px-2'>
          {notificationsCount}
        </span>
      ) : null}
      <span>{title}</span>
    </div>
  );
};

const tabNames = ['Sin responder', 'Respondidas', 'Historial'];

export const QuestionsFilterTabs = ({ onTabSelected, newQuestions }) => {
  const [activeFilter, setActiveFilter] = useState(tabNames[0]);

  const onTabClicked = (tab) => {
    setActiveFilter(tab);
    onTabSelected(tabNames.indexOf(tab));
  };

  return (
    <div className='flex font-semibold text-sm text-gray-500 items-center h-[60px] w-full'>
      <div className='flex w-[5%]'><MessageIcon /></div>
      <div className='flex w-[95%]'>
        <QuestionFilterTab
          title='Sin responder'
          notificationsCount={newQuestions}
          selectedTab={activeFilter}
          onClick={onTabClicked}
        />
        <QuestionFilterTab
          title='Respondidas'
          selectedTab={activeFilter}
          onClick={onTabClicked}
        />
        <QuestionFilterTab
          title='Historial'
          selectedTab={activeFilter}
          onClick={onTabClicked}
        />
      </div>
    </div>
  );
};
