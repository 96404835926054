import React from 'react';
import axios from 'axios';
import DocumentIcon from 'components/utils/iconComponents/DocumentIcon';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;
// const API_BASE_URL = 'http://localhost';

const MessageBox = ({ messagesBuyer, orderInfo }) => {
  // console.info('messagesBuyer..', messagesBuyer);
  // console.info('orderInfo..', orderInfo);

  const handlerFileDownload = (fileName, type) => {
    const token = localStorage.getItem('session')
      ? JSON.parse(localStorage.getItem('session')).sessionInfo?.token
      : '';
    // console.info('token...', token);

    const url = `${API_BASE_URL}/downloaded-files?fileName=${fileName}`;
    axios(`${url}`, {
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        const file = new Blob([response.data], { type });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const ChatDateComponent = ({ message, buyerSide }) => {
    const d = new Date(message.message_date.created);
    const day = d.getDate();
    const month = d.getMonth();
    const year = d.getFullYear().toString().substr(-2);
    const hours = d.getHours();
    const minutes = d.getMinutes();

    return (
      <span className={buyerSide && 'text-gray-600 text-xs'}>
        {`${day}/${month + 1}/${year} ${hours}:${minutes}`}
      </span>
    );
  };

  return (
    <>
      {messagesBuyer &&
        messagesBuyer.map((message) => {
          return (
            <>
              <div
                key={Math.random() * 1000}
                className={`flex p-1 ${
                  message.from.user_id == orderInfo.seller_id && 'justify-end'
                }`}
              >
                <div className='min-w-0 max-w-[50%]'>
                  <div
                    className={`w-full justify-between p-2 rounded-lg text-sm font-medium ${
                      message.from.user_id == orderInfo.seller_id
                        ? 'text-white bg-primary-700'
                        : 'text-primary-700 bg-primary-50'
                    }`}
                  >
                    <div>
                      <div>
                        <span>{message.text}</span>
                      </div>
                    </div>
                  </div>
                  <div className='flex justify-end text-xs font-normal text-gray-600'>
                    <ChatDateComponent message={message} buyerSide={false} />
                  </div>
                </div>
              </div>
              <div
                key={Math.random() * 2000}
                className={`block p-1 ${
                  message.from.user_id == orderInfo.seller_id && 'justify-end'
                }`}
              >
                {message &&
                  message.message_attachments !== null &&
                  message.message_attachments.map((fileInfo) => (
                    <div className='w-[50%]' key={Math.random() * 100}>
                      <div
                        key={fileInfo.filename}
                        className={`w-full justify-between p-0 rounded-lg`}
                        onClick={() =>
                          handlerFileDownload(fileInfo.filename, fileInfo.type)
                        }
                      >
                        <div>
                          <div className='h-16 p-1.5 flex border border-[#E4E7EC] rounded-lg mt-2 hover:bg-gray-50 cursor-pointer'>
                            <div className='w-[10%] flex'>
                              <span className='h-full w-full flex justify-center items-start'>
                                <DocumentIcon />
                              </span>
                            </div>
                            <div className='w-[90%] grid'>
                              <span className='text-[#344054] text-sm font-medium truncate'>
                                {fileInfo.original_filename}
                              </span>
                              <div className='flex justify-between'>
                                <span className='text-[#475467] text-sm font-normal'>
                                  {fileInfo.convertedSize}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className='flex justify-end text-xs font-normal text-gray-600'>
                            <ChatDateComponent
                              message={message}
                              buyerSide={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          );
        })}
    </>
  );
};

export default MessageBox;
