const { useManualQuery } = require('graphql-hooks');

const SendMessageToBuyer = `mutation SendMessageToBuyer($orderId: String, $buyerId: String, $dataInput: String, $attachments : String){
  sendMessageToBuyer(orderId: $orderId, buyerId: $buyerId, dataInput: $dataInput, attachments: $attachments)
  {
    messages {
      id
      from {
        user_id
      }
      to {
        user_id
      }
      status
      text
      message_date {
        read
        received
        available
        notified
        created
        timeLapsed
      }
      message_attachments {
        original_filename
        size
        filename
        type
        convertedSize
      }
    }
  }
}`;

const useSendMessageBuyerManual = () => {
  const [sendMessageToBuyer, { loading, error, data }] =
    useManualQuery(SendMessageToBuyer);

  const { messages } = data ? data.sendMessageToBuyer : { messages: [] };

  return { sendMessageToBuyer, loading, error, messages };
};

export { useSendMessageBuyerManual };
