const { useManualQuery } = require('graphql-hooks');

const searchUnreadMessageQuery = `query SearchUnreadMessages {
  searchUnreadMessages {
    orders {
      item {
        title
        id
        category_id
        condition
        seller_custom_field
        thumbnail
        permalink
        amazonUrl
      }
      buyer {
          id
          nickname
      }
      id
      order_id
      pack_id
      seller_id
      unit_price
      currency_id
      quantity
      lastUpdated
      dateCreated
      total_amount
      status
    }
  }
}`;

const useSearchUnreadMessageManual = () => {
  const [searchUnreadMessages, { loading, error, data }] = useManualQuery(
    searchUnreadMessageQuery
  );

  const { orders } = data
    ? data.searchUnreadMessages
    : { orders: [] };

  return { searchUnreadMessages, loading, error, orders };
};

export { useSearchUnreadMessageManual };
