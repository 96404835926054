import { useState } from 'react';
import SaveButtonComponent from '../utilsComponents/SaveButtonComponent';
import { RemoveRowButtonComponent } from '../utilsComponents/RemoveRowButtonComponent';

const BlackList = ({
  blackList,
  setBlackList,
  onSaveSettings,
  isValidated,
  isValidatedKg,
}) => {
  const [enabledButton, setEnabledButton] = useState(false);
  const [blackListInput, setBalckListInput] = useState('');
  const [selectedItemIdx, setSelectedItemIdx] = useState('');
  const [messageError, setMessageError] = useState('');

  const handlerOnChangeInput = (value) => {
    setMessageError('');
    setBalckListInput(value);
    /^\S[a-zA-Z-]+(?: \S[a-zA-Z-]+)*$/gm.test(value)
      ? setEnabledButton(true)
      : setEnabledButton(false);
  };

  const onRemoveRow = (position) => {
    const newBlackList = [...blackList];
    newBlackList.splice(position, 1);
    setBlackList(newBlackList);
  };

  const handlerAddBlackListItem = () => {
    const index = blackList.findIndex((item) => {
      return item.toLowerCase() === blackListInput.toLowerCase();
    });

    if (index !== -1) {
      setMessageError('Ya se encuentra asociado un item con el mismo valor');
    } else {
      setMessageError('');
      setBlackList([...blackList, blackListInput.trim()]);
      setBalckListInput('');
      setEnabledButton(false);
    }
  };

  const handlerEdit = (idx) => {
    setBalckListInput(blackList[idx]);
    setEnabledButton(true);
    setSelectedItemIdx(idx);
  };

  const handlerUpdateBlackList = (idx) => {
    const newValueBlackList = blackList.map((item, i) => {
      if (i === idx) {
        return blackListInput;
      } else {
        return item;
      }
    });
    setBlackList(newValueBlackList);
    setBalckListInput('');
    setSelectedItemIdx('');
    setEnabledButton(false);
  };

  return (
    <div className='flex flex-col justify-between h-full'>
      <div className='w-full flex max-h-[580px] h-[85%]'>
        <div className='w-[50%] border-2 border-r-primary-100 border-x-white border-y-white'>
          <div className='mb-2'>
            <div className='flex w-full'>
              <input
                type='text'
                name={blackListInput}
                id={blackListInput}
                value={blackListInput}
                className='block w-[50%] rounded-md border-0 border-gray-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-2 mr-4'
                onChange={(e) => handlerOnChangeInput(e.target.value)}
              />
              <button
                className={`bg-primary-100 border border-primary-100 hover:border-primary-500 text-primary-700 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md transition ease-in-out duration-150 ${
                  !enabledButton &&
                  'cursor-not-allowed bg-gray-200 border border-gray-200 hover:border-gray-100 text-gray-300'
                }`}
                type='button'
                onClick={() =>
                  selectedItemIdx.length === 0
                    ? handlerAddBlackListItem()
                    : handlerUpdateBlackList(selectedItemIdx)
                }
                disabled={enabledButton ? false : true}
              >
                {selectedItemIdx ? 'Editar' : 'Agregar'}
              </button>
              <button
                type='button'
                className='bg-red-200 border border-red-200 hover:border-red-500 inline-flex items-center px-4 py-2 ml-1 mr-2.5 font-semibold leading-6 text-sm shadow rounded-md text-red-700 transition ease-in-out duration-150'
                onClick={() => {
                  setBalckListInput('');
                  setSelectedItemIdx('');
                  setMessageError('');
                  setEnabledButton(false);
                }}
              >
                Cancelar
              </button>
            </div>
            <div>
              <span className='text-red-700 text-sm italic'>
                {messageError}
              </span>
            </div>
          </div>
        </div>
        <div className='w-[50%]'>
          <div className='mb-2 w-full'>
            <ul className='ml-8'>
              {blackList?.map((item, idx) => (
                <li
                  key={item}
                  className='w-full bg-primary-100 text-primary-700 py-2 my-2 px-2 rounded-md flex justify-between'
                >
                  <div className='flex'>{item}</div>
                  <div className='flex'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='w-6 h-6 cursor-pointer'
                      onClick={() => handlerEdit(idx)}
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10'
                      />
                    </svg>
                    <RemoveRowButtonComponent
                      position={idx}
                      onRemoveRow={onRemoveRow}
                      iconTitle={'Remover item'}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className='w-full flex mt-2 justify-end'>
        <SaveButtonComponent
          onSaveSettings={onSaveSettings}
          isValidated={isValidated}
          isValidatedKg={isValidatedKg}
        />
      </div>
    </div>
  );
};

export default BlackList;
