const TwoShapeIcon = () => {
  return (
    <svg
      width='16'
      height='12'
      viewBox='0 0 16 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.3335 7.66663L7.76165 10.8807C7.8491 10.9244 7.89283 10.9463 7.9387 10.9549C7.97932 10.9625 8.02101 10.9625 8.06163 10.9549C8.1075 10.9463 8.15122 10.9244 8.23868 10.8807L14.6668 7.66663M1.3335 4.3333L7.76165 1.11922C7.8491 1.07549 7.89283 1.05363 7.9387 1.04502C7.97932 1.0374 8.02101 1.0374 8.06163 1.04502C8.1075 1.05363 8.15122 1.07549 8.23868 1.11922L14.6668 4.3333L8.23868 7.54737C8.15122 7.5911 8.1075 7.61296 8.06163 7.62157C8.02101 7.62919 7.97932 7.62919 7.9387 7.62157C7.89283 7.61296 7.8491 7.5911 7.76165 7.54737L1.3335 4.3333Z'
        stroke='#7F56D9'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default TwoShapeIcon;
