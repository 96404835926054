const TickIcon = () => {
  return (
    <svg
      width='21'
      height='18'
      viewBox='0 0 21 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.4942 0.816769L6.56084 12.3334L3.39417 8.9501C2.81084 8.4001 1.89417 8.36677 1.22751 8.83344C0.577506 9.31677 0.394172 10.1668 0.794172 10.8501L4.54417 16.9501C4.91084 17.5168 5.54417 17.8668 6.26084 17.8668C6.94417 17.8668 7.59417 17.5168 7.96084 16.9501C8.56084 16.1668 20.0108 2.51677 20.0108 2.51677C21.5108 0.983436 19.6942 -0.366564 18.4942 0.800103V0.816769Z'
        fill='#7F56D9'
      />
    </svg>
  );
};

export default TickIcon