import { memoize } from 'lodash';

const BLACK_LIST_CATEGORIES = [
  'MLU1743', // AUTOS, MOTOS Y OTROS
  'MLU1540', // SERVICIOS
  'MLU1459', // INMUEBLES
];

function getFilteredCategoriesList(data: any[]) {
  return data.filter((val) => {
    return !BLACK_LIST_CATEGORIES.includes(val.id);
  });
}

function getOptionsSubcategoryList(categoriesList: any[]) {
  return categoriesList.map((child: any) => ({
    label: child.name,
    value: child.id,
  }));
}

function useGetCategoriesFromML() {
  const getCategoriesList = async (catId: string = '') => {
    let data : any = await fetchCategoriesList(catId);
    if (catId) {
      data = data?.children_categories?.length ? data.children_categories : [];
    } else {
      data = getFilteredCategoriesList(data);
    }
    data = getOptionsSubcategoryList(data);
    return data;
  };

  return {
    getCategoriesList,
  };
}

export { useGetCategoriesFromML, fetchCategoriesList };

const fetchCategoriesList = async (catId: string): Promise<{ data: any }> => {
  return await fetch(`${process.env.REACT_APP_BACKEND_URL}/categories/${catId || ''}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${ process.env.TOKEN || JSON.parse(localStorage.session)?.sessionInfo?.token}`,
    },
  }).then(async (res) => {
    const data = await res.json();
    return data;
  });
};
