import React, { useState } from 'react';
import { TrainingForm } from './TrainingForm';
import { CounterViewer } from './CounterViewer';

export const validateAsin = (asin: string) => {
    return asin.length === 10 && asin.match(/^[A-Z0-9]+$/);
}

export const EXCLUDED_ATTRIBUTES = ['GTIN', 'BRAND', 'MODEL'];

export const TrainingPage = () => {
    enum Mode {
        TRAINING,
        SHOW_COUNTER
    }
    const [mode, setMode] = useState<Mode>(Mode.TRAINING);
    const [count, setCount] = useState<any>(0);
    return mode === Mode.TRAINING ? <TrainingForm onExampleSaved={(count) => {
        setCount(count);
        setMode(Mode.SHOW_COUNTER);
    }} /> : <CounterViewer count={count} onContinue={() => setMode(Mode.TRAINING)} />;
};