import { useQuery, useManualQuery } from 'graphql-hooks';

const GET_FILLED_ATTRIBUTES_QUERY = `
query GetFilledAttributes($asin: String!, $jsonToFill: String!, $amazonAttributes: String!) {
    getFilledAttributes(asin: $asin, jsonToFill: $jsonToFill, amazonAttributes: $amazonAttributes) {
        filledAttributes {
            id
            value
        }
    }
}
`

const useGetFilledAttributes = (asin: string, jsonToFill: string, amazonAttributes: string) => {
    const { loading, error, data } = useQuery(GET_FILLED_ATTRIBUTES_QUERY, {
        variables: {
            asin,
            jsonToFill,
            amazonAttributes
        }
    });
    return {
        loading,
        error,
        data
    }
}

const useGetFilledAttributesManual = () => {
    const [getFilledAttributes, { loading, data, error }] = useManualQuery(GET_FILLED_ATTRIBUTES_QUERY);
    return {
        loading,
        error,
        data,
        getFilledAttributes
    }
}

export {
    GET_FILLED_ATTRIBUTES_QUERY,
    useGetFilledAttributes,
    useGetFilledAttributesManual
}