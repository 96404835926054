import { useQuery, useManualQuery } from 'graphql-hooks';

const GetAmazonTreeNodeQuery = `
  query GetAmazonTreeNode($id: String!, $levels: Int!) {
    getAmazonTreeNode(id: $id, levels: $levels) {
      id
      name
      children {
        id
        name
        childrenIds
      }
    }
  }
`;

export const useGetAmazonTreeNode = (id: string, levels = 1) => {
  const { loading, error, data } = useQuery(GetAmazonTreeNodeQuery, {
    variables: {
      id,
      levels,
    },
  });
  return { loading, error, data };
};

export const useGetAmazonTreeNodeManual = () => {
  const [getAmazonTreeNode, { loading, error, data }] = useManualQuery(
    GetAmazonTreeNodeQuery
  );
  return { getAmazonTreeNode, loading, error, data };
};

export { GetAmazonTreeNodeQuery };
