import { RemoveRowButtonComponent } from './utilsComponents/RemoveRowButtonComponent';

const CategoryMeliSelectedItem = ({
  path,
  percentage,
  onPercentageChange,
  onRemoveRow,
  position,
  categoryId,
  onHandlerEdit
}) => {
  const handlerOnPercentageChange = (value) => {
    onPercentageChange(value);
  };
  return (
    <div className='flex w-full mt-2'>
      <div className={`w-[55%]`}>
        <span
          className='w-full h-full rounded items-center justify-start flex truncate text-ellipsis text-sm'
          title={path}
        >
          {path}
        </span>
      </div>
      <div className='w-[15%] ml-2.5 mr-0'>
        <span className='w-[90%] h-full bg-primary-100 text-primary-700 rounded-md items-center justify-center flex'>
          %
        </span>
      </div>
      <div className='w-[15%]'>
        <input
          type='number'
          min={0}
          max={100}
          maxLength={3}
          size={4}
          className='block w-[98%] rounded-md border-0 border-gray-300 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-2'
          value={percentage}
          onChange={(e) => handlerOnPercentageChange(e.target.value)}
        />
      </div>
      <span className='w-[10%] h-full mt-2 flex'>
        <RemoveRowButtonComponent
          position={position}
          onRemoveRow={onRemoveRow}
          iconTitle='Remover fila'
        />
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth='1.5'
          stroke='currentColor'
          className='w-6 h-6 cursor-pointer text-primary-700'
          onClick={() => onHandlerEdit(categoryId)}
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10'
          />
        </svg>
      </span>
    </div>
  );
};

export default CategoryMeliSelectedItem;
