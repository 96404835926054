import React from 'react';

interface PeriodSelectorProps {
	currentView: string;
	periods?: { id: string; label: string }[];
	onViewChange: (view: string) => void;
}

const defaultPeriods = [
	{ id: 'year', label: 'Año' },
	{ id: 'month', label: 'Mes' },
	{ id: 'week', label: 'Semana' },
];

export const PeriodSelector: React.FC<PeriodSelectorProps> = ({ currentView, periods = defaultPeriods, onViewChange }) => {
	return <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
		{periods.map((period) => (
			<span
				key={period.id}
				style={{
					marginLeft: '10px',
					cursor: 'pointer',
					fontWeight: currentView === period.id ? 'bold' : 'normal',
					color: currentView === period.id ? '#9E77ED' : '#666',
				}}
				onClick={() => onViewChange(period.id)}
			>
				{period.label}
			</span>
		))}
	</div>

};
