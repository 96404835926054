import { useQuery, useManualQuery } from 'graphql-hooks';

const searchMlstoreSettingsQuery = `
query searchMlstoreSettings {
  searchMlstoreSettings {
    settings
  }
}
`;

const useSearchMlstoreSettingsManual = () => {
  const [searchMlstoreSettings, { loading, error, data }] = useManualQuery(searchMlstoreSettingsQuery);
  const { settings } = data ? data.searchMlstoreSettings : { settings: {} };
  return { searchMlstoreSettings, loading, error, settings }; 
}

export {
  useSearchMlstoreSettingsManual,
  searchMlstoreSettingsQuery
};