import { useManualQuery } from 'graphql-hooks';

const UpdateItemById = `
  mutation updateItemById($itemId: String, $formData: JSON) {
    updateItemById(itemId: $itemId, formData: $formData) {
      payload
    }
  }
`;

const useEditItemByIdManual = () => {
  const [updateItemById, { loading, error, data: updatedItemResponse }] = useManualQuery(UpdateItemById);
  return { updateItemById, loading, error, updatedItemResponse };
};

export {
  UpdateItemById,
  useEditItemByIdManual,
};