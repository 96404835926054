import CheckIcon from 'components/utils/iconComponents/CheckIcon';
import ChevronDown from 'components/utils/iconComponents/ChevronDown';

const NewModalShipping = ({ setlectedTypeShipping, handlerOpenDiv }) => {
  return (
    <div className='mt-8'>
      <div className='w-full flex justify-between items-center pb-[6px]'>
        <span className='text-sm font-medium text-gray-700'>Envio</span>
        <span
          className='text-gray-200 font-semibold cursor-pointer'
          onClick={handlerOpenDiv}
          aria-hidden='true'
        >
          <ChevronDown />
        </span>
      </div>
      <div className='w-full'>
        <div className='w-full h-[72px] border-2 border-primary-600 bg-primary-50 rounded-lg flex p-4'>
          <div className='w-[10%] flex'>
            <span className='flex justify-center items-center bg-primary-200 border-primary-50 rounded-full w-[32px] h-[32px]'>
              {setlectedTypeShipping.icon}
            </span>
          </div>
          <div className='w-[70%]'>
            <div className='w-full flex'>
              <span className='w-full text-sm font-medium text-primary-800'>
                {setlectedTypeShipping.shippingType}
              </span>
            </div>
            <div className='w-full flex'>
              <span className='w-full text-sm font-normal text-primary-700'>
                {setlectedTypeShipping.shippingOwner}
              </span>
            </div>
          </div>
          <div className='w-[20%] flex justify-between px-[5px]'>
            <span className='w-[61px] h-[22px] rounded-lg py-[2px] px-[8px] bg-orange-500 text-xs font-medium text-white'>
              Mejorar
            </span>
            <span className='w-[16px] h-[16px] rounded-full bg-primary-600 border-primary-600 flex justify-center items-center'>
              <CheckIcon />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewModalShipping;
