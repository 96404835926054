import { emailValidator } from 'hooks/useQueryParams';
import React, { useState } from 'react';
import {
  hideMessage,
  useCreateUserForStoreMutation,
  useUpdateUserPermissionsForStoreMutation,
} from 'store';
import { UserItemList } from './UsersPage';
import { useDispatch } from 'react-redux';

interface PermissionOption {
  value: string;
  label: string;
}
interface PermissionSelected {
  id: string;
}

export const PERMISSION_OPTIONS: PermissionOption[] = [
  {
    label: 'Publicación Manual',
    value: 'manual_publishing',
  },
  {
    label: 'Publicación de Catálogo',
    value: 'catalog_publishing',
  },
  {
    label: 'Lectura de Mensajes',
    value: 'message_reading',
  },
  {
    label: 'Respuesta a Mensajes',
    value: 'message_writing',
  },
];

export const EditUserPermissions = (userItemList: UserItemList) => {
  const FnEditUserPerm = () => {
    const dispatch = useDispatch();
    const [error, setError] = useState<string>('');
    const [perm, setPerm] = useState<Set<string>>(
      new Set(userItemList.permissions)
    );
    const [updatePermissions] = useUpdateUserPermissionsForStoreMutation();

    const changeCheck = (evt: any) => {
      const newSet = new Set(perm);
      if (!evt.target.checked) {
        newSet.delete(evt.target.value);
        setPerm(newSet);
      } else {
        newSet.add(evt.target.value);
        setError('');
      }
      setPerm(newSet);
    };

    const savePermissionsList = async () => {
      if (perm.size === 0) {
        setError('Debes seleccionar al menos un permiso');
        return;
      }
      setError('');
      try {
        await updatePermissions({
          data: {
            id: userItemList.id,
            permissions: Array.from(perm),
          },
        }).unwrap();
        dispatch(hideMessage());
      } catch (err: any) {
        console.log('>> Error on update permissions: ', err);
        setError(err?.data?.message);
      }
    };

    return (
      <div>
        <h3 className='font-semibold text-xl mt-8 mb-3'>
          Editar permisos:{' '}
          <span className='text-primary-700'>{userItemList.email}</span>
        </h3>
        <div className='text-left mt-6'>
          <div className='pl-4 grid grid-cols-2 gap-2'>
            {PERMISSION_OPTIONS.map((popt: PermissionOption, idx: number) => {
              return (
                <label key={`perm-${idx}`}>
                  <input
                    type='checkbox'
                    name='perm'
                    checked={perm.has(popt.value) ? true : false}
                    className='permission-check'
                    value={popt.value}
                    onChange={(evt) => {
                      changeCheck(evt);
                    }}
                  />{' '}
                  {popt.label}
                </label>
              );
            })}
          </div>
        </div>
        <div className='mt-8'>
          <button
            data-modal-hide='popup-modal'
            type='button'
            className='text-white bg-primary-700 hover:bg-primary-500 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2'
            onClick={() => {
              savePermissionsList();
            }}
          >
            Guardar cambios
          </button>
          {error && (
            <div className='text-center text-red-500 text-sm mt-4'>{error}</div>
          )}
        </div>
      </div>
    );
  };

  return FnEditUserPerm;
};

interface AddUserProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddUser: React.FC<AddUserProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [permissions, setPermissions] = useState<string[]>([]);
  const [error, setError] = useState('');
  const [addUserToStore] = useCreateUserForStoreMutation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden');
      return;
    }

    try {
      await addUserToStore({
        data: {
          email,
          password,
          permissions,
        },
      }).unwrap();
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setPermissions([]);
      onClose();
    } catch (err) {
      console.error('Failed to add user:', err);
      setError('Error al agregar usuario. Por favor, intente de nuevo.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-50 flex justify-center items-center backdrop-blur-sm">
      <div className="bg-white p-8 shadow-xl rounded-md w-1/2 max-w-md">
        <h2 className="text-2xl font-bold mb-6">Agregar nuevo usuario</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-primary-700"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
              Contraseña
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-primary-700"
            />
          </div>
          <div>
            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 mb-1">
              Confirmar Contraseña
            </label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-primary-700"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Permisos</label>
            {PERMISSION_OPTIONS.map((option) => (
              <div key={option.value} className="flex items-center mt-2">
                <input
                  type="checkbox"
                  id={option.value}
                  value={option.value}
                  checked={permissions.includes(option.value)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setPermissions([...permissions, option.value]);
                    } else {
                      setPermissions(permissions.filter((p) => p !== option.value));
                    }
                  }}
                  className="h-4 w-4 text-primary-700 focus:ring-primary-700 border-gray-300 rounded"
                />
                <label htmlFor={option.value} className="ml-2 block text-sm text-gray-900">
                  {option.label}
                </label>
              </div>
            ))}
          </div>
          {error && (
            <div className="text-red-500 text-sm">{error}</div>
          )}
          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-700"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-primary-700 text-white rounded-md text-sm font-medium hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-primary-700"
            >
              Agregar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUser;
