import { useQuery, useManualQuery } from 'graphql-hooks';

const searchLabelsQuery = `
query searchLabels($query: String!, $offset: Int, $limit: Int, $module: String) {
  searchLabels (query: $query, offset: $offset, limit: $limit, module: $module) {
    offset
    total
    labels {
      id
      title
      description
      module
      deleted
    }
  }
}
`;

const useSearchLabels = (query) => {
  const { loading, error, data } = useQuery(searchLabelsQuery, {
    variables: {
      query,
      module,
      offset:0,
      limit:1
    }
  });
  return { loading, error, data }; 
}

const useSearchLabelsManual = () => {
  const [searchLabels, { loading, error, data }] = useManualQuery(searchLabelsQuery);
  const { labels, offset, total } = data ? data.searchLabels : { labels: [], offset: 0, total: 0 };
  return { searchLabels, loading, error, labels, offset, total }; 
}

export {
  useSearchLabels,
  useSearchLabelsManual,
  searchLabelsQuery
};