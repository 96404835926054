import { useQuery, useManualQuery } from 'graphql-hooks';

const SearchAttrCategory = `
  query SearchAttrCategory($itemId: String) {
    searchAttrCategory (itemId: $itemId) {
      mainAttributes
      secundaryAttributes
      mainComponentsGroup
      secundaryComponentsGroup
    }
  }
`;

const useSearchAttrCategoryManual = () => {
  const [searchAttrCategory, { loading, error, data }] =
    useManualQuery(SearchAttrCategory);
  const {
    mainAttributes,
    secundaryAttributes,
    mainComponentsGroup,
    secundaryComponentsGroup,
  } = data
    ? data.searchAttrCategory
    : [];
  return {
    searchAttrCategory,
    loading,
    error
  };
};

export { useSearchAttrCategoryManual, SearchAttrCategory };
