import './App.css';
import React, { useState, useEffect } from 'react';
import SideMenu from './components/SideMenu';
import BottomMenu from './components/BottonMenu';
import ProductsPage from './components/ProductsPage';
import MessagesPage from './components/MessagesPage';
import UsersPage from './components/usersPage/UsersPage.tsx';
import { Main } from 'components/LabelSection';
import { Routes, Route } from 'react-router-dom';

import EditUserInfo from 'components/users/EditUserInfo';
import { useFetchUserInfoQuery } from 'store';
import { useQueryParams } from 'hooks/useQueryParams';
import { PromptDialog } from 'components/utils/PromptDialog';
import HomePage from 'components/usersPage/HomePage';
import OrderPage from 'components/Orders/OrderPage';
import SettingPage from 'components/Settings/SettingPage';
import { TrainingPage } from 'components/TrainingPage/TrainingPage';
import { PaymentsPage } from 'components/PaymentPage/PaymentsPage';

function App() {
  const queryTokenInfo = useQueryParams();
  const [selected, setSelected] = useState(0);
  const [open, setOpen] = useState(true);
  const {
    error: userInfoError,
    isLoading: userInfoLoading,
    data: userInfoData,
  } = useFetchUserInfoQuery(queryTokenInfo);

  if (!userInfoError && userInfoLoading) {
    // show load screen
    return <>Loading...</>;
  } else if (userInfoError && !userInfoLoading) {
    if (userInfoError.status === 401) {
      // unauthorized / no-session
      window.location.replace('https://login.atibo.io');
    }
  }

  if (
    !userInfoError &&
    !userInfoLoading &&
    userInfoData &&
    (!userInfoData.username ||
      !userInfoData.firstname ||
      !userInfoData.lastname)
  ) {
    return <EditUserInfo userInfo={userInfoData} />;
  }

  return (
    <div className='h-screen'>
      <div className={`flex h-full`}>
        <div
          className={`p-5 pt-4 text-white bg-white fixed h-full shadow ${open ? 'w-64' : 'w-20'
            }`}
        >
          <div className='h-full pt-4 text-white'>
            <span
              className={`absolute -right-4 ${!open && 'rotate-180'}`}
            ></span>
            <SideMenu
              selectedOption={selected}
              userInfo={userInfoData}
              onChangeSelection={(value) => setSelected(value)}
              open={open}
              setOpen={setOpen}
            />
            {/* <BottomMenu userInfo={userInfoData} open={open} /> */}
          </div>
        </div>
        <div
          className='pt-0 pr-7 pb-7 w-full bg-white'
          style={{ position: 'absolute', left: (open ? '280px' : '100px'), width: open ? 'calc(100% - 280px)' : 'calc(100% - 100px)' }}
        >
          <Routes>
            <Route
              path='/'
              element={<HomePage userInfoData={userInfoData} />}
            />
            <Route path='articulos' element={<ProductsPage />} />
            <Route path='mensajes' element={<MessagesPage />} />
            <Route path='etiquetas' element={<Main />} />
            <Route path='ordenes' element={<OrderPage />} />
            <Route path='pagos' element={<PaymentsPage />} />
            <Route path='configuracion' element={<SettingPage />} />
            {userInfoData?.isAdmin && userInfoData?.storeId && (
              <>
                <Route
                  path='usuarios'
                  element={<UsersPage userInfoData={userInfoData} />}
                />
                <Route
                  path='entrenamiento'
                  element={<TrainingPage />}
                />
              </>
            )}
          </Routes>
        </div>
      </div>
      <PromptDialog />
    </div>
  );
}

export default App;
