import React from 'react';
import {
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
} from 'react-hook-form';
import {
  AddProductBasicFormValues,
  DomainInfo,
} from '../types/AddCatalogProduct.types';

const createLabel = (id: string, lbl: string) => (
  <label
    className='block text-gray-700 text-sm font-bold mb-2'
    htmlFor={id}
  >
    {lbl}
  </label>
);

const createInput = (id: string, opts: any) => (
  <input
    className='shadow appearance-none border 
              rounded w-full py-2 px-3 text-gray-700
              leading-tight focus:outline-none focus:shadow-outline'
    id={id}
    {...opts}
  />
);

function AddCatalogProductFormControls({
  productId,
  errors,
  domainInfo,
  productName,
  productCategory,
  currencyOptions,
  register,
  clearErrors,
  setProductCategory,
  handleCancel,
  currencyValue
}: {
  productId: string;
  errors: FieldErrors<AddProductBasicFormValues>;
  domainInfo: DomainInfo[];
  productName: string;
  productCategory: DomainInfo | null | undefined;
  currencyOptions: string[];
  register: UseFormRegister<AddProductBasicFormValues>;
  clearErrors: UseFormClearErrors<AddProductBasicFormValues>;
  setProductCategory: (c: DomainInfo | null) => void;
  handleCancel: () => void;
  currencyValue: string;
}) {
  const updateProductCategorySelected = (catId: string) => {
    const category =
      catId && domainInfo.find((category) => category.id === catId);
    clearErrors();
    setProductCategory(category ? category : null);
  };

  const checkExistingPrice = (value: number) => {
    return (
      !productCategory ||
      (productCategory &&
        parseFloat(String(value)) >= productCategory?.settings?.minimum_price)
    );
  };

  const checkPositiveNumber = (value: number) => {
    return parseFloat(String(value)) > 0;
  };

  return (
    <>
      <div className='text-xl font-bold mb-4'>Publicar producto</div>
      <div className='mb-4'>
        {createLabel('productName', 'Título')}
        <p className='bg-gray-100 p-3'>{productName} <a className="text-primary-700 ml-4" href={`https://mercadolibre.com.uy/p/${productId}`} target="_blank">Ver en MercadoLibre</a></p>
      </div>

      <div className='mb-4'>
        {createLabel('productCategory', 'Categoría')}
        {domainInfo &&
          domainInfo.length &&
          (domainInfo?.length === 1 ? (
            productCategory ? (
              <p className='bg-gray-100 p-3'>
                {productCategory.label_path_from_root}
              </p>
            ) : (
              ''
            )
          ) : (
            <>
              <select
                className='border w-full max-w-xl rounded p-2'
                id='productCategory'
                {...register('productCategory', {
                  required: true,
                  onChange: (e) => {
                    updateProductCategorySelected(e.target.value);
                  },
                })}
              >
                <option value=''>--</option>
                {domainInfo.map((cat) => {
                  return (
                    <option key={cat.id} value={cat.id}>
                      {cat.label_path_from_root}
                    </option>
                  );
                })}
              </select>
              {errors.productCategory && (
                <p className='text-red-500 text-xs italic'>
                  Seleccione una categoría
                </p>
              )}
            </>
          ))}
      </div>

      <div className='mb-4 flex gap-2'>
        <div>
          {createLabel('price', 'Precio')}
          {createInput(
            'price',
            register('price', {
              required: true,
              validate: {
                checkExistingPrice,
              },
            })
          )}
          {productCategory &&
            errors.price &&
            errors.price.type === 'checkExistingPrice' && (
              <p className='text-red-500 text-xs italic'>
                El precio debe ser mayor o igual que{' '}
                {productCategory?.settings?.minimum_price}
              </p>
            )}
        </div>
        <div>
          {createLabel('currency', 'Moneda')}
          <>
            <select
              className='border w-full max-w-xl rounded p-2'
              id='currency'
              {...register('currency', {
                required: true,
              })}
              value={currencyValue}
              defaultValue={currencyValue}
            >
              <option value=''>--</option>
              {currencyOptions.map((curr) => {
                return (
                  <option key={curr} value={curr}>
                    {curr}
                  </option>
                );
              })}
            </select>
            {productCategory && errors.currency && (
              <p className='text-red-500 text-xs italic'>
                Seleccione una moneda
              </p>
            )}
          </>
        </div>
      </div>
      <div className='mb-6'>
        {createLabel('stock', 'Stock')}
        {createInput(
          'stock',
          register('stock', {
            required: true,
            validate: {
              checkPositiveNumber,
            },
          })
        )}
        {errors.stock && errors.stock.type === 'checkPositiveNumber' && (
          <p className='text-red-500 text-xs italic'>
            El stock debe ser un número mayor que 0 (cero)
          </p>
        )}
      </div>

      <div className='flex items-center justify-end gap-3'>
        <button
          type='button'
          className='bg-gray-200 rounded-md px-2 py-1 mr-2 cursor-pointer border'
          onClick={handleCancel}
        >
          Cancelar
        </button>
        <button
          className='bg-primary-700 text-white rounded-md px-2 py-1 mr-2'
          type='submit'
        >
          Publicar
        </button>
      </div>
    </>
  );
}

export { AddCatalogProductFormControls };
