import { useQuery, useManualQuery } from 'graphql-hooks';

const GET_CATALOG_SUGGESTIONS_BY_ASIN = `
query getCatalogSuggestionsByAsin($asin: String!) {
  getCatalogSuggestionsByAsin(asin: $asin) {
    id
    status
    domain_id
    settings {
      listing_strategy
    }
    name
    attributes {
      id
      name
      value_id
      value_name
    }
    pictures {
      id
      url
    }
    parent_id
  }
}
`;

export function useGetCatalogSuggestionsByAsin(asin: string) {
    const { loading, data } = useQuery(GET_CATALOG_SUGGESTIONS_BY_ASIN, {
        variables: { asin }
    });
    
    return { loading, data };
}


export function useGetCatalogSuggestionsByAsinManual() {
  const [getCatalogSuggestionsByAsin, { loading, data }] = useManualQuery(GET_CATALOG_SUGGESTIONS_BY_ASIN);

  return { getCatalogSuggestionsByAsin, loading, data };
}