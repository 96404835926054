const { useQuery, useManualQuery } = require('graphql-hooks');

export const GET_TRANSLATED_DESCRIPTION_BY_ASIN_QUERY = `
query getTranslatedDescriptionByAsin($asin: String!) {
    getTranslatedDescriptionByAsin(asin: $asin) {
        description
        bulletPoint
    }
}
`;

export const useGetTranslatedDescriptionByAsin = (asin: string) => {
    const { loading, error, data } = useQuery(GET_TRANSLATED_DESCRIPTION_BY_ASIN_QUERY, {
        variables: { asin },
    });

    return {
        loading,
        error,
        data,
    };
};

export const useGetTranslatedDescriptionByAsinManual = () => {
    const [getTranslatedDescriptionByAsin, { loading, error, data }] = useManualQuery(GET_TRANSLATED_DESCRIPTION_BY_ASIN_QUERY);

    return {
        getTranslatedDescriptionByAsin,
        loading,
        error,
        data,
    };
}