import React, { useEffect, useState } from 'react';
import { useGetAmazonTreeNodeManual } from 'hooks/useGetAmazonTreeNode';
import { useTree } from 'contexts/TreeSelectionContext';
import _ from 'lodash';

export interface TreeNode {
    id: string;
    name: string;
    childrenIds: string[];
    children: TreeNode[];
}

interface TreeSelectorProps {
    nodeId: string;
    onUnselectedItem?: () => void;
    preSelected?: boolean;
    parentNode?: TreeNode;
}

export const TreeSelector = ({ nodeId, onUnselectedItem, preSelected, parentNode }: TreeSelectorProps) => {
    const { getAmazonTreeNode, data, loading } = useGetAmazonTreeNodeManual();
    const [showChildren, setShowChildren] = useState(false);
    const [node, setNode] = useState<TreeNode | null>(null);
    const [isChecked, setIsChecked] = useState(preSelected);
    const { state, dispatch } = useTree();

    useEffect(() => {
        getAmazonTreeNode({
            variables: {
                id: nodeId,
                levels: 9
            }
        });
    }, [nodeId]);

    useEffect(() => {
        setNode(data?.getAmazonTreeNode);
    }, [data]);

    useEffect(() => {
        setIsChecked(node ? (state.selectedNodes.has(node.id) || (parentNode ? state.selectedNodes.has(parentNode.id) : false)) : false);
    }, [node, state.selectedNodes, parentNode]);

    const handleCheckboxChange = (checked: boolean, fromChild: boolean = false) => {
        if (!node) return;

        if (checked) {
            // La selección sigue igual
            const childrenIds = getAllChildrenIds(node);
            dispatch({ type: 'SELECT_MULTIPLE', nodeIds: [node.id, ...childrenIds] });
        } else {
            if (fromChild) {
                // Si viene de un hijo, solo deseleccionamos este nodo
                dispatch({ type: 'UNSELECT_SINGLE_WITH_CHILDREN', nodeId: node.id });
            } else {
                // Si viene del checkbox directamente, deseleccionamos todo como antes
                const childrenIds = getAllChildrenIds(node);
                dispatch({ type: 'UNSELECT_MULTIPLE', nodeIds: [node.id, ...childrenIds] });
            }
            onUnselectedItem?.();
        }
    };

    const getAllChildrenIds = (node: TreeNode): string[] => _.flatMap(node.children, child => [child.id, ...getAllChildrenIds(child)]);

    if (loading) return <div className="pl-8 select-none">Cargando...</div>;
    return node && (
        <div className="pl-4 select-none cursor-pointer" key={node.id}>
            <span 
                onClick={() => node.children.length && setShowChildren(!showChildren)}
                className="inline-block w-4 text-center"
            >
                {showChildren ? '-' : node.children.length ? '+' : ' '}
            </span>
            <input
                type="checkbox"
                className="mr-2"
                checked={isChecked}
                onChange={(e) => handleCheckboxChange(e.target.checked)}
            />
            <span onClick={() => node.children.length && setShowChildren(!showChildren)}>
                {node.name}
            </span>
            {showChildren &&
                node.children.length > 0 &&
                node.children.map(childNode => (
                    <TreeSelector
                        key={childNode.id}
                        nodeId={childNode.id}
                        onUnselectedItem={() => handleCheckboxChange(false, true)}
                        preSelected={isChecked}
                        parentNode={node}
                    />
                ))}
        </div>
    );
};
