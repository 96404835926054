import LoadingIconComponent from '../iconsComponents/LoadingIconComponent';

const SaveButtonComponent = ({
  isValidated,
  isValidatedKg,
  onSaveSettings,
  loading,
}) => {
  return (
    <button
      className={`bg-primary-100 border border-primary-100 hover:border-primary-500 text-primary-700 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md transition ease-in-out duration-150  ${
        (!isValidated || !isValidatedKg) &&
        'cursor-not-allowed bg-gray-200 border border-gray-200 hover:border-gray-100 text-gray-300'
      }`}
      type='button'
      onClick={onSaveSettings}
      disabled={!isValidated || !isValidatedKg ? true : false}
    >
      {loading ? (
        <span className='ml-4'>
          <LoadingIconComponent />
        </span>
      ) : (
        'Guardar'
      )}
    </button>
  );
};

export default SaveButtonComponent;
