import { useQuery, useManualQuery } from 'graphql-hooks';

const query = `
query GetMlItemByAsin($asin: String!) {
    getMlItemByAsin(asin: $asin) {
        id
        title
        category_id
        status
        catalog_listing
        descriptions
        pictures {
            url
            secure_url
            size
            max_size
            quality
        }
        tags
        price
        currency_id
    }
}
`

export const useGetMlItemByAsin = (asin: string) => {
    const { loading, error, data } = useQuery(query, {
        variables: {
            asin
        }
    });
    return {
        loading,
        error,
        data
    }
}

export const useGetMlItemByAsinManual = () => {
    const [getMlItemByAsin, { loading, data, error }] = useManualQuery(query);
    return {
        loading,
        error,
        data,
        getMlItemByAsin
    }
}