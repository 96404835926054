import { useQuery, useManualQuery } from 'graphql-hooks';

export interface AttributeValueInput {
  id: string;
  name: string;
}

export interface AttributeInput {
  id: string;
  name: string;
  value_id: string;
  value_name: string;
  values: AttributeValueInput[];
}

export interface PictureInput {
  source: string;
}

export interface UsePublishProductQueryInput {
  title: string;
  categoryId: string;
  price: number;
  currencyId: string;
  stock: number;
  catalogId: string;
  description: string;
  attributes: AttributeInput[];
  pictures: PictureInput[];
  sku: string;
}

export const UsePublishProductQuery = `
mutation UsePublishProductQuery(
  $title: String!,
  $categoryId: String!,
  $price: Float!,
  $currencyId: String!,
  $stock: Int!,
  $description: String!,
  $attributes: [AttributeValueInput]!,
  $pictures: [PictureInput]!,
  $sku: String,
) {
  publishManualProduct(
    title: $title,
    price: $price,
    categoryId: $categoryId,
    currencyId: $currencyId,
    stock:  $stock,
    description: $description,
    attributes: $attributes,
    pictures: $pictures,
    sku: $sku,
  ) {
    id
    error
  }
}
`;

export const usePublishProduct = (variables: any) => {
  const formattedVariables = {
    ...variables,
    attributes: variables.attributes.map((attribute: any) => ({
      value_id: attribute.value_id,
      value_name: String(attribute.value_name),
    })),
  };
  const {
    loading,
    error,
    data: publishProductResponse,
  } = useQuery(UsePublishProductQuery, { variables: formattedVariables });
  return { loading, error, publishProductResponse };
};

export const usePublishProductManual = () => {
  const [publishProduct, { loading, error, data: publishProductResponse }] =
    useManualQuery(UsePublishProductQuery);
  return { loading, error, publishProductResponse, publishProduct };
};
