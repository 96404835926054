import { useEffect, useState } from 'react';
import MeliModal from '../MeliModal';
import AddRowIcon from '../iconsComponents/AddRowIcon';
import CategoryMeliSelectedItem from '../CategoryMeliSelectedItem';
import SaveButtonComponent from '../utilsComponents/SaveButtonComponent';

const CostTax = ({
  fee,
  setFee,
  meliShipping,
  setMeliShipping,
  waitingDays,
  setWaitingDays,
  amazonPriceTax,
  setAmazonPriceTax,
  amazonAsinTax,
  setAmazonAsinTax,
  onSaveSettings,
  meliCategoryTax,
  setMeliCategoryTax,
  isValidated,
  isValidatedKg,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [finalPath, setFinalPath] = useState([]);
  const [selectedCaregoryId, setSelectedCategoryId] = useState('');

  useEffect(() => {
    if (finalPath.length > 0) {
      let pathDetail = '';
      for (let idv = 0; idv < finalPath.length; idv++) {
        pathDetail = pathDetail.concat(finalPath[idv].label + ' / ');
      }

      const newMeliCategoryObject = {
        path: pathDetail.slice(0, -3),
        percentage: 0,
        categoryId: finalPath[finalPath.length - 1].value,
      };

      setMeliCategoryTax([...meliCategoryTax, newMeliCategoryObject]);
    }
  }, [finalPath]);

  const onPercentageChange = (idx, newValue) => {
    const newMeliCategoryTax = [...meliCategoryTax];
    newMeliCategoryTax[idx] = {
      ...newMeliCategoryTax[idx],
      percentage: +newValue,
    };
    setMeliCategoryTax(newMeliCategoryTax);
  };

  const onRemoveRow = (position) => {
    const newMeliCategoryTax = [...meliCategoryTax];
    newMeliCategoryTax.splice(position, 1);
    setMeliCategoryTax(newMeliCategoryTax);
  };

  return (
    <div className='flex flex-col justify-between h-full'>
      {isOpenModal && (
        <MeliModal
          setIsOpenModal={setIsOpenModal}
          setFinalPath={setFinalPath}
          selectedCaregoryId={selectedCaregoryId}
        />
      )}
      <div className='w-full flex max-h-[580px] h-[85%]'>
        <div className='w-[30%] border-2 border-r-primary-100 border-x-white border-y-white'>
          <div className='mb-2'>
            <span className='align-center'>
              <h2></h2>
            </span>
            <div className='flex w-full'>
              <div className='sm:col-span-3 w-full p-4'>
                <label
                  htmlFor='fee'
                  className='block text-sm font-medium leading-6 text-gray-700'
                >
                  % cuota del giro
                </label>
                <div className='flex w-full mt-2'>
                  <div className='w-[15%] mr-0'>
                    <span className='w-[90%] h-full bg-primary-100 text-primary-700 rounded-md items-center justify-center flex'>
                      %
                    </span>
                  </div>
                  <div className='w-[55%]'>
                    <input
                      name='fee'
                      id='fee'
                      type='number'
                      min={0}
                      max={100}
                      maxLength={3}
                      size={8}
                      className='block w-[30%] rounded-md border-0 border-gray-300 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-2'
                      value={fee}
                      onChange={(e) => setFee(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='flex w-full'>
              <div className='sm:col-span-3 w-full p-4'>
                <label
                  htmlFor='meliShipping'
                  className='block text-sm font-medium leading-6 text-gray-700'
                >
                  Tipo de envio (MELI)
                </label>
                <div className='mt-2 w-full'>
                  <select
                    id='meliShipping'
                    name='meliShipping'
                    className='w-full h-[2rem] pl-2 bg-white rounded-md border-0 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                    value={meliShipping}
                    onChange={(e) => setMeliShipping(e.target.value)}
                  >
                    <option value='0'>Mercado envios (CADC)</option>
                    <option value='1'>Mercado envios (CADV)</option>
                    <option value='2'>Envio a coordinar con el vendedor</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='flex w-full'>
              <div className='sm:col-span-3 w-full p-4'>
                <label
                  htmlFor='waitingDays'
                  className='block text-sm font-medium leading-6 text-gray-700'
                >
                  Dias de espera del producto (Disponibilidad del producto)
                </label>
                <div className='mt-2'>
                  <input
                    type='text'
                    name='waitingDays'
                    id='waitingDays'
                    className='block w-full rounded-md border-0 border-gray-300 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-2 mr-4'
                    placeholder='Ej. 15 dias'
                    value={waitingDays}
                    onChange={(e) => setWaitingDays(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-[30%] border-2 border-r-primary-100 border-x-white border-y-white'>
          <div className='mb-2'>
            <div className='flex w-full'>
              <div className='sm:col-span-3 w-full p-4'>
                <label
                  htmlFor='amazonPriceTax'
                  className='block text-sm font-medium leading-6 text-gray-700'
                >
                  % global de impuesto en los productos sobre precio Amazon
                </label>
                <div className='flex w-full mt-2'>
                  <div className={`w-[55%]`}>
                    <select
                      id='amazonPriceTax'
                      name='amazonPriceTax'
                      className='pl-2 bg-white block w-full h-full rounded-md border-0 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                      value={amazonPriceTax.option}
                      onChange={(e) =>
                        setAmazonPriceTax({
                          ...amazonPriceTax,
                          option: e.target.value,
                        })
                      }
                    >
                      <option value='0'>Precio + costo flete</option>
                      <option value='1'>Precio sin costo flete</option>
                    </select>
                  </div>
                  <div className='w-[15%] ml-2.5 mr-0'>
                    <span className='w-[90%] h-full bg-primary-100 text-primary-700 rounded-md items-center justify-center flex'>
                      %
                    </span>
                  </div>
                  <div className='w-[15%]'>
                    <input
                      type='number'
                      name='amazonPricePercentage'
                      id='amazonPricePercentage'
                      min={0}
                      max={100}
                      maxLength={3}
                      size={4}
                      className='block w-full rounded-md border-0 border-gray-300 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-2'
                      value={amazonPriceTax.percentage}
                      onChange={(e) =>
                        setAmazonPriceTax({
                          ...amazonPriceTax,
                          percentage: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='flex w-full'>
              <div className='sm:col-span-3 w-full p-4'>
                <label
                  htmlFor='amazonAsinTax'
                  className='block text-sm font-medium leading-6 text-gray-700'
                >
                  % de impuesto en ASIN Amazon
                </label>
                <div className='flex w-full mt-2'>
                  <div className={`w-[55%]`}>
                    <select
                      id='amazonAsinTax'
                      name='amazonAsinTax'
                      className='pl-2 bg-white block w-full h-full rounded-md border-0 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                      value={amazonAsinTax.option}
                      onChange={(e) =>
                        setAmazonAsinTax({
                          ...amazonAsinTax,
                          option: e.target.value,
                        })
                      }
                    >
                      <option value='0'>Precio + costo flete</option>
                      <option value='1'>Precio sin costo flete</option>
                    </select>
                  </div>
                  <div className='w-[15%] ml-2.5 mr-0'>
                    <span className='w-[90%] h-full bg-primary-100 text-primary-700 rounded-md items-center justify-center flex'>
                      %
                    </span>
                  </div>
                  <div className='w-[15%]'>
                    <input
                      type='number'
                      id='amazonAsinPercentage'
                      name='amazonAsinPercentage'
                      min={0}
                      max={100}
                      maxLength={3}
                      size={4}
                      className='block w-full rounded-md border-0 border-gray-300 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-2'
                      value={amazonAsinTax.percentage}
                      onChange={(e) =>
                        setAmazonAsinTax({
                          ...amazonAsinTax,
                          percentage: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-[40%]'>
          <div className='mb-2'>
            <div className='flex w-full'>
              <div className='sm:col-span-3 w-full p-4'>
                <label
                  htmlFor='impuestoCategoria'
                  className='block text-sm font-medium leading-6 text-gray-700'
                >
                  % de impuesto sobre categoria MELI
                </label>
                <div className='w-full flex mt-2 justify-start'>
                  <span onClick={() => {
                    setSelectedCategoryId('');
                    setIsOpenModal(true);
                  }}>
                    <AddRowIcon title='Agregar path de categoria' />
                  </span>
                </div>
                {meliCategoryTax?.map((meliInfo, idx) => {
                  return (
                    <CategoryMeliSelectedItem
                      key={meliInfo.categoryId}
                      position={idx}
                      path={meliInfo.path}
                      percentage={meliInfo.percentage}
                      categoryId={meliInfo.categoryId}
                      onRemoveRow={onRemoveRow}
                      onPercentageChange={(newValue) =>
                        onPercentageChange(idx, newValue)
                      }
                      onHandlerEdit={(categoryId) => {
                        setIsOpenModal(true);
                        setSelectedCategoryId(categoryId);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full flex mt-2 justify-end'>
        <SaveButtonComponent
          onSaveSettings={onSaveSettings}
          isValidated={isValidated}
          isValidatedKg={isValidatedKg}
        />
      </div>
    </div>
  );
};

export default CostTax;
