import { useQuery, useManualQuery } from 'graphql-hooks';

const query = `
query GetMlItemsByUpc($upc: String!) {
    getMlItemsByUpc(upc: $upc) {
        id
        title
        category_id
        status
        catalog_listing
        descriptions
        pictures {
            url
            secure_url
            size
            max_size
            quality
        }
        tags
        price
        currency_id
        item_relations {
            id
            variation_id
            stock_relation
        }
        seller_custom_field
    }
}
`

export const useGetMlItemsByUpc = (upc: string) => {
    const { loading, error, data } = useQuery(query, {
        variables: {
            upc
        }
    });
    return {
        loading,
        error,
        data
    }
}

export const useGetMlItemsByUpcManual = () => {
    const [getMlItemsByUpc, { loading, data, error }] = useManualQuery(query);
    return {
        loading,
        error,
        data,
        getMlItemsByUpc
    }
}