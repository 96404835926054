import { useQuery, useManualQuery } from 'graphql-hooks';

export const GetDomainInfoQuery = `
query GetDomainInfo($domainId: String!) {
  getDomainInfo(domainId: $domainId) {
    id
    name
    label_path_from_root
    date_created
    path_from_root {
      id
      name
    }
    settings {
      catalog_domain
      currencies
      status
      minimum_price
      minimum_price_currency
    }
  }
}
`;

export const useGetDomainInfo = (domainId) => {
  const { loading, error, data } = useQuery(GetDomainInfoQuery, {
    variables: {
      domainId
    }
  });

  const domainInfo = data?.getDomainInfo || [];

  return { loading, error, domainInfo };
}

export const useGetDomainInfoManual = () => {
  const [getDomainInfo, { loading, error, data }] = useManualQuery(GetDomainInfoQuery);

  const domainInfo = data?.getDomainInfo || [];

  return { getDomainInfo, loading, error, domainInfo };
}