import React from 'react';

import { useUserCreditsSubscription } from 'hooks/useUserCreditsSubscription';

const CheckIconChecked = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-circle-check w-5 h-5 mr-2 text-primary-700"><circle cx="12" cy="12" r="10"></circle><path d="m9 12 2 2 4-4"></path></svg>
const CheckIconUnchecked = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-circle-x w-5 h-5 mr-2 text-gray-500"><circle cx="12" cy="12" r="10"></circle><path d="m15 9-6 6"></path><path d="m9 9 6 6"></path></svg>

interface Feature {
  text: string;
  included: boolean;
}

export interface PlanCardProps {
  title: string;
  description: string;
  price: number;
  totalCredits?: number;
  creditsUsed?: number;
  features: Feature[];
  isConnected?: boolean;
}

export const PlanCard = ({ title, description, price, totalCredits, creditsUsed, features, isConnected = false, upgradeText = "Mejorar plan" }: PlanCardProps & { upgradeText?: string }) => {
  const percentage = totalCredits && creditsUsed ? Math.round((creditsUsed / totalCredits) * 100) : 0;
  
  return (
    <div className='border-2 rounded-xl p-0 border-gray-200 shadow-sm flex flex-col bg-white'>
      <div className='grow p-8'>
        <div className='flex mb-4'>
          <div className='flex-grow font-medium'>
            <div>
              {title} <span className="text-xs bg-primary-100 text-primary-700 rounded-xl px-2 py-1 align-middle">Mensual</span>
            </div>
            <div className="text-xs text-gray-500">{description}</div>
          </div>
          <div className=''><span className='text-3xl font-bold'>U$S {price}</span> <span className="text-md text-gray-500">/mes</span></div>
        </div>
        <div className="space-y-2 mb-4">
          {features.map((feature, index) => (
            <div key={index} className="flex items-center">
              {feature.included ? <CheckIconChecked /> : <CheckIconUnchecked />}
              <span className={feature.included ? 'text-black' : 'text-gray-500'}>{feature.text}</span>
            </div>
          ))}
        </div>
        {isConnected && totalCredits && creditsUsed && (
          <div className="mt-4">
            <div className="text-xs font-bold pb-2">{creditsUsed.toLocaleString('es-UY')} créditos de {totalCredits.toLocaleString('es-UY')} usados</div>
            <div className="relative w-full">
              <div className={`absolute rounded-xl h-2 w-full bg-gray-300`}></div>
              <div className={`absolute rounded-xl h-2 bg-primary-700`} style={{ width: `${percentage}%` }}></div>
            </div>
          </div>
        )}
      </div>
      <div className='border-t-gray-200 border-t-2 p-4'>
        {isConnected ? (
          <div className='text-black font-medium text-end'>Tu plan actual</div>
        ) : (
          <div className='text-primary-700 font-medium text-end'><span className='cursor-pointer'>{upgradeText}</span></div>
        )}
      </div>
    </div>
  );
}

export const ConnectedPlanCard = ({ title, description, price, features, upgradeText }: PlanCardProps & { upgradeText?: string }) => {
  const [usage, setUsage] = React.useState(0);
  const [limit, setLimit] = React.useState(1);
  const [resetLimitDate, setResetLimitDate] = React.useState('');
  const [synced, setSynced] = React.useState(false);

  useUserCreditsSubscription((data) => {
    setUsage(data.used);
    setLimit(data.total);
    setResetLimitDate(data.nextResetDate);
    setSynced(true);
  });

  return <PlanCard
    title={title}
    description={description}
    price={price}
    totalCredits={limit}
    creditsUsed={usage}
    features={features}
    isConnected={true}
    upgradeText={upgradeText}
  />
}

