const {
  default: XMarkIcon,
} = require('components/utils/iconComponents/XMarkIcon');

const NewModalHeader = ({handleClickBackground}) => {
  return (
    <div className='flex justify-between items-center bg-white'>
      <span className='text-xl font-semibold text-gray-900'>
        Opciones de precio dinamico
      </span>
      <span className='w-[24px] h-[24px] t-1.5 l-1.5 cursor-pointer' onClick={handleClickBackground}>
        <XMarkIcon />
      </span>
    </div>
  );
};

export default NewModalHeader;
