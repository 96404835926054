export const emailValidator = new RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

export function useQueryParams() {
  const query = window?.location?.search?.substring(1);
  const sessionData = localStorage.getItem('session');
  let accessTokenExist = false;
  let email: string = '';
  let access_token: string = '';

  if (
    query &&
    query.indexOf('access_token=') > -1 &&
    query.indexOf('email=') > -1
  ) {
    const queryVals = query.split('&');
    const queryObj = queryVals.reduce((prev: any, curr: string) => {
      const [key, val] = curr.split('=');
      return { ...prev, [key]: val };
    }, {});

    if (
      emailValidator.test(queryObj['email']) &&
      String(queryObj['access_token']).length >= 40
    ) {
      email = queryObj['email'];
      access_token = queryObj['access_token'];
    }

    accessTokenExist = true;
  } else if (sessionData) {
    try {
      const { sessionInfo } = JSON.parse(sessionData);
      if (sessionInfo) {
        email = sessionInfo.email;
        access_token = sessionInfo.token;
      }
    } catch (e) {}
  }


  return {
    accessTokenExist,
    access_token,
    email,
  };
}
