import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddIcon from '@mui/icons-material/Add';
import AddUser, { EditUserPermissions, PERMISSION_OPTIONS } from './AddUser';
import { useDispatch } from 'react-redux';
import { showMessage, useRemoveUserFromStoreMutation } from 'store';
import { IconType, MessageType } from 'components/utils/PromptDialog';

export interface UserItemList {
  id: string;
  email: string;
  permissions: string[];
}

const UsersPage = ({ userInfoData }: any) => {
  const { usersInStore }: { usersInStore: UserItemList[] } = userInfoData || {};
  const [removeUserFromStore] = useRemoveUserFromStoreMutation();
  const dispatch = useDispatch();
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

  const permissionsPreProcess = PERMISSION_OPTIONS.map((p) => [p.value, p.label]);
  const permissionsProcess: any = Object.fromEntries(permissionsPreProcess);

  const handleDeleteUser = (idToRemove: string, email: string) => {
    dispatch(
      showMessage({
        content: `¿Desea eliminar el usuario ${email}?`,
        messageType: MessageType.YES_NO,
        iconType: IconType.QUESTION,
        handleConfirm: async () => {
          try {
            await removeUserFromStore({
              data: {
                id: idToRemove,
              },
            }).unwrap();
          } catch (err) {
            console.log('>> Error on remove user from store: ', err);
          }
        },
      })
    );
  };

  const handleEditUser = (userItemList: UserItemList) => {
    dispatch(
      showMessage({
        content: EditUserPermissions(userItemList),
        messageType: MessageType.NONE,
        iconType: IconType.EDIT,
      })
    );
  };

  return (
    <div className="space-y-6 p-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-800">Usuarios</h1>
        <button
          onClick={() => setIsAddUserModalOpen(true)}
          className="bg-primary-700 hover:bg-primary-800 text-white font-bold py-2 px-4 rounded inline-flex items-center"
        >
          <AddIcon className="mr-2" />
          Agregar Usuario
        </button>
      </div>

      <div className="bg-white rounded-lg border border-gray-200 shadow overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-800">Usuarios existentes</h2>
        </div>
        {Array.isArray(usersInStore) && usersInStore.length ? (
          <table className="w-full">
            <thead className="bg-gray-50 text-gray-700 text-sm uppercase">
              <tr>
                <th className="py-3 px-6 text-left">E-mail</th>
                <th className="py-3 px-6 text-left">Permisos</th>
                <th className="py-3 px-6 text-left">Acciones</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {usersInStore.map((userItemList: UserItemList) => (
                <tr key={`tr-${userItemList.id}`} className="hover:bg-gray-50">
                  <td className="py-4 px-6">{userItemList.email}</td>
                  <td className="py-4 px-6">
                    {userItemList.permissions
                      .map((p) => permissionsProcess[p])
                      .join(', ')}
                  </td>
                  <td className="py-4 px-6">
                    <button
                      className="text-blue-600 hover:text-blue-800 mr-3"
                      onClick={() => handleEditUser(userItemList)}
                    >
                      <ModeEditIcon />
                    </button>
                    <button
                      className="text-red-600 hover:text-red-800"
                      onClick={() => handleDeleteUser(userItemList.id, userItemList.email)}
                    >
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center p-6 text-gray-500">
            Aún no se han agregado usuarios a la tienda
          </div>
        )}
      </div>

      <AddUser 
        isOpen={isAddUserModalOpen} 
        onClose={() => setIsAddUserModalOpen(false)} 
      />
    </div>
  );
};

export default UsersPage;
