import TrashIcon from '../iconsComponents/TrashIcon';

export const RemoveRowButtonComponent = ({
  position,
  onRemoveRow,
  iconTitle,
}) => {
  return (
    <div onClick={() => onRemoveRow(position)}>
      <TrashIcon title={iconTitle} />
    </div>
  );
};
