import { useState, useEffect } from 'react';
import { CatalogProductsList } from './CatalogProductsList';
import { ElegibleProductsList } from './ElegibleProductsList';
import { ProductFilterTabs } from './ProductFilterTabs';
import { MlCatalogProductsList } from './MlCatalogProductList/MlCatalogProductsList';
//user permission
import { useSearchPermissionManual } from 'hooks/useSearchPermissions';
import AlertMessage from './utils/AlertMessage';
import { DANGERMSGALERT } from './utils/constants';

//module permission
import { flags } from './utils/flags';
import { ProductsAllList } from './ProductsAllList';

const ProductsPage = () => {
  const [selectedTabNumber, setSelectedTabNumber] = useState(0);
  const [showManualPublicationButton, setShowManualPublicationButton] =
    useState(false);
  const [showProductPage, setShowProductPage] = useState(false);

  const {
    searchPermission,
    error: errorSearchPermission,
    loading: loadingSearchPermission,
  } = useSearchPermissionManual();

  useEffect(() => {
    const fetchUserPermission = async () => {
      const { data } = await searchPermission();
      // console.log('user permission up', data.searchPermission);
      if (errorSearchPermission) {
        if (errorSearchPermission.httpError) {
          console.log(
            `${errorSearchPermission.httpError.statusText} - para obtener los permisos`
          );
        }
      }

      if (data) {
        const { isAdmin, lastActiveStore } = data.searchPermission;

        if (isAdmin) {
          setShowManualPublicationButton(true);
          setShowProductPage(true);
        } else if (lastActiveStore.permissions.length > 0) {
          lastActiveStore.permissions.includes('manual_publishing') &&
            setShowManualPublicationButton(true);
          lastActiveStore.permissions.includes('catalog_publishing') &&
            setShowProductPage(true);
        }
      }
    };

    fetchUserPermission();
  }, []);

  return (
    <div className='font-sans mt-4 flex flex-col h-[calc(100vh-3rem)]'>
      {flags.showModuleHeader && <div className='font-semibold text-3xl mb-12'>Artículos</div>}
      {!showProductPage && !loadingSearchPermission && (
        <div className='w-9/12'>
          <AlertMessage message='No tiene permisos para ver esta p&aacute;gina!' title='Atencion' type={DANGERMSGALERT} />
        </div>
      )}
      {showProductPage && (
        <div className='flex-grow overflow-hidden'> {/* This will contain the table component */}
          {selectedTabNumber === 0 && (
            <MlCatalogProductsList
              query=''
              showManualPublicationButton={showManualPublicationButton}
              selectedTabNumber={selectedTabNumber}
              setSelectedTabNumber={setSelectedTabNumber}
            />
          )}
          {selectedTabNumber === 1 && (
            <ElegibleProductsList query='' setSelectedTabNumber={setSelectedTabNumber} />
          )}
          {selectedTabNumber === 2 && (
            <CatalogProductsList query='' setSelectedTabNumber={setSelectedTabNumber} />
          )}
          {selectedTabNumber === 3 && (
            <ProductsAllList query='' setSelectedTabNumber={setSelectedTabNumber} />
          )}
        </div>
      )}
    </div>
  );
};

export default ProductsPage;
