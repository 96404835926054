const { useManualQuery } = require('graphql-hooks');

const searOrdersQuery = `query SearchOrders($query: String!, $offset: Int!, $limit: Int!) {
    searchOrders(query: $query, offset: $offset, limit: $limit) {
      offset
      total
      orders {
        item {
          title
          id
          category_id
          condition
          seller_custom_field
          thumbnail
          permalink
          amazonUrl
        }
        buyer {
            id
            nickname
        }
        id
        order_id
        pack_id
        seller_id
        unit_price
        currency_id
        quantity
        lastUpdated
        dateCreated
        total_amount
        status
      }
    }
  }`;

const useSearchOrdersManual = () => {
  const [searchOrders, { loading, error, data }] =
    useManualQuery(searOrdersQuery);

  const { orders, offset, total } = data
    ? data.searchOrders
    : { orders: [], offset: 0, total: 0 };

  return { searchOrders, loading, error, offset, total, orders };
};

export { useSearchOrdersManual };
