import { useManualQuery } from 'graphql-hooks';

const EditMlstoreMutation = `
  mutation editMlstore($settings: JSON) {
    editMlstore(settings: $settings) {
      settings
    }
  }
`;

const useEditMlstoreManual = () => {
  const [editMlstore, { loading, error, data: mlstoreResponse }] = useManualQuery(EditMlstoreMutation);
  return { editMlstore, loading, error, mlstoreResponse };
};

export {
  EditMlstoreMutation,
  useEditMlstoreManual,
};