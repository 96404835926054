import React, { Fragment } from 'react';

function AnswerLabel({ labels, handlerLabelAnswer, elementId, isFromOrders = false }) {
  const handlerPressTitle = (pressed) => {
    handlerLabelAnswer(pressed, elementId);
  };

  let labelInfo = '';

  if (labels && labels.length > 0) {
    labelInfo = labels.map((label) => (
      <span
        key={label.id}
        className={`inline-block py-1 px-4 m-1 rounded-2xl text-primary-700 font-medium text-sm border border-primary-100 cursor-pointer bg-primary-50 hover:border-primary-500`}
        onClick={() => handlerPressTitle(label.description)}
      >
        {label.title}
      </span>
    ));
  }

  return (
    <div className={`mb-1 h-24 w-full py-2 rounded-xl ${isFromOrders && 'shadow border border-gray-100'} overflow-y-auto flex flex-wrap text-gray-500 bg-white`}>
      <div className='w-full'>{labelInfo}</div>
    </div>
  );
}

export default AnswerLabel;
