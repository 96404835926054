const { React, Fragment, useState } = require('react');

function DeleteQuestion({
  setShowDeleteQuestionModal,
  deleteQuestionSelected,
}) {
  const [enableButton, setEnableButton] = useState(false);
  const [optionValue, setOptionValue] = useState();

  const handlerClickCancel = () => {
    console.log('cancelar');
    setShowDeleteQuestionModal(false);
  };

  const handlerClickAcept = () => {
    if (!enableButton) {
      return false;
    }
    // console.log('aceptar', optionValue);
    if (optionValue == 'delete') {
      deleteQuestionSelected();
      setShowDeleteQuestionModal(false);
    }
  };

  const handlerDeleteOptions = (e) => {
    setEnableButton(true);
    setOptionValue(e.target.value);
  };

  return (
    <Fragment>
      <div
        className={`fixed top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-50 flex justify-center items-center backdrop-blur-sm`}
      >
        <div
          className='px-8 pt-8 pb-8 w-128 max-w-lg rounded-lg bg-white overflow-y-auto'
          style={{ color: 'lightgray' }}
        >
          <form>
            <label>
              <span className='text-slate-700'>Que deseas hacer?</span>
              <hr />
              <div className='inline-flex w-full justify-between p-8'>
                <div>
                  <input
                    type='radio'
                    name='deleteOptions'
                    value='delete'
                    className='checked:bg-primary-700'
                    onChange={handlerDeleteOptions}
                  />{' '}
                  Eliminar respuesta
                </div>
                <div>
                  <input
                    type='radio'
                    name='deleteOptions'
                    value='block'
                    className='checked:bg-primary-700'
                    onChange={handlerDeleteOptions}
                  />{' '}
                  Bloquear usuario
                </div>
              </div>
            </label>
            <div className='flex justify-end'>
              <input
                type='button'
                className={`${
                  enableButton ? 'bg-primary-100 border border-primary-100 hover:border-primary-500 text-primary-700' : 'bg-gray-500 cursor-not-allowed text-white'
                } 'mr-1 font-semibold text-sm px-4 py-2 rounded-lg m-1 cursor-pointer'`}
                onClick={handlerClickAcept}
                value='Aceptar'
              />
              <button
                type='button'
                className='bg-red-200 border border-red-200 hover:border-red-500 text-red-700 mr-1 font-semibold text-sm px-4 py-2 rounded-lg m-1 cursor-pointer'
                onClick={handlerClickCancel}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default DeleteQuestion;
