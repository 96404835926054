import logoSVG from './images/Logo.svg';
import homeSVG from './images/Home.svg';
import articlesSVG from './images/Articles.svg';
import messagesSVG from './images/Messages.svg';
import addsSVG from './images/Adds.svg';
import statsSVG from './images/Stats.svg';
import userSVG from './images/User.svg';
import orderSVG from './images/Order.svg';
import settingsSvg from './images/Settings.svg';
import academicCap from './images/AcademicCap.svg';
import paymentsSVG from './images/Payments.svg';
import favicon from './images/favicon.svg';
import { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import BottomMenu from './BottonMenu';

const SideMenuOption = (props) => {
  const handleClick = () => {
    if (props.onClick) props.onClick();
  };

  return (
    <div
      className={`cursor-pointer ${props.selected ? 'rounded-md bg-gray-50' : ''
        }`}
      onClick={handleClick}
    >
      <div className='inline-flex my-2'>
        <img className={`mr-3 ml-2.5`} src={props.icon}></img>
        <span
          className={`leading-6 text-sm text-gray-${props.selected ? '900' : '700'
            } text-base`}
        >
          {props.open && props.text}
        </span>
      </div>
    </div>
  );
};

const SideMenu = (params) => {
  const [selectedOption, setSelectedOption] = useState(params.selectedOption);
  const userInfo = params.userInfo;

  useEffect(() => {
    if (params.onChangeSelection) params.onChangeSelection(selectedOption);
  }, [selectedOption]);

  return (
    <div className='flex flex-col justify-between h-full'>
      <div>
        <img
          className={`mb-8 fixed cursor-pointer ${params.open ? 'ml-2.5' : 'ml-1.5'
            }`}
          src={params.open ? logoSVG : favicon}
          alt='Atibo'
          onClick={() => params.setOpen(!params.open)}
        />
        <div className='pt-16'>
          <div className='h-12'>
            <Link to='/'>
              <SideMenuOption
                icon={homeSVG}
                text='Home'
                onClick={() => setSelectedOption(0)}
                selected={selectedOption === 0}
                open={params.open}
              />
            </Link>
          </div>
          <div className='h-12'>
            <Link to='/articulos'>
              <SideMenuOption
                icon={articlesSVG}
                text='Artículos'
                onClick={() => setSelectedOption(1)}
                selected={selectedOption === 1}
                open={params.open}
              />
            </Link>
          </div>
          <div className='h-12'>
            <Link to='/mensajes'>
              <SideMenuOption
                icon={messagesSVG}
                text='Mensajes'
                onClick={() => setSelectedOption(2)}
                selected={selectedOption === 2}
                open={params.open}
              />
            </Link>
          </div>
          <div className='h-12'>
            <SideMenuOption
              icon={addsSVG}
              text='Impulsar anuncios'
              onClick={() => setSelectedOption(3)}
              selected={selectedOption === 3}
              open={params.open}
            />
          </div>
          <div className='h-12'>
            <SideMenuOption
              icon={statsSVG}
              text='Estadísticas'
              onClick={() => setSelectedOption(4)}
              selected={selectedOption === 4}
              open={params.open}
            />
          </div>
          <div className='h-12'>
            {userInfo?.isAdmin && userInfo?.storeId ? (
              <Link to='/usuarios'>
                <SideMenuOption
                  icon={userSVG}
                  text='Usuarios'
                  onClick={() => setSelectedOption(5)}
                  selected={selectedOption === 5}
                  open={params.open}
                />
              </Link>
            ) : (
              ''
            )}
          </div>
          <div className='h-12'>
            <Link to='/ordenes'>
              <SideMenuOption
                icon={orderSVG}
                text='Ordenes'
                onClick={() => setSelectedOption(6)}
                selected={selectedOption === 6}
                open={params.open}
              />
            </Link>
          </div>
          <div className='h-12'>
            <Link to='/pagos'>
              <SideMenuOption
                icon={paymentsSVG}
                text='Pagos'
                onClick={() => setSelectedOption(7)}
                selected={selectedOption === 7}
                open={params.open}
              />
            </Link>
          </div>

          <div className='h-12'>
            <Link to='/configuracion'>
              <SideMenuOption
                icon={settingsSvg}
                text='Conf. de cuenta'
                onClick={() => setSelectedOption(8)}
                selected={selectedOption === 8}
                open={params.open}
              />
            </Link>
          </div>
          <div className='h-12'>
            <Link to='/entrenamiento'>
              <SideMenuOption
                icon={academicCap}
                text='Entrenamiento'
                onClick={() => setSelectedOption(9)}
                selected={selectedOption === 9}
                open={params.open}
              />
            </Link>
          </div>
        </div>
      </div>
      <div>
        <BottomMenu userInfo={userInfo} open={params.open} />
      </div>
    </div>
  );
};

export default SideMenu;
