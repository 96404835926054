import { useQuery, useManualQuery } from 'graphql-hooks';

const AddLabelMutation = `
  mutation addLabel($title: String, $description: String, $module: String) {
    addLabel(title: $title, description: $description, module: $module) {
      label {
        id
        title
        description
        module
        deleted
      }
      error
    }
  }
`;

const useAddLabel = () => {
  const { loading, error, data: labelResponse } = useQuery(AddLabelMutation);
  return { loading, error, labelResponse };
};

const useAddLabelManual = () => {
  const [addLabel, { loading, error, data: labelResponse }] =
    useManualQuery(AddLabelMutation);
  return { addLabel, loading, error, labelResponse };
};

export { AddLabelMutation, useAddLabel, useAddLabelManual };
