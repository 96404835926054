import { useManualQuery } from 'graphql-hooks';

const SearchAsinQuery = `
query SearchAsin($asinNumber: String!) {
  searchAsin(asinNumber: $asinNumber) {
      price
      inCatalog
      manual,
      title,
      productDescription,
      productImages {
        id
        variations {
          size
          secure_url
          url
        }
      }
      itemProductInfo{
        id
        title
        price
        permalink
        thumbnail
        pictures{
          url
        }
      }
    }
  }
`;

const useSearchAsin = () => {
  const [loadAsinQuery, { loading, error, data }] =
    useManualQuery(SearchAsinQuery);
  const precioAsin = data?.searchAsin.price || 0;
  const inCatalog = data?.searchAsin.inCatalog || false;
  const manual = data?.searchAsin.manual || false;
  const title = data?.searchAsin.title || '';
  const productDescription = data?.searchAsin.productDescription || '';
  const productImages = data?.searchAsin.productImages || [];
  const itemProductInfo = data?.searchAsin.itemProductInfo || '';

  return {
    loadAsinQuery,
    precioAsin,
    inCatalog,
    manual,
    title,
    itemProductInfo,
    productDescription,
    productImages,
    loading,
  };
};

export { useSearchAsin, SearchAsinQuery };
