// interfaces similar to graphQL types in pollux-ml-api/src/gql-types/DomainInfo.js
export interface DomainPathItem {
  id: string;
  name: string | null;
}

export interface DomainInfoSettings {
  catalog_domain: string;
  currencies: string[];
  status: string;
  minimum_price: number;
  minimum_price_currency: string;
}

export interface DomainInfo {
  id: string;
  name: string;
  path_from_root: DomainPathItem[];
  label_path_from_root: String;
  date_created: string;
  settings: DomainInfoSettings;
}

export enum PRODUCT_FORM_STATES {
  SHOW_LOADING = 'FormShowLoading',
  SHOW_ERROR = 'FormShowError',
  SHOW_SUCCESS = 'FormShowSuccess',
  SUBMIT_LOADING = 'FormSubmitLoading',
  SUBMIT_ERROR = 'FormSubmitError',
  SUBMIT_SUCCESS = 'FormSubmitSuccess',
}

export interface AddProductBasicFormValues {
  productCategory: string;
  productDescription: string;
  productName: string;
  currency: string;
  price: number;
  stock: number;
  productImages: string[];
  attributes: any;
}

