const DocumentIcon = () => {
  return (
    <svg
      width='16'
      height='20'
      viewBox='0 0 16 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.66683 1.89154V5.33366C9.66683 5.80037 9.66683 6.03372 9.75766 6.21198C9.83755 6.36879 9.96504 6.49627 10.1218 6.57616C10.3001 6.66699 10.5335 6.66699 11.0002 6.66699H14.4423M14.6668 8.32385V14.3337C14.6668 15.7338 14.6668 16.4339 14.3943 16.9686C14.1547 17.439 13.7722 17.8215 13.3018 18.0612C12.767 18.3337 12.067 18.3337 10.6668 18.3337H5.3335C3.93336 18.3337 3.2333 18.3337 2.69852 18.0612C2.22811 17.8215 1.84566 17.439 1.60598 16.9686C1.3335 16.4339 1.3335 15.7338 1.3335 14.3337V5.66699C1.3335 4.26686 1.3335 3.5668 1.60598 3.03202C1.84566 2.56161 2.22811 2.17916 2.69852 1.93948C3.2333 1.66699 3.93336 1.66699 5.3335 1.66699H8.00998C8.62145 1.66699 8.92719 1.66699 9.21491 1.73607C9.47 1.79731 9.71386 1.89832 9.93754 2.03539C10.1898 2.19 10.406 2.40619 10.8384 2.83857L13.4953 5.49542C13.9276 5.9278 14.1438 6.14399 14.2984 6.39628C14.4355 6.61996 14.5365 6.86382 14.5978 7.11891C14.6668 7.40663 14.6668 7.71237 14.6668 8.32385Z'
        stroke='#98A2B3'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default DocumentIcon;
