import { useQuery, useManualQuery } from 'graphql-hooks';

const answerQuestionQuery = `
mutation AnswerQuestion ($questionId: Float!, $answer: String!) {
    answerQuestion(questionId: $questionId, answer: $answer){
        status
    }
}`;

export const useAnswerQuestion = (questionId, answer) => {
    const { data, loading, error } = useQuery(answerQuestionQuery, {
        variables: { questionId, answer }
    });

    return { data, loading, error };
}

export const useAnswerQuestionManual = () => {
    const [answerQuestion, { data, loading, error }] = useManualQuery(answerQuestionQuery);
    return { answerQuestion, data, loading, error };
}

export {answerQuestionQuery }