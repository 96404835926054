import { useState } from 'react';

const NewModalPrices = ({ product }) => {
  const [currency, setCurrency] = useState(product.currency_id);
  const [minPrice, setMinPrice] = useState(
    product.currentBid ? product.currentBid.minPrice : product.price
  );
  const [maxPrice, setMaxPrice] = useState(
    product.currentBid ? product.currentBid.maxPrice : product.price
  );
  return (
    <div className=' flex mt-6 justify-between bg-white'>
      <div className='w-[38%]'>
        <div className='w-full flex'>
          <span className='text-sm font-medium text-gray-700'>Minimo</span>
        </div>
        <div className='w-full flex pt-1 border-gray-300 shadow-sm items-end'>
          <div className='w-full'>
            <input
              type='text'
              className='h-[40px] w-full rounded-lg border border-gray-300 shadow-sm py-[8px] pr-0 pl-[12px]'
              value={minPrice}
              onChange={(e) =>
                /^[0-9\b]+$/.test(e.target.value) && setMinPrice(e.target.value)
              }
              maxLength={6}
            />
          </div>
        </div>
      </div>
      <div className='w-[38%]'>
        <div className='w-full flex'>
          <span className='text-sm font-medium text-gray-700'>Maximo</span>
        </div>
        <div className='w-full flex pt-1 border-gray-300 shadow-sm items-end'>
          <div className='w-full'>
            <input
              type='text'
              className='h-[40px] w-full rounded-lg border border-gray-300 shadow-sm py-[8px] pr-0 pl-[12px]'
              value={maxPrice}
              onChange={(e) =>
                /^[0-9\b]+$/.test(e.target.value) && setMaxPrice(e.target.value)
              }
              maxLength={6}
            />
          </div>
        </div>
      </div>
      <div className='w-[20%]'>
        <div className='w-full flex'>
          <span className='text-sm font-medium text-gray-700'>Moneda</span>
        </div>
        <div className='w-full flex pt-1 border-gray-300 shadow-sm items-end'>
          <div className='w-full'>
            <select
              className='h-[40px] rounded-lg border border-gray-300 pl-[8px] bg-white w-full'
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            >
              <option>USD</option>
              <option>UYU</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewModalPrices;
