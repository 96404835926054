import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import type { ECharts, EChartsOption } from 'echarts';
import { useGetStoreSalesStatsManual } from 'hooks/useGetStoreSalesStats';
import { PeriodSelector } from '../PeriodSelector/PeriodSelector';

export const SalesEvolution: React.FC = () => {
	const chartRef = useRef(null);
	const [chart, setChart] = useState<ECharts | null>(null);
	const { getStoreSalesStats, loading, error, data: utcData } = useGetStoreSalesStatsManual();
	const [currentView, setCurrentView] = useState<string>('year');
	const [chartTitle, setChartTitle] = useState<string>('Evolución de las ventas (por año)');
	const [data, setData] = useState<any>(null);

	useEffect(() => {
		const fetchData = async () => {
			await getStoreSalesStats({ variables: { period: currentView } });
		};
		fetchData();
	}, [getStoreSalesStats, currentView]);

	useEffect(() => {
		if (utcData) {
			const offset = new Date().getTimezoneOffset() * 60000;
			setData({
				getStoreSalesStats: utcData.getStoreSalesStats.map((item: any) => ({
					...item,
					time: `${parseInt(item.time) + offset}`
				}))
			});
		}
	}, [utcData]);

	useEffect(() => {
		const initChart = () => {
			if (chartRef.current) {
				const newChart = echarts.init(chartRef.current);
				setChart(newChart);

				const loadingOption: EChartsOption = {
					text: 'Cargando datos...',
					color: '#9E77ED',
					textColor: '#000',
					maskColor: 'rgba(255, 255, 255, 0.8)',
					zlevel: 0
				};

				if (loading) {
					newChart.showLoading(loadingOption as any);
				} else if (data) {
					newChart.hideLoading();

					const salesData = data.getStoreSalesStats
						.sort((a: any, b: any) => parseInt(a.time) - parseInt(b.time));

					const dates = salesData.map((item: any) => new Date(parseInt(item.time)));
					const uniqueDates = Array.from(new Set(dates.map((date: Date) => date.getTime())));
					const xAxisData = uniqueDates.map((timestamp: any) => {
						const date = new Date(timestamp);
						if (date.getTime() > new Date().getTime()) return null;
						
						return currentView === 'year'
							? `${date.toLocaleString('default', { month: 'short' })}\n${date.getFullYear()}`
							: `${date.getDate()}\n${date.toLocaleString('default', { month: 'short' })}`;
					}).filter(Boolean);

					const getSeriesData = (filter: (item: any) => boolean) => {
						return uniqueDates.map(timestamp => {
							const matchingItems = salesData.filter((item: any) =>
								parseInt(item.time) === timestamp && filter(item)
							);
							return matchingItems.reduce((sum: number, item: any) => sum + item.totalQuantity, 0);
						});
					};

					const confirmedCatalogSales = getSeriesData(item => item.confirmed && item.catalogListing);
					const confirmedMarketplaceSales = getSeriesData(item => item.confirmed && !item.catalogListing);
					const unconfirmedCatalogSales = getSeriesData(item => !item.confirmed && item.catalogListing);
					const unconfirmedMarketplaceSales = getSeriesData(item => !item.confirmed && !item.catalogListing);

					const option: EChartsOption = {
						title: { text: chartTitle, left: 'left' },
						tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
						legend: {
							data: ['Confirmadas de catálogo', 'Confirmadas de marketplace', 'No confirmadas de catálogo', 'No confirmadas de marketplace'],
							bottom: '0%', left: 'center', padding: [10, 0, 0, 0]
						},
						grid: { left: '3%', right: '4%', bottom: '15%', containLabel: true },
						xAxis: [{
							type: 'category',
							data: xAxisData as any,
							axisLabel: {
								interval: 0,
								formatter: (value: string) => {
									if (currentView === 'year') {
										const [month, year] = value.split('\n');
										return `{a|${month}}\n{b|${year}}`;
									} else {
										const [day, month] = value.split('\n');
										return `{a|${day}}\n{b|${month}}`;
									}
								},
								rich: {
									a: { fontWeight: 'bold', lineHeight: 20 },
									b: { fontSize: 10, lineHeight: 20 }
								}
							}
						}],
						yAxis: [{ type: 'value', name: 'Número de productos', nameLocation: 'middle', nameGap: 35 }],
						series: [
							{ name: 'Confirmadas de catálogo', type: 'bar', stack: 'total', itemStyle: { color: '#8E77ED' }, data: confirmedCatalogSales },
							{ name: 'Confirmadas de marketplace', type: 'bar', stack: 'total', itemStyle: { color: '#C6B9E8' }, data: confirmedMarketplaceSales },
							{ name: 'No confirmadas de catálogo', type: 'bar', stack: 'total', itemStyle: { color: '#FFD700' }, data: unconfirmedCatalogSales },
							{ name: 'No confirmadas de marketplace', type: 'bar', stack: 'total', itemStyle: { color: '#E6D388' }, data: unconfirmedMarketplaceSales }
						]
					};

					newChart.setOption(option as any);
				}
			}
		};

		initChart();

		const handleResize = () => {
			chart?.resize();
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
			chart?.dispose();
		};
	}, [data, loading, chart, currentView, chartTitle]);

	useEffect(() => {
		if (chart) {
			chart.setOption({
				title: {
					text: chartTitle
				}
			});
		}
	}, [chart, chartTitle]);

	const updateView = (view: string) => {
		setCurrentView(view);
		setChartTitle(`Evolución de las ventas (${view === 'year' ? 'último año' : view === 'month' ? 'último mes' : 'última semana'})`);
	};

	if (error) return <div>Error al cargar los datos</div>;

	return (
		<div style={{ position: 'relative', width: '100%', height: '100%' }}>
			<div style={{
				position: 'absolute',
				top: '10px',
				right: '60px',
				zIndex: 1
			}}>
				<PeriodSelector currentView={currentView} onViewChange={updateView} />
			</div>
			<div
				ref={chartRef}
				style={{
					width: '100%',
					height: '100%',
					minHeight: '400px',
					maxWidth: '100%',
					overflow: 'hidden'
				}}
			/>
		</div>
	);
};
