import { useQuery, useManualQuery } from 'graphql-hooks';

const GET_ML_CATEGORY_BY_ASIN_QUERY = `
query GetMlCategoryByAsin($asin: String!) {
    getMlCategoryByAsin(asin: $asin) {
        title
        categoryId
        categoryPath {
            id
            name
        }
        attributes {
            id
            name
        }
    }
}
`;

const useGetMlCategoryByAsin = (asin: string) => {
  const { loading, error, data } = useQuery(GET_ML_CATEGORY_BY_ASIN_QUERY, {
    variables: {
      asin,
    },
  });
  return {
    loading,
    error,
    data,
  };
};

const useGetMlCategoryByAsinManual = () => {
  const [getMlCategoryByAsin, { loading, data, error }] = useManualQuery(
    GET_ML_CATEGORY_BY_ASIN_QUERY
  );
  return {
    loading,
    error,
    data,
    getMlCategoryByAsin,
  };
};

export {
  GET_ML_CATEGORY_BY_ASIN_QUERY,
  useGetMlCategoryByAsin,
  useGetMlCategoryByAsinManual,
};
