import { useRef, useState } from 'react';
import { useBidOnProductManual } from '../hooks/useBidOnProduct';
import XMarkIcon from './utils/iconComponents/XMarkIcon';
import SquareMinusIcon from './utils/iconComponents/SquareMinusIcon';
import ContentEditable from './utils/ContentEditable';
import SquarePlusIcon from './utils/iconComponents/SquarePlusIcon';

export const BidOnProductModal = ({
  product,
  currentMin,
  currentMax,
  onBid,
  onCancel,
}) => {
  const { bidOnProduct, data, loading, error } = useBidOnProductManual();
  const [minPrice, setMinPrice] = useState(
    product.currentBid ? product.currentBid.minPrice : product.price
  );
  const [maxPrice, setMaxPrice] = useState(
    product.currentBid ? product.currentBid.maxPrice : product.price
  );

  const timer = useRef(null);

  const increment = (add) => {
    timer.current = setInterval(
      () =>
        add
          ? setMaxPrice((prev) => +prev + 1)
          : setMinPrice((prev) => +prev + 1),
      130
    );
  };

  const decrement = (add) => {
    timer.current = setInterval(
      () =>
        add ? setMaxPrice((prev) => prev - 1) : setMinPrice((prev) => prev - 1),
      130
    );
  };

  function timeoutClear() {
    clearInterval(timer.current);
  }

  const handleClickBackground = (e) => {
    typeof onCancel === 'function' && onCancel();
    e.stopPropagation();
  };

  const handleBidOnProduct = async () => {
    if (!product.permalink.includes('/p/')) return; // TODO: show error
    const bid = await bidOnProduct({
      variables: {
        productId: product.id,
        catalogProductId: product.permalink.split('/p/')[1],
        minPrice: parseFloat(minPrice),
        maxPrice: parseFloat(maxPrice),
        currency: product.currency_id,
        active: true,
      },
    });
    // console.log('bid product en modal',bid);
    typeof onBid === 'function' && onBid(bid);
  };

  return (
    <div
      className='fixed top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-50 flex justify-center items-center backdrop-blur-sm'
      onClick={handleClickBackground}
    >
      <div className='p-4' onClick={(e) => e.stopPropagation()}>
        <div className='w-[480px] h-[316px] bg-gray-700 rounded-lg shadow border-gray-300'>
          <div className='w-full h-[85px] bg-white rounded-t-lg border border-l-0 border-t-0 border-r-0 border-b-gray-200'>
            {/* content */}
            <div className='w-[385px] h-[78px] pt-4 pr-6 pb-0 pl-6 gap-4 flex'>
              {/* featured icon */}
              {/* text and support */}
              <div className='w-[385px] h-[54px] gap-1'>
                <div className='w-[385px] h-[30px]'>
                  <span className='font-semibold text-xl'>Pujar</span>
                </div>
                <div className='w-[385px] h-[20px]'>
                  <span className='font-normal text-sm text-gray-600'>
                    Selecciona el precio minimo y maximo del articulo
                  </span>
                </div>
              </div>
              {/* button close */}
              <div className='w-[44px] h-[44px] p-2.5 rounded-lg'>
                <div className='w-[24px] h-[24px]'>
                  <div
                    className='w-[24px] h-[24px] t-1.5 l-1.5 cursor-pointer'
                    onClick={onCancel}
                  >
                    <XMarkIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* medio */}
          <div className='w-full h-[146px] gap-5 bg-white pt-8 pr-6 pb-8 pl-6'>
            <div className='w-[432px] h-full gap-3 flex justify-around flex-col'>
              <div className='w-full h-[30px] flex justify-between'>
                <div className='w-full h-[30px] font-semibold'>
                  Precio minimo
                </div>
                <div className='flex w-full justify-end'>
                  <div className='flex w-[50%] justify-between gap gap-3 items-center'>
                    <div className='h-full w-5 flex justify-center items-center'>
                      <div
                        onMouseLeave={timeoutClear}
                        onMouseUp={timeoutClear}
                        onMouseDown={() => decrement(false)}
                        className='cursor-pointer h-[15px] w-[15px] flex justify-center items-center'
                      >
                        <SquareMinusIcon />
                      </div>
                    </div>
                    <div className='w-[60%] max-w-[60%] flex justify-center'>
                      <span className='mr-1'>$</span>
                      <input
                        type='text'
                        value={minPrice}
                        onChange={(e) =>
                          /^[0-9\b]+$/.test(e.target.value) &&
                          setMinPrice(e.target.value)
                        }
                        minLength={0}
                        maxLength={6}
                        size={5}
                        className='text-center'
                      />
                    </div>
                    <div className='h-full w-5 flex justify-center items-center'>
                      <div
                        onMouseLeave={timeoutClear}
                        onMouseUp={timeoutClear}
                        onMouseDown={() => increment(false)}
                        className='cursor-pointer h-[15px] w-[15px] flex justify-center items-center'
                      >
                        <SquarePlusIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='w-full h-[30px] flex justify-between'>
                <div className='w-full h-[30px] font-semibold'>
                  Precio minimo
                </div>
                <div>
                  <input
                    type='number'
                    className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-3 pr-1 h-[30px]'
                    value={minPrice}
                    onChange={(e) => setMinPrice(e.target.value)}
                    placeholder='mínimo'
                  />
                </div>
              </div> */}
              {/* <div className='w-full h-[30px] flex justify-between'>
                <div className='w-full h-[30px] font-semibold'>
                  Precio maximo
                </div>
                <div>
                  <input
                    type='number'
                    className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-3 pr-1 h-[30px]'
                    value={maxPrice}
                    onChange={(e) => setMaxPrice(e.target.value)}
                    placeholder='máximo'
                  />
                </div>
              </div> */}
              <div className='w-full h-[30px] flex justify-between'>
                <div className='w-full h-[30px] font-semibold'>
                  Precio maximo
                </div>
                <div className='flex w-full justify-end'>
                  <div className='flex w-[50%] justify-between gap gap-3 items-center'>
                    <div className='h-full w-5 flex justify-center items-center'>
                      <div
                        onMouseLeave={timeoutClear}
                        onMouseUp={timeoutClear}
                        onMouseDown={() => decrement(true)}
                        className='cursor-pointer h-[15px] w-[15px] flex justify-center items-center'
                      >
                        <SquareMinusIcon />
                      </div>
                    </div>
                    <div className='w-[60%] max-w-[60%] flex justify-center'>
                      <span className='mr-1'>$</span>
                      <input
                        type='text'
                        value={maxPrice}
                        onChange={(e) =>
                          /^[0-9\b]+$/.test(e.target.value) &&
                          setMaxPrice(e.target.value)
                        }
                        minLength={0}
                        maxLength={6}
                        size={5}
                        className='text-center'
                      />
                    </div>
                    <div className='h-full w-5 flex justify-center items-center'>
                      <div
                        onMouseLeave={timeoutClear}
                        onMouseUp={timeoutClear}
                        onMouseDown={() => increment(true)}
                        className='cursor-pointer h-[15px] w-[15px] flex justify-center items-center'
                      >
                        <SquarePlusIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* footer */}
          <div className='w-full h-[85px] bg-white rounded-b-lg border border-l-0 border-b-0 border-r-0 border-t-gray-200'>
            <div className='w-full h-full p-5 flex justify-between'>
              <div
                className={`text-black bg-white border border-gray-200 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md  transition ease-in-out duration-150' w-[210px] h-[44px] justify-center`}
                onClick={onCancel}
              >
                <div>Cancelar</div>
              </div>
              <div
                className={`text-white bg-primary-700 border border-primary-700 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md  transition ease-in-out duration-150' w-[210px] h-[44px] justify-center`}
                onClick={handleBidOnProduct}
              >
                <div>Guardar</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
