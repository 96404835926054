import { useQuery, useManualQuery } from 'graphql-hooks';

const GET_ML_ITEM_BY_ID = `
query GetMlItemById($id: String!) {
  getMlItemById(id: $id) {
    start_time
    permalink
    sold_quantity
    available_quantity
    catalog_product_id
    seller_custom_field
    id
    title
    category_id
    status
    catalog_listing
    descriptions
    description
    categoryPath
    pictures {
      id
      url
      secure_url
      size
      max_size
      quality
      link
      variant
      width
      height
      id
    }
    shipping {
      mode
      dimensions
      local_pick_up
      free_shipping
      methods
      tags
    }
    attributes {
      name
      value
    }
    tags
    price
    currency_id
    item_relations {
      id
      variation_id
      stock_relation
    }
  }
}
`;

export const useGetMlItemById = (id: string) => {
  const { loading, error, data } = useQuery(GET_ML_ITEM_BY_ID, {
    variables: { id },
  });
  return { loading, error, data };
}

export const useGetMlItemByIdManual = () => {
  const [getMlItemById, { loading, error, data }] = useManualQuery(GET_ML_ITEM_BY_ID);
  return { getMlItemById, loading, error, data };
}
