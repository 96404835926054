import React from 'react';

function getProductPermalink(id: string): string {
  const formattedId: string = (id || '').replace('MLU', 'MLU-');
  return `https://articulo.mercadolibre.com.uy/${formattedId}`;
}

function AddCatalogProductResultSuccess({
  handleCloseOkSuccess,
  addCatalogProductInfo,
  closeButtonLabel = 'Cerrar',
}: {
  handleCloseOkSuccess: () => void;
  addCatalogProductInfo: string;
  closeButtonLabel?: string;
}) {
  return (
    <div className='m-5'>
      <div className='text-xl font-bold mb-4'>
        El producto fue publicado con éxito
      </div>
      <a
        href={getProductPermalink(addCatalogProductInfo)}
        className='text-primary-500 hover:underline'
        rel='noreferrer'
        target='_blank'
      >
        Ver artículo publicado
      </a>
      <div className='flex justify-center'>
        <button
          className='bg-primary-700 text-white rounded-md px-2 py-1 mr-2 mt-10'
          onClick={handleCloseOkSuccess}
        >
          {closeButtonLabel}
        </button>
      </div>
    </div>
  );
}

function AddCatalogProductResultLoading() {
  return <div>Publishing product...</div>;
}

function AddCatalogProductResultError() {
  return <div>Error during publishing process</div>;
}

export {
  AddCatalogProductResultSuccess,
  AddCatalogProductResultLoading,
  AddCatalogProductResultError,
};
