const { useManualQuery } = require('graphql-hooks');

export const GET_SELECTED_PRODUCT_ATTRIBUTES_QUERY = `
query GetSelectedProductAttributesValues($categoryId: String!, $asin: String!, $selectedAttributes: SelectedAttributesInfo!) {
    getSelectedProductAttributesValues(categoryId: $categoryId, asin: $asin, selectedAttributes: $selectedAttributes) {
        name
        value
    }
}
`

export const useGetSelectedProductAttributesValuesManual = () => {
    const [getSelectedProductAttributesValues, { loading, error, data }] = useManualQuery(GET_SELECTED_PRODUCT_ATTRIBUTES_QUERY);

    return {
        getSelectedProductAttributesValues,
        loading,
        error,
        data,
    };
}
