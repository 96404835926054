import { useQuery, useManualQuery } from "graphql-hooks";

export const GET_STORE_INFRACTIONS_STATS_QUERY = `
	query GetStoreInfractionsStats {
		getStoreInfractionsStats {
			noInfractions
			contentInfractions
			qualityInfractions
    }
  }
`;

export const useGetStoreInfractionsStatsManual = () => {
	const [getStoreInfractionsStats, { loading, error, data }] = useManualQuery(GET_STORE_INFRACTIONS_STATS_QUERY);

	return {
		getStoreInfractionsStats,
		loading,
		error,
		data
	};
};

