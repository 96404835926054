import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import type { ECharts, EChartsOption } from 'echarts';
import { useGetStoreItemsStats } from 'hooks/useGetStoreItemsStats';
import { PeriodSelector } from '../PeriodSelector/PeriodSelector';

export const CatalogAndMarketplaceEvolution: React.FC = () => {
	const chartRef = useRef(null);
	const [chart, setChart] = useState<ECharts | null>(null);
	const [currentView, setCurrentView] = useState<string>('month');
	const { getStoreItemsStats, loading, error, data } = useGetStoreItemsStats();

	useEffect(() => {
		if (chartRef.current) {
			const newChart = echarts.init(chartRef.current);
			setChart(newChart);
		}
		getStoreItemsStats({ variables: { period: currentView } });
		return () => {
			chart?.dispose();
		};
	}, [currentView]);

	useEffect(() => {
		if (chart && data?.getStoreItemsStats) {
			const stats = data.getStoreItemsStats;
			const option: EChartsOption = {
				title: {
					text: `Evolución del Catálogo y Marketplace (${getPeriodText(currentView)})`,
					left: 'left',
					top: '10px'
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						label: {
							backgroundColor: '#6a7985'
						}
					}
				},
				legend: {
					data: ['Marketplace', 'Elegibles para catálogo', 'En catálogo perdiendo', 'En catálogo ganando'],
					bottom: '0%',
					left: 'center',
					padding: [10, 0, 0, 0]
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '15%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: false,
						data: stats.map((item: any) => getXAxisLabel(currentView, new Date(parseInt(item.timestamp)))),
						axisLabel: {
							formatter: (value: string, index: number) => {
								const parts = value.split('\n');
								return parts.map((part, i) => {
									if (i === 0) return `{a|${part}}`;
									if (i === 1) return `{b|${part}}`;
									return `{c|${part}}`;
								}).join('\n');
							},
							rich: {
								a: {
									lineHeight: 20,
									align: 'center'
								},
								b: {
									lineHeight: 20,
									align: 'center'
								},
								c: {
									lineHeight: 20,
									align: 'center',
									fontSize: 10
								}
							}
						}
					}
				],
				yAxis: [
					{
						type: 'value',
						name: 'Número de productos',
						nameLocation: 'middle',
						nameGap: 50
					}
				],
				series: [
					{
						name: 'Marketplace',
						type: 'line',
						stack: 'Total',
						areaStyle: {
							color: '#C6B9E8',
							opacity: 0.9,
							...(currentView === 'month' && {
								opacity: 0.9,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{ offset: 0, color: '#C6B9E8' },
									{ offset: 1, color: '#C6B9E8' }
								]),
								areaStyle: {
									color: {
										image: getPatternImage(),
										repeat: 'repeat'
									}
								}
							})
						},
						itemStyle: { color: '#C6B9E8' },
						emphasis: { focus: 'series' },
						data: stats.map((item: any) => item.marketplaceNotEligible)
					},
					{
						name: 'Elegibles para catálogo',
						type: 'line',
						stack: 'Total',
						areaStyle: {
							color: '#9E77ED',
							opacity: 0.9,
							...(currentView === 'month' && {
								opacity: 0.9,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{ offset: 0, color: '#9E77ED' },
									{ offset: 1, color: '#9E77ED' }
								]),
								areaStyle: {
									color: {
										image: getPatternImage(),
										repeat: 'repeat'
									}
								}
							})
						},
						itemStyle: { color: '#9E77ED' },
						emphasis: { focus: 'series' },
						data: stats.map((item: any) => item.marketplaceEligible)
					},
					{
						name: 'En catálogo perdiendo',
						type: 'line',
						stack: 'Total',
						areaStyle: {
							color: '#7F56D9',
							opacity: 0.9,
							...(currentView === 'month' && {
								opacity: 0.9,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{ offset: 0, color: '#7F56D9' },
									{ offset: 1, color: '#7F56D9' }
								]),
								areaStyle: {
									color: {
										image: getPatternImage(),
										repeat: 'repeat'
									}
								}
							})
						},
						itemStyle: { color: '#7F56D9' },
						emphasis: { focus: 'series' },
						data: stats.map((item: any) => item.losingInCatalog)
					},
					{
						name: 'En catálogo ganando',
						type: 'line',
						stack: 'Total',
						areaStyle: {
							color: '#53389E',
							opacity: 0.9,
							...(currentView === 'month' && {
								opacity: 0.9,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{ offset: 0, color: '#53389E' },
									{ offset: 1, color: '#53389E' }
								]),
								areaStyle: {
									color: {
										image: getPatternImage(),
										repeat: 'repeat'
									}
								}
							})
						},
						itemStyle: { color: '#53389E' },
						emphasis: { focus: 'series' },
						data: stats.map((item: any) => item.winningInCatalog)
					}
				]
			};
			chart.setOption(option as any);
		}
	}, [chart, data, currentView]);

	useEffect(() => {
		const handleResize = () => {
			chart?.resize();
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [chart]);

	const handleViewChange = (view: string) => {
		setCurrentView(view);
		getStoreItemsStats({ variables: { period: view } });
	};

	const periods = [
		{ id: 'month', label: 'Mes' },
		{ id: 'week', label: 'Semana' },
		{ id: 'day', label: 'Día' }
	];

	const getPeriodText = (view: string) => {
		switch (view) {
			case 'month': return 'Mes';
			case 'week': return 'Semana';
			case 'day': return 'Día';
			default: return 'Mes';
		}
	};

	const getXAxisLabel = (view: string, date: Date) => {
		const monthNames = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
		const dayOfMonth = date.getDate();
		const month = monthNames[date.getMonth()];

		switch (view) {
			case 'month':
				return `${dayOfMonth}\n${month}`;
			case 'week':
				return `${dayOfMonth}\n${month}`;
			case 'day':
				const hours = date.getHours().toString().padStart(2, '0');
				const minutes = date.getMinutes().toString().padStart(2, '0');
				return `${hours}:${minutes}\n${dayOfMonth} ${month}`;
			default:
				return `${dayOfMonth}\n${month}`;
		}
	};

	const getPatternImage = () => {
		const canvas = document.createElement('canvas');
		canvas.width = 10;
		canvas.height = 10;
		const ctx = canvas.getContext('2d');
		if (ctx) {
			ctx.strokeStyle = 'rgba(255, 255, 255, 0.3)';
			ctx.lineWidth = 1;
			ctx.beginPath();
			ctx.moveTo(0, 10);
			ctx.lineTo(10, 0);
			ctx.stroke();
		}
		return canvas.toDataURL();
	};

	if (error) return <div>Error al cargar los datos</div>;

	return (
		<div style={{ position: 'relative', width: '100%', height: '100%' }}>
			<div style={{
				position: 'absolute',
				top: '10px',
				right: '75px',
				zIndex: 1
			}}>
				<PeriodSelector currentView={currentView} onViewChange={handleViewChange} periods={periods} />
			</div>
			<div
				ref={chartRef}
				style={{
					width: '100%',
					height: '100%',
					minHeight: '400px',
					maxWidth: '100%',
					overflow: 'hidden'
				}}
			/>
		</div>
	);
};
