import React, { useMemo, useState } from 'react';
import searchIcon from './images/Search.svg';
import _ from 'lodash';

export const SearchBox = ({
  query,
  onQueryChanged,
  placeholder = 'Buscar',
  sizeWidth = '',
}) => {
  const [searchText, setSearchText] = useState(query);
  const debouncedOnQueryChanged = useMemo(
    () => _.debounce(onQueryChanged, 200),
    [onQueryChanged]
  );

  const handleTextChange = (event) => {
    setSearchText(event.target.value);
    debouncedOnQueryChanged(event.target.value);
  };

  return (
    <div className='flex flex-inline'>
      <img src={searchIcon} alt='Search' />{' '}
      <input
        className={`outline-0 ml-2 border-0 focus:outline-none focus:ring-0 ${sizeWidth}`}
        type='text'
        value={searchText}
        placeholder={placeholder}
        onChange={handleTextChange}
      />
    </div>
  );
};
