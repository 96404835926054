import { useQuery, useManualQuery } from 'graphql-hooks';

const query = `
query GetMlItemsByAsin($asin: String!) {
    getMlItemsByAsin(asin: $asin) {
        id
        title
        category_id
        status
        catalog_listing
        descriptions
        pictures {
            url
            secure_url
            size
            max_size
            quality
        }
        tags
        price
        currency_id
        item_relations {
            id
            variation_id
            stock_relation
        }
        seller_custom_field
    }
}
`

export const useGetMlItemsByAsin = (asin: string) => {
    const { loading, error, data } = useQuery(query, {
        variables: {
            asin
        }
    });
    return {
        loading,
        error,
        data
    }
}

export const useGetMlItemsByAsinManual = () => {
    const [getMlItemsByAsin, { loading, data, error }] = useManualQuery(query);
    return {
        loading,
        error,
        data,
        getMlItemsByAsin
    }
}