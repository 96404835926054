import { useState, useEffect } from 'react';
import { useGetPredictedCategoryManual } from 'hooks/useGetPredictedCategory';

export const useCategoryPredictor = () => {
    const { loading, error, data, getPredictedCategory } = useGetPredictedCategoryManual();
    const [categoryPath, setCategoryPath] = useState<{id: string, name: string}[]>([]);

    useEffect(() => {
        const loadPredictedCategoryPath = async () => {
            if (data?.getPredictedCategory) {
                setCategoryPath(data.getPredictedCategory);
            }
        };
        loadPredictedCategoryPath();
    }, [data]);

    return {
        loading,
        error,
        categoryPath,
        setCategoryPath,
        getPredictedCategory,
    };
};
