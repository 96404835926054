const AttachmentIcon = ({ color = '#344054', title = '' }) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        d='M16.6271 8.08247L9.1141 15.5955C7.40556 17.304 4.63546 17.304 2.92692 15.5955C1.21837 13.8869 1.21837 11.1168 2.92692 9.4083L10.4399 1.89529C11.579 0.756258 13.4257 0.756258 14.5647 1.89529C15.7037 3.03432 15.7037 4.88105 14.5647 6.02008L7.34633 13.2385C6.77682 13.808 5.85345 13.808 5.28394 13.2385C4.71442 12.6689 4.71442 11.7456 5.28394 11.1761L11.6184 4.84157'
        stroke={color}
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default AttachmentIcon;
