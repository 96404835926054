import { useQuery, useManualQuery } from 'graphql-hooks';

const EditLabelMutation = `
  mutation editLabel($id:String, !$title: String, $description: String, $module: String) {
    editLabel(id: $id, title: $title, description: $description, module: $module) {
      label {
        id
        title
        description
        deleted
        module
      }
      error
    }
  }
`;

const useEditLabel = () => {
  const { loading, error, data: labelResponse } = useQuery(EditLabelMutation);
  return { loading, error, labelResponse };
};

const useEditLabelManual = () => {
  const [editLabel, { loading, error, data: labelResponse }] = useManualQuery(EditLabelMutation);
  return { editLabel, loading, error, labelResponse };
};

export {
  EditLabelMutation,
  useEditLabel,
  useEditLabelManual,
};