import { useQuery, useManualQuery } from 'graphql-hooks';

const searchPermissionQuery = `
query searchPermission {
    searchPermission {
    storeId
    username
    isAdmin
    usersInStore {
      id
      email
      permissions 
    }
    lastActiveStore {
      emailAdmin
      permissions
    } 
  }
}
`;

const useSearchPermission = (query) => {
  const { loading, error, data } = useQuery(searchPermissionQuery);
  return { loading, error, data }; 
}

const useSearchPermissionManual = () => {
  const [searchPermission, { loading, error, data }] = useManualQuery(searchPermissionQuery);
  
  const { lastActiveStore, isAdmin } = data ? data : { lastActiveStore: [], isAdmin: false };
  return { searchPermission, loading, error, lastActiveStore, isAdmin }; 
}

export {
  useSearchPermission,
  useSearchPermissionManual,
  searchPermissionQuery
};