import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  token: string | null;
  email: string | null;
}

interface TokenData {
  token: string;
  email: string;
}

const initialState: AuthState = {
  token: null,
  email: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSession(state, action: PayloadAction<TokenData>) {
      state.token = action.payload.token;
    },
    clearSession(state) {
      state.token = null;
      state.email = null;
    },
  },
});

export const { setSession, clearSession } = authSlice.actions;

export { authSlice };
