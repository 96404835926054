import React, { useEffect, useState } from 'react';
import { useGetAmazonProductByAsinManual } from '../../hooks/useGetAmazonProductByAsin';
import { useGetAttributeMatchesManual } from '../../hooks/useGetAttributeMatches';
import { useGetMlCategoryByAsinManual } from '../../hooks/useGetMlCategoryByAsin';
import { useGetTranslatedDescriptionByAsinManual } from '../../hooks/useGetTranslatedDescriptionByAsin';
import { useGetFilledAttributesManual } from 'hooks/useGetFilledAttributes';
import { useSaveAttributeTrainingExampleManual } from 'hooks/useSaveAttributeTrainingExample';
import { EXCLUDED_ATTRIBUTES, validateAsin } from './TrainingPage';
import { useAddFixedMatchManual } from 'hooks/useAddFixedMatchMutation';

export const TrainingForm = ({ onExampleSaved = () => null }: { onExampleSaved: (count: Number) => void; }) => {
    const { getAmazonProductByAsin, loading: loadingAmazonProduct, error: errorAmazonProduct, data: amazonProduct } = useGetAmazonProductByAsinManual();
    const { getAttributeMatches, loading: loadingAttributeMatches, error: errorAttributeMatches, data: attributeMatches } = useGetAttributeMatchesManual();
    const { getMlCategoryByAsin, loading: loadingMlCategory, error: errorMlCategory, data: mlCategory } = useGetMlCategoryByAsinManual();
    const { getTranslatedDescriptionByAsin, loading: loadingTranslatedDescription, error: errorTranslatedDescription, data: translatedDescription } = useGetTranslatedDescriptionByAsinManual();
    const { getFilledAttributes: getSelectedProductAttributesValues, loading: loadingSelectedProductAttributesValues, error: errorSelectedProductAttributesValues, data: selectedProductAttributesValues } = useGetFilledAttributesManual();
    const { saveAttributeTrainingExample, loading: loadingSaveAttributeTrainingExample, error: errorSaveAttributeTrainingExample, data: savedAttributeTrainingExample } = useSaveAttributeTrainingExampleManual();
    const { addFixedMatch, loading: loadingAddFixedMatch, data: addedFixedMatch } = useAddFixedMatchManual();

    const [mlAttributes, setMlAttributes] = useState<{ id: string; name: string; }[]>([]);
    const [enteredAsin, setEnteredAsin] = useState<string>('');
    const [asin, setAsin] = useState<string>('');
    const [selectedMlAttribute, setSelectedMlAttribute] = useState<string>('');
    const [hoveredMlAttribute, setHoveredMlAttribute] = useState<string>('');
    const [newAttributeMatches, setNewAttributeMatches] = useState<{ mlAttributeId: string; amazonAttributeId: string; }[]>([]);
    const [matchesChanged, setMatchesChanged] = useState<boolean>(false);
    const [correctedDescription, setCorrectedDescription] = useState<string | null>(null);
    const [descriptionCorrectionNotes, setDescriptionCorrectionNotes] = useState<string>('');
    const [attributeCorrectionNotes, setAttributeCorrectionNotes] = useState<string>('');
    const [correctedAttributes, setCorrectedAttributes] = useState<{ id: string; value: string; }[]>([]);
    const [correctedBulletPoints, setCorrectedBulletPoints] = useState<string | null>(null);
    const [bulletPointsCorrectionNotes, setBulletPointsCorrectionNotes] = useState<string>('');

    useEffect(() => {
        if (savedAttributeTrainingExample?.saveAttributeTrainingExample?.count > 0) {
            onExampleSaved(savedAttributeTrainingExample?.saveAttributeTrainingExample?.count);
        }
    }, [savedAttributeTrainingExample]);

    useEffect(() => {
        if (asin) {
            getMlCategoryByAsin({ variables: { asin: asin } });
        }
    }, [asin]);

    useEffect(() => {
        if (mlCategory) {
            setMlAttributes(mlCategory.getMlCategoryByAsin.attributes.filter((attribute: any) => !EXCLUDED_ATTRIBUTES.includes(attribute.id)));
            getAmazonProductByAsin({ variables: { asin: asin } });
        }
    }, [mlCategory]);

    useEffect(() => {
        if (amazonProduct) {
            getTranslatedDescriptionByAsin({ variables: { asin: asin } });
        }
    }, [amazonProduct]);

    useEffect(() => {
        if (mlCategory && amazonProduct) {
            getAttributeMatches({ variables: { asin: asin, categoryId: mlCategory.getMlCategoryByAsin.categoryId } });
        }
    }, [mlCategory, amazonProduct]);

    useEffect(() => {
        if (attributeMatches?.getAttributesMatches?.matches) {
            const mlAttributesIds = mlAttributes.map((attribute: any) => attribute.id);
            const matches = mlAttributesIds.map((mlAttributeId: string) => {
                const match = attributeMatches?.getAttributesMatches?.matches.find((match: any) => match.mlAttributeId === mlAttributeId);
                return match || { mlAttributeId, amazonAttributeId: '' };
            });
            setNewAttributeMatches(matches);
        }
    }, [attributeMatches]);

    const loading = loadingAmazonProduct || loadingAttributeMatches || loadingMlCategory || loadingTranslatedDescription;

    return <div>
        <h1 className='font-bold text-xl'>Entrenamiento - Correspondencia de atributos</h1>
        <span>ASIN:</span>
        <input type="text" className="border-2 ml-2  mt-8 border-gray-500 p-2 rounded-lg" value={enteredAsin} onChange={(e) => setEnteredAsin(e.target.value)} />
        <span className={`ml-2 ${validateAsin(enteredAsin) ? 'bg-primary-700 cursor-pointer' : 'bg-gray-500'} p-2 rounded-md text-white select-none mt-8`} onClick={() => validateAsin(enteredAsin) && setAsin(enteredAsin)}>Cargar</span>
        {loading && <div>
            <div>{loadingMlCategory ? "Cargando atributos de ML..." : "Cargando atributos de ML... (completado)"}</div>
            <div>{loadingAmazonProduct ? "Cargando atributos de Amazon..." : "Cargando atributos de Amazon... (completado)"}</div>
            <div>{(loadingMlCategory || loadingAmazonProduct) ? "Esperando atributos para cargar correspondencias" : loadingAttributeMatches ? "Cargando correspondencias de atributos..." : "Cargando correspondencias de atributos... (completado)"}</div>
            <div>{loadingTranslatedDescription ? "Cargando descripción traducida..." : "Cargando descripción traducida... (completado)"}</div>
        </div>}
        {!loading && <div>
            <div className='p-2'><a href={`https://www.amazon.com/dp/${asin}`} target="_blank" rel="noreferrer">{mlCategory?.getMlCategoryByAsin?.title}</a></div>
            <div className='p-2'>{mlCategory?.getMlCategoryByAsin?.categoryPath?.map((category: any) => category.name).join(' > ')}</div>
            <div className='flex'>
                {amazonProduct?.getAmazonProductByAsin?.images?.slice(0, 6).map((image: string) => <img key={image} src={image?.replace('_SL75_', '_SL200_')} className="w-1/8" />)}
            </div>
            {
                translatedDescription?.getTranslatedDescriptionByAsin?.bulletPoint && <div>
                    <h2 className="font-bold text-xl mt-8">Bullet point</h2>
                    <textarea
                        className="border-2 w-full mt-2 border-gray-500 p-2 rounded-lg"
                        value={correctedBulletPoints || translatedDescription?.getTranslatedDescriptionByAsin?.bulletPoint || ''} rows={5}
                        onChange={(e) => setCorrectedBulletPoints(e.target.value)}
                    />
                    {
                        correctedBulletPoints && <div>
                            <div>Notas de corrección:</div>
                            <textarea
                                className="border-2 w-full mt-2 border-gray-500 p-2 rounded-lg"
                                value={bulletPointsCorrectionNotes} rows={5} onChange={(e) => setBulletPointsCorrectionNotes(e.target.value)} />
                        </div>
                    }

                </div>
            }
            {
                translatedDescription?.getTranslatedDescriptionByAsin?.description && <div>
                    <h2 className="font-bold text-xl mt-8">Descripción</h2>
                    {(correctedDescription || translatedDescription?.getTranslatedDescriptionByAsin?.description) && <textarea
                        className="border-2 w-full mt-2 border-gray-500 p-2 rounded-lg"
                        value={correctedDescription || translatedDescription?.getTranslatedDescriptionByAsin?.description || ''} rows={5} onChange={(e) => setCorrectedDescription(e.target.value)} />}
                    {correctedDescription && <div>
                        <div>Notas de corrección:</div>
                        <textarea
                            className="border-2 w-full mt-2 border-gray-500 p-2 rounded-lg"
                            value={descriptionCorrectionNotes} rows={5} onChange={(e) => setDescriptionCorrectionNotes(e.target.value)} />
                    </div>}
                </div>
            }
            <div className="flex mt-8">
                <div className="w-1/3">
                    <div className="text-xl p-2 m-2 text-white bg-black">Mercado Libre</div>
                    <div className="p-4">
                        <ul>
                            {mlAttributes?.map((attribute: { id: string; name: string; }) => <li
                                key={attribute.id} className={`cursor-pointer ${selectedMlAttribute === attribute.id ? 'bg-green-700' : hoveredMlAttribute === attribute.id ? 'bg-green-200' : ''}`}
                                onMouseOver={() => setHoveredMlAttribute(attribute.id)}
                                onMouseOut={() => setHoveredMlAttribute('')}
                                onClick={() => setSelectedMlAttribute(attribute.id)}
                            >{attribute.name}
                            </li>)}
                        </ul>
                    </div>
                    <div>
                        <button className={`${loadingSelectedProductAttributesValues ? 'bg-gray-500' : 'bg-primary-500 cursor-pointer'} p-2 rounded-lg mt-4`} onClick={() => getSelectedProductAttributesValues({
                            variables: {
                                asin: asin,
                                jsonToFill: JSON.stringify(newAttributeMatches?.filter(a => !EXCLUDED_ATTRIBUTES.includes(a.mlAttributeId)).reduce((acc: any, match: { amazonAttributeId: string; mlAttributeId: string; }) => {
                                    const id = mlAttributes?.find((a: any) => a.id === match.mlAttributeId)?.name;
                                    if (id) acc[id] = match.amazonAttributeId;
                                    return acc;
                                }, {})),
                                amazonAttributes: JSON.stringify(amazonProduct?.getAmazonProductByAsin?.attributes
                                    ?.filter((amazonAttribute: any) => newAttributeMatches.some(match => amazonAttribute.name === match.amazonAttributeId))
                                    ?.reduce((acc: any, attribute: any) => {
                                        acc[attribute.name] = attribute.value;
                                        return acc;
                                    }, {}))
                            }
                        })}>Llenar atributos</button>
                        <button className={`${loadingSelectedProductAttributesValues || !selectedMlAttribute ? 'bg-gray-500' : 'bg-primary-500 cursor-pointer'} p-2 rounded-lg mt-4 ml-2`} onClick={() => {
                            if (!selectedMlAttribute) return;
                            console.log(selectedProductAttributesValues?.getFilledAttributes?.filledAttributes);
                            console.log("Atributo de ML seleccionado", selectedMlAttribute);
                            console.log("Atributo de Amazon seleccionado", newAttributeMatches?.find((a: any) => a.mlAttributeId === selectedMlAttribute)?.amazonAttributeId);
                            addFixedMatch({
                                variables: {
                                    mlAttributeId: selectedMlAttribute,
                                    amzAttributeId: newAttributeMatches?.find((a: any) => a.mlAttributeId === selectedMlAttribute)?.amazonAttributeId,
                                }
                            });
                        }}>Fijar matcheo</button>
                        <div>
                            {loadingAddFixedMatch ? "Guardando matcheo..." : addedFixedMatch?.addFixedMatch?.success ? "Matcheo guardado" : ""}
                        </div>
                        {selectedProductAttributesValues?.getFilledAttributes?.filledAttributes?.length > 0 ? <div>
                            Se llenaron {selectedProductAttributesValues.getFilledAttributes.filledAttributes.length} atributos
                        </div> : <div>No se llenaron atributos</div>}
                    </div>
                </div>
                <div className="w-2/3">
                    <div className="text-xl p-2 m-2 text-white bg-black">Valor asignado por IA</div>
                    {selectedMlAttribute && <div className="m-4">
                        <span>{mlAttributes.find(mlAttribute => mlAttribute.id === selectedMlAttribute)?.name}</span>
                        <input
                            type="text"
                            className="border-2 w-full mt-2 border-gray-500 p-2 rounded-lg"
                            value={selectedProductAttributesValues?.getFilledAttributes?.filledAttributes?.find((attr: any) => attr.id === mlAttributes.find(mlAttribute => mlAttribute.id === selectedMlAttribute)?.name)?.value || ''} />
                        <div>Atributo corregido</div>
                        <input
                            type="text"
                            className="border-2 w-full mt-2 border-gray-500 p-2 rounded-lg"
                            value={correctedAttributes?.find((correctedAttribute) => correctedAttribute.id === selectedMlAttribute)?.value || ''}
                            onChange={(e) => {
                                const ca = [...correctedAttributes];
                                const index = ca.findIndex(a => a.id === selectedMlAttribute);
                                if (index < 0) {
                                    ca.push({ id: selectedMlAttribute, value: e.target.value });
                                } else {
                                    ca[index] = { id: selectedMlAttribute, value: e.target.value };
                                }
                                setCorrectedAttributes(ca);
                            }} />
                    </div>}
                    <div className="text-xl p-2 m-2 text-white bg-black">Atributos de Amazon</div>
                    <div className="p-4">
                        <ul>
                            {amazonProduct?.getAmazonProductByAsin?.attributes
                                ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
                                ?.map((attribute: any) => <li
                                    key={attribute.name}
                                    className={`cursor-pointer ${newAttributeMatches?.find((a: any) => a.mlAttributeId === hoveredMlAttribute)?.amazonAttributeId === attribute.name ? 'bg-green-200' : newAttributeMatches?.find((a: any) => a.mlAttributeId === selectedMlAttribute)?.amazonAttributeId === attribute.name ? 'bg-green-700' : ''}`}
                                    onClick={() => {
                                        if (selectedMlAttribute) {
                                            const am = [...(newAttributeMatches || [])];
                                            const index = am.findIndex(a => a.mlAttributeId === selectedMlAttribute);
                                            if (index < 0) return;
                                            am[index] = {
                                                mlAttributeId: selectedMlAttribute,
                                                amazonAttributeId: attribute.name,
                                            };
                                            setNewAttributeMatches(am);
                                            setMatchesChanged(true);
                                        }
                                    }}
                                ><span className='font-bold'>{attribute.name}</span> ({attribute.value})</li>)}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        }
        <div>
            <h2 className="font-bold text-xl mt-8">Anotaciones</h2>
            <div>
                <div>Notas de corrección de atributos:</div>
                <textarea
                    className="border-2 w-full mt-2 border-gray-500 p-2 rounded-lg"
                    value={attributeCorrectionNotes} rows={5} onChange={(e) => setAttributeCorrectionNotes(e.target.value)} />
                <button
                    className={`${matchesChanged && !loadingSaveAttributeTrainingExample ? 'bg-primary-700' : 'bg-gray-500'} p-2 rounded-lg mt-4`}
                    onClick={() => {
                        saveAttributeTrainingExample({
                            variables: {
                                json: JSON.stringify({
                                    asin: asin,
                                    categoryId: mlCategory?.getMlCategoryByAsin?.categoryId,
                                    filledAttributes: selectedProductAttributesValues?.getFilledAttributes?.filledAttributes,
                                    correctedAttributes: correctedAttributes,
                                    translatedDescription: translatedDescription?.getTranslatedDescriptionByAsin?.description,
                                    correctedDescription: correctedDescription,
                                    attributesMatchedByAI: newAttributeMatches,
                                    correctedAttributeMatches: newAttributeMatches,
                                    descriptionCorrectionNotes: descriptionCorrectionNotes,
                                    attributeCorrectionNotes: attributeCorrectionNotes,
                                    translatedBulletPoints: translatedDescription?.getTranslatedDescriptionByAsin?.bulletPoint,
                                    correctedBulletPoints: correctedBulletPoints,
                                    bulletPointsCorrectionNotes: bulletPointsCorrectionNotes,
                                }),
                            }
                        });
                        setMatchesChanged(false);
                    }}
                >Guardar</button>
            </div>
        </div>
    </div >;
};
