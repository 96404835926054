import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, hideMessage } from 'store';
import { AlertIcon, EditIcon, InfoIcon, QuestionIcon } from './Icons';

export enum IconType {
  'ALERT' = 'Alert',
  'EDIT' = 'Edit',
  'QUESTION' = 'Question',
  'INFO' = 'Info',
  'NONE' = '',
}

export enum MessageType {
  'YES_CANCEL' = 'Yes-Cancel',
  'YES_NO' = 'Yes-No',
  'OK' = 'ACCEPT',
  'NONE' = '',
}

const CloseIcon = () => (
  <svg
    aria-hidden='true'
    className='w-5 h-5'
    fill='currentColor'
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'></path>
  </svg>
);

export function PromptDialog() {
  const dispatch = useDispatch();
  const promptData = useSelector((state: RootState) => {
    return state.prompt;
  });

  let promptIcon;
  switch (promptData.iconType) {
    case IconType.ALERT:
      promptIcon = <AlertIcon />;
      break;
    case IconType.EDIT:
      promptIcon = <EditIcon />;
      break;
    case IconType.QUESTION:
      promptIcon = <QuestionIcon />;
      break;
    case IconType.NONE:
      promptIcon = <></>;
      break;
    default:
      promptIcon = <InfoIcon />;
  }

  const closePromptDialog = () => {
    dispatch(hideMessage());
  };

  const onClickOK = () => {
    promptData.handleConfirm && promptData.handleConfirm();
    closePromptDialog();
  };
  const onClickCancel = () => {
    promptData.handleCancel && promptData.handleCancel();
    closePromptDialog();
  };

  let messageType;
  switch (promptData.messageType) {
    case MessageType.YES_CANCEL:
    case MessageType.YES_NO:
      messageType = (
        <>
          <button
            data-modal-hide='popup-modal'
            type='button'
            className='text-white bg-primary-700 hover:bg-primary-500 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2'
            onClick={() => {
              onClickOK();
            }}
          >
            Sí
          </button>
          <button
            data-modal-hide='popup-modal'
            type='button'
            className='text-gray-500 hover:bg-white bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600'
            onClick={() => {
              onClickCancel();
            }}
          >
            {promptData.messageType === MessageType.YES_NO ? 'No' : 'Cancelar'}
          </button>
        </>
      );
      break;
    case MessageType.NONE:
      messageType = <></>;
      break;
    default:
      messageType = (
        <button
          data-modal-hide='popup-modal'
          type='button'
          className='text-white bg-primary-700 hover:bg-primary-500 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2'
          onClick={() => {
            onClickOK();
          }}
        >
          Aceptar
        </button>
      );
  }

  let FnContent;
  let textContent;
  if (typeof promptData.content === 'function') {
    FnContent = promptData.content;
  } else {
    textContent = promptData.content as String;
  }

  return (
    <div
      id='popup-modal'
      tabIndex={-1}
      className={`${
        promptData.showMsg ? '' : 'hidden'
      } fixed top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-50 flex justify-center items-center backdrop-blur-sm`}
    >
      <div className='relative w-full max-w-lg max-h-full'>
        <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
          <button
            type='button'
            className='absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white'
            data-modal-hide='popup-modal'
            onClick={() => {
              closePromptDialog();
            }}
          >
            <CloseIcon />
            <span className='sr-only'>Cerrar</span>
          </button>
          <div className='p-6 text-center'>
            {promptIcon}
            {FnContent ? (
              <FnContent />
            ) : (
              <h3 className='mb-5 text-lg font-normal text-gray-500 dark:text-gray-400'>
                {textContent}
              </h3>
            )}
            {messageType}
          </div>
        </div>
      </div>
    </div>
  );
}
