import { useQuery, useManualQuery } from 'graphql-hooks';

const GET_CATALOG_SUGGESTIONS_BY_UPC = `
query getCatalogSuggestionsByUpc($asin: String!) {
  getCatalogSuggestionsByUpc(asin: $asin) {
    id
    status
    domain_id
    settings {
      listing_strategy
    }
    name
    attributes {
      id
      name
      value_id
      value_name
    }
    pictures {
      id
      url
    }
    parent_id
  }
}
`;

export function useGetCatalogSuggestionsByUpc(asin: string) {
  const { loading, data } = useQuery(GET_CATALOG_SUGGESTIONS_BY_UPC, {
    variables: { asin }
  });

  return { loading, data };
}


export function useGetCatalogSuggestionsByUpcManual() {
  const [getCatalogSuggestionsByUpc, { loading, data }] = useManualQuery(GET_CATALOG_SUGGESTIONS_BY_UPC);

  return { getCatalogSuggestionsByUpc, loading, data };
}
