import React, { useState } from 'react';
import { useUserCreditsSubscription } from 'hooks/useUserCreditsSubscription';

const UsageBar = ({ usage, limit }: { usage: number, limit: number }) => {
	const usagePercentage = usage / limit * 100;
	return (
		<div>
			<div className="bg-gray-300 w-full rounded-md" style={{ width: '100%', height: '10px' }}>
				<div className="bg-primary-700 rounded-md" style={{ width: `${usagePercentage}%`, height: '100%' }}></div>
			</div>
		</div>
	);
}

export const UsageViewer = (props: {
	resetLimitDate: string,
	usage: number,
	limit: number,
	collapsed?: boolean,
}) => {
	const [usage, setUsage] = useState(0);
	const [limit, setLimit] = useState(0);
	const [resetLimitDate, setResetLimitDate] = useState('');
	const [synced, setSynced] = useState(false);

	useUserCreditsSubscription((data) => {
		setUsage(data.used);
		setLimit(data.total);
		setResetLimitDate(data.nextResetDate);
		setSynced(true);
	});

	return <div className="py-2 color-black bold text-gray-700 text-xs cursor-pointer mb-2">
		{synced && <>
			<div className="font-bold grid grid-cols-2 select-none mb-2" >
				<div>Uso</div>
				{!props.collapsed && <div className="text-end">{usage}/{limit}</div>}
			</div>
			<UsageBar usage={usage} limit={limit} />
			{!props.collapsed && <div className="mt-2">Próximo reinicio {resetLimitDate}</div>}
		</>}
	</div>
}
