import { useQuery, useManualQuery } from 'graphql-hooks';

const query = `
query GetCatalogProductByUPC($upc: String!) {
    getCatalogProductByUpc(upc: $upc) {
        id
        name
        status
        domain_id
        pictures {
            id
            url
            max_width
            max_height
        }
    }
}
`

export const useGetCatalogProductByUPC = (upc: string) => {
    const { loading, error, data } = useQuery(query, {
        variables: {
            upc
        }
    });
    return {
        loading,
        error,
        data
    }
}

export const useGetCatalogProductByUPCManual = () => {
    const [getCatalogProductByUPC, { loading, data, error }] = useManualQuery(query);
    return {
        loading,
        error,
        data,
        getCatalogProductByUPC
    }
}