import React, { MouseEvent, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { AddManualProductFormAttributes } from './AddManualProductFormAttributes';
import { AddManualProductFormControls } from './AddManualProductFormControls';
import { ImageSelector } from '../../ImageSelector2/ImageSelector';
import { AddProductBasicFormValues } from '../types/AddCatalogProduct.types';
import { useSearchAttrCategoryManual } from 'hooks/useSearchAttrCategory';
import LoadingIconComponent from 'components/utils/iconComponents/LoadingIconComponent';
import LinkArrow from '../../images/LinkArrow.svg';
import ExclamationIcon from 'components/utils/iconComponents/ExclamationIcon';

const formDefaultValues: AddProductBasicFormValues = {
  productCategory: '',
  productDescription: '',
  productName: '',
  currency: '',
  price: 0,
  stock: 12,
  productImages: [],
  attributes: '',
};

function AddManualProductForm({
  handleCancel,
  identifier,
  productName,
  titleAsinProduct,
  price = 0,
  currency,
  productDescriptionAsin,
  productImages,
  categoryPath: initialCategoryPath = [],
  attributeValues = [],
  formTitle,
  buttonName,
  onSubmit,
  loadingFetch,
  soldQuantity = 0,
  itemId = '',
  stock = 0,
  catalog_listing = false,
  permalink = '',
  catalog_product_id = '',
  onInitialImagesReady = () => null,
  errorMessage = '',
}: {
  handleCancel: (evt: MouseEvent<HTMLButtonElement>) => void;
  identifier?: string;
  productName: string;
  titleAsinProduct: string;
  price: number;
  currency: string;
  productDescriptionAsin: string;
  productImages: any;
  categoryPath?: string[];
  attributeValues?: { name: string; value: string | number }[];
  formTitle: string;
  buttonName: string;
  onSubmit: (payload: any) => void;
  loadingFetch: boolean;
  soldQuantity?: number;
  itemId?: string;
  stock?: number;
  catalog_listing?: boolean;
  permalink?: string;
  catalog_product_id?: string;
  onInitialImagesReady?: () => void;
  errorMessage?: string;
}) {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: { ...formDefaultValues, productName },
  });

  const { searchAttrCategory, loading: loadingAttrCategory } =
    useSearchAttrCategoryManual();

  const [attrSections, setAttrSections] = useState<any[]>(attributeValues); // TODO: Create group[] type
  const [productCategory, setProductCategory] = useState<string | undefined>();
  const [currencyOptions, setCurrencyOptions] = useState<string[]>([]);
  const [updatedImages, setUpdatedImages] = useState<any[]>([]);
  const [picturesIds, setPicturesIds] = useState<any[]>([]);
  const [categoryPath, setCategoryPath] =
    useState<string[]>(initialCategoryPath);

  // Add this line to declare preloadedImages state
  const [preloadedImages, setPreloadedImages] = useState<any[]>([]);

  const [generalTabsSelected, setGeneralTabsSelected] = useState(true);
  const [pimaryTabsSelected, setPrimaryTabsSelected] = useState(false);
  const [secundaryTabsSelected, setOnsecundaryAttribute] = useState(false);
  const [resComponentAttributes, setResComponentAttributes] = useState<any>([]);
  const [savedTabsAttributes, setSavedTabsAttributes] = useState<any>([
    attributeValues,
    [],
  ]);

  useEffect(() => {
    const arrPicturesIds = [];
    for (let idx = 0; idx < productImages.length; idx++) {
      if (productImages[idx].id !== null) {
        arrPicturesIds.push({ id: productImages[idx].id });
      }
    }

    arrPicturesIds.length > 0 && setPicturesIds(arrPicturesIds);

    const imageGroupsIds = productImages.reduce((acc: any, curr: any) => {
      // const id = curr?.link?.split('NP_')?.[1]?.split('-F.')?.[0];
      const imageTypeFormat = curr?.link.includes('-S.') ? '-S.' : '-F.';
      const id = curr?.link?.split('D_')?.[1]?.split(imageTypeFormat)?.[0];
      if (!acc.includes(id)) {
        acc.push(id);
      }
      return acc;
    }, []);
    const imagesByGroup = imageGroupsIds.map(
      (id: string) =>
        productImages.find((img: any) => img.link.includes(`_${id}-`))?.link
    );
    setPreloadedImages(imagesByGroup || []);
  }, []);

  useEffect(() => {
    if (getValues('productCategory') !== productCategory)
      setValue('productCategory', productCategory ?? '');
    clearErrors();
    //eslint-disable-next-line
  }, [productCategory]);

  useEffect(() => {
    const dataFetched = async (itemId: any) => {
      const { data } = await searchAttrCategory({
        variables: {
          itemId: itemId,
        },
      });
      // setResComponentAttributes(dataRes.data);
      console.info('data....', data);

      if (data) {
        setResComponentAttributes(data.searchAttrCategory);
      }
    };

    if (itemId) {
      dataFetched(itemId);
    }
  }, [itemId]);

  useEffect(() => {
    setValue('productName', titleAsinProduct?.slice(0, 60));
  }, [titleAsinProduct]);

  useEffect(() => {
    setValue('productDescription', productDescriptionAsin);
  }, [productDescriptionAsin]);

  useEffect(() => {
    const tabs1 = resComponentAttributes?.mainAttributes || [];
    const tabs2 = resComponentAttributes?.secundaryAttributes || [];
    setSavedTabsAttributes([tabs1, tabs2]);
  }, [resComponentAttributes]);

  const saveTabs1Attributes = () => {
    const currentTab1Values = resComponentAttributes?.mainAttributes.map(
      (attr: any) => {
        return {
          id: attr.id,
          name: attr.name,
          value:
            getValues(attr.id)?.value ||
            getValues(attr.id)?.label ||
            getValues(attr.id) ||
            '',
          values: attr.values,
        };
      }
    );

    setSavedTabsAttributes([currentTab1Values, [...savedTabsAttributes[1]]]);
  };

  const saveTabs2Attributes = () => {
    const currentTab2Values =
      resComponentAttributes?.secundaryAttributes?.length > 0
        ? resComponentAttributes?.secundaryAttributes.map((attr: any) => {
            return {
              id: attr.id,
              name: attr.name,
              value:
                getValues(attr.id)?.value ||
                getValues(attr.id)?.label ||
                getValues(attr.id) ||
                '',
              values: attr.values,
            };
          })
        : [];

    setSavedTabsAttributes([[...savedTabsAttributes[0]], currentTab2Values]);
  };

  const extractId = (url: string) => {
    const match = url.match(/D_(.+?)(?:-.[A-Z]\.jpg)$/);
    return match ? match[1] : null;
  };

  const onSubmitForm = async (formData: FieldValues) => {
    const {
      currency,
      price,
      productCategory,
      productDescription,
      productName,
      stock,
    } = formData;

    try {
      let payload = {};
      if (!catalog_listing) {
        const attributes = Object.keys(formData)
          .filter((key) => key === key.toUpperCase() && formData[key])
          .reduce((acc: any, key) => {
            acc.push({
              id: key,
              value_name: formData[key],
            });
            return acc;
          }, []);

        const allImages = [...updatedImages, ...preloadedImages];

        const pictures = allImages
          .map((image) => {
            if (image?.id) {
              return { source: image.link };
            } else {
              const id = extractId(image.link);
              if (id) {
                return { id: id };
              } else {
                return { source: image.link };
              }
            }
          })
          .filter(Boolean)
          .slice(0, 12);

        payload = {
          title: productName,
          currencyId: currency,
          price: parseFloat(price),
          categoryId: productCategory,
          description: productDescription,
          stock: parseInt(stock),
          attributes: attributes.map((attr: any) => ({
            id: attr.id,
            value_name: attr.value_name?.label
              ? attr.value_name.label
              : String(attr.value_name),
          })),
          pictures,
          sku: identifier,
        };

        // Validate images array
        if (!pictures.length) {
          throw new Error('Missing images for product with manual publish');
        }
      } else {
        payload = {
          currencyId: currency,
          price: parseFloat(price),
          stock: parseInt(stock),
        };
      }

      onSubmit(payload);
    } catch (err) {
      console.error('>> Error onSubmit: ', err);
    }
  };

  const handleImageListChanged = (list: any[]) => {
    // Set updated images directly from the list
    setUpdatedImages(list);
  };

  const onProductImagesChanged = (images: any) => {
    // Update preloaded images with the new images
    setPreloadedImages(images || []);
  };

  return (
    <div className='h-full'>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className='text-xl font-semibold mb-4 flex items-baseline'>
          <span className='pr-4'>{formTitle}</span>
          {itemId && (
            <span>
              <a
                href={permalink}
                target='_blank'
                rel='noreferrer'
                className='text-primary-700 flex items-center'
              >
                <div className='pr-4 flex items-center'>
                  <span className='text-base'>{itemId}</span>
                  <img src={LinkArrow} alt='Arrow' className='ml-1 w-2 h-2' />
                </div>
              </a>
            </span>
          )}
          {identifier && (
            <span>
              <a
                href={`https://amazon.com/dp/${identifier}`}
                target='_blank'
                rel='noreferrer'
                className='text-primary-700 flex items-center'
              >
                <div className='pr-4 flex items-center'>
                  <span className='text-base'>{identifier}</span>
                  <img src={LinkArrow} alt='Arrow' className='ml-1 w-2 h-2' />
                </div>
              </a>
            </span>
          )}
          {catalog_product_id && (
            <span>
              <a
                href={`https://mercadolibre.com.uy/p/${catalog_product_id}`}
                target='_blank'
                rel='noreferrer'
                className='text-primary-700 flex items-center'
              >
                <div className='pr-4 flex items-center'>
                  <span className='text-base'>{catalog_product_id}</span>
                  <img src={LinkArrow} alt='Arrow' className='ml-1 w-2 h-2' />
                </div>
              </a>
            </span>
          )}
        </div>
        <div>
          <div className='flex justify-between border-gray-100 border-b-2'>
            <div className='h-[45px] flex pt-2 text-primary-700'>
              <span
                className={`mr-4 cursor-pointer ${
                  generalTabsSelected && 'border-primary-700 border-b-2'
                }`}
                onClick={() => {
                  setGeneralTabsSelected(true);
                  setPrimaryTabsSelected(false);
                  setOnsecundaryAttribute(false);
                }}
              >
                General
              </span>
              <span
                className={`mr-4 cursor-pointer ${
                  pimaryTabsSelected && 'border-primary-700 border-b-2'
                }`}
                onClick={() => {
                  saveTabs2Attributes();
                  setPrimaryTabsSelected(true);
                  setOnsecundaryAttribute(false);
                  setGeneralTabsSelected(false);
                }}
              >
                Atributos principales
              </span>
              {pimaryTabsSelected && loadingAttrCategory && (
                <LoadingIconComponent />
              )}
              {itemId &&
                resComponentAttributes?.secundaryAttributes?.length > 0 && (
                  <span
                    className={`mx-4 cursor-pointer ${
                      secundaryTabsSelected && 'border-primary-700 border-b-2'
                    }`}
                    onClick={() => {
                      saveTabs1Attributes();
                      setPrimaryTabsSelected(false);
                      setOnsecundaryAttribute(true);
                      setGeneralTabsSelected(false);
                    }}
                  >
                    Atributos secundarios
                  </span>
                )}
              {secundaryTabsSelected && loadingAttrCategory && (
                <LoadingIconComponent />
              )}
            </div>
            <div className='flex items-center'>
              <span
                className={`flex w-[25px] text-base text-orange-400 ${
                  errorMessage ? 'visible' : 'hidden'
                }`}
              >
                <ExclamationIcon title={errorMessage} />
              </span>
            </div>
          </div>
          <div
            className={`pt-[0.8rem] relative ${
              !generalTabsSelected ? 'overflow-y-auto' : ''
            }`}
            style={{ height: 'calc(100vh - 223px)' }}
          >
            <div
              className={`${
                generalTabsSelected ? 'visible' : 'invisible'
              } absolute t-0 l-0`}
            >
              <div className='sm:flex justify-between gap-5'>
                <ImageSelector
                  onImageListChanged={handleImageListChanged as any}
                  productImages={productImages as any}
                  onProductImagesChanged={onProductImagesChanged}
                  onReady={onInitialImagesReady}
                />
                <AddManualProductFormControls
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  productName={productName}
                  productCategory={productCategory}
                  setProductCategory={setProductCategory}
                  setCategoryPath={setCategoryPath}
                  errors={errors}
                  price={price}
                  currency={currency}
                  currencyOptions={currencyOptions}
                  setCurrencyOptions={setCurrencyOptions}
                  categoryPath={categoryPath ?? []}
                  attributeValues={attributeValues}
                  soldQuantity={soldQuantity}
                  stock={stock}
                />
              </div>
            </div>
            <div>
              <div
                className={`${
                  pimaryTabsSelected ? 'visible overflow-y-auto' : 'invisible'
                } absolute t-0 l-0`}
              >
                <AddManualProductFormAttributes
                  categoryId={productCategory}
                  control={control}
                  attrSections={itemId ? savedTabsAttributes[0] : attrSections}
                  setAttrSections={setAttrSections}
                  attributeValues={
                    itemId ? savedTabsAttributes[0] : attributeValues
                  }
                  attrComponents={
                    resComponentAttributes?.mainComponentsGroup || []
                  }
                  getValues={getValues}
                  asin={identifier}
                  itemId={itemId}
                />
              </div>
              <div
                className={`${
                  itemId &&
                  secundaryTabsSelected &&
                  resComponentAttributes?.secundaryAttributes?.length > 0
                    ? 'visible overflow-y-auto'
                    : 'invisible'
                } absolute t-0 l-0`}
              >
                <AddManualProductFormAttributes
                  categoryId={productCategory}
                  control={control}
                  attrSections={savedTabsAttributes[1] || []}
                  setAttrSections={setAttrSections}
                  attributeValues={savedTabsAttributes[1] || []}
                  attrComponents={
                    resComponentAttributes?.secundaryComponentsGroup || []
                  }
                  getValues={getValues}
                  asin={identifier}
                  itemId={itemId}
                />
              </div>
            </div>
          </div>
          <div className='pt-2 flex items-center justify-between'>
            <div className='w-full pr-2'>
              <button
                type='button'
                className={`text-black bg-white border border-gray-200 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md  transition ease-in-out duration-150' w-full h-[44px] justify-center`}
                onClick={handleCancel}
              >
                Cancelar
              </button>
            </div>
            <div className='w-full pl-2'>
              <button
                className={`text-white bg-primary-700 border border-primary-700 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md  transition ease-in-out duration-150' w-full h-[44px] justify-center ${
                  !productCategory ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}
                // type='submit'
                type='button'
                disabled={!productCategory}
                onClick={async () => {
                  if (catalog_listing) {
                    const isValid = await trigger([
                      'price',
                      'currency',
                      'stock',
                    ]);
                    if (isValid) {
                      await onSubmitForm({
                        price: getValues('price'),
                        currency: getValues('currency'),
                        stock: getValues('stock'),
                      });
                    }
                  } else {
                    handleSubmit(onSubmitForm)();
                  }
                }}
              >
                {loadingFetch ? <LoadingIconComponent /> : buttonName}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddManualProductForm;
