const { useQuery, useManualQuery } = require('graphql-hooks');

export const query = `
query getPredictedCategory($name: String!) {
    getPredictedCategory(name: $name) {
        id
        name
    }
}
`;

export const useGetPredictedCategory = (name: string) => {
    const { loading, error, data } = useQuery(query, {
        variables: { name },
    });

    return {
        loading,
        error,
        data,
    };
};

export const useGetPredictedCategoryManual = () => {
    const [getPredictedCategory, { loading, error, data }] = useManualQuery(query);

    return {
        getPredictedCategory,
        loading,
        error,
        data,
    };
}