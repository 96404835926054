import { FormFieldsComponents, ML_FORM_TYPES } from '../types/MLForm.types';
import { AoComboSelect } from './AoComboSelect';
import { AoLinkedConnectorInput } from './AoLinkedConnectorInput';
import { AoNumberInput, AoTextInput } from './AoTextInput';

/**
 * To render a new type of component:
 * 1) a new ML_FORM_TYPE needs to be defined (MLForm.types.ts)
 * 2) a new FORM_FIELD_LIST entry/map is required
 * List of FormFields that maps 'ML components' to 'AO components'
 * (ML_FORM_TYPES definition on MLForm.types.ts)
 */
const FORM_FIELD_LIST: FormFieldsComponents = {
  [ML_FORM_TYPES.TEXT_INPUT]: AoTextInput,
  [ML_FORM_TYPES.COMBO]: AoComboSelect,
  [ML_FORM_TYPES.BOOLEAN_INPUT]: AoComboSelect,
  [ML_FORM_TYPES.NUMBER_INPUT]: AoNumberInput,
  [ML_FORM_TYPES.NUMBER_UNIT_INPUT]: AoNumberInput,
  [ML_FORM_TYPES.COLOR_INPUT]: AoComboSelect,
  [ML_FORM_TYPES.LINKED_BY_CONNECTOR_INPUT]: AoLinkedConnectorInput,
  [ML_FORM_TYPES.BOOLEAN_LIST]: AoComboSelect,
};

export { FORM_FIELD_LIST };
