import { useQuery, useManualQuery } from 'graphql-hooks';

export const ADD_FIXED_MATCH_MUTATION = `
mutation addFixedAttributeMatch($mlAttributeId: String!, $amzAttributeId: String!) {
  addFixedAttributeMatch(mlAttributeId: $mlAttributeId, amzAttributeId: $amzAttributeId)
}
`;

export function useAddFixedMatchMutation() {
  const { loading, data } = useQuery(ADD_FIXED_MATCH_MUTATION);

  return { loading, data };
}

export function useAddFixedMatchManual() {
  const [addFixedMatch, { loading, data }] = useManualQuery(ADD_FIXED_MATCH_MUTATION);

  return { addFixedMatch, loading, data };
}
