import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setSession } from 'store/slices/authSlice';

const usersApi = createApi({
  reducerPath: 'users',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders(headers: Headers, { getState }: any) {
      const { token } = getState().auth || {};
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['BasicUserInfo'],
  endpoints(builder) {
    return {
      fetchUserInfo: builder.query({
        providesTags: ['BasicUserInfo'],
        query: (queryTokenInfo: any) => {
          let headersData = {};
          if (queryTokenInfo && queryTokenInfo.accessTokenExist) {
            headersData = {
              headers: {
                authorization: `Bearer ${queryTokenInfo.access_token}`,
              },
            };
          }
          return {
            url: '/profile',
            method: 'GET',
            ...headersData,
            queryTokenInfo,
          };
        },
        onCacheEntryAdded(arg, { dispatch }) {
          if (arg && arg.access_token) {
            dispatch(
              setSession({
                token: arg.access_token,
                email: arg.email,
              })
            );
          }
        },
      }),
      updateBasicUserInfo: builder.mutation({
        invalidatesTags: ['BasicUserInfo'],
        query: ({ data, userId }: any) => {
          return {
            url: `users/${userId}/userinfo`,
            method: 'PATCH',
            body: data,
          };
        },
      }),
      createUserForStore: builder.mutation({
        invalidatesTags: ['BasicUserInfo'],
        query: ({ data }: any) => {
          return {
            url: `users/createuserforstore`,
            method: 'POST',
            body: data,
          };
        },
      }),
      removeUserFromStore: builder.mutation({
        invalidatesTags: ['BasicUserInfo'],
        query: ({ data }: any) => {
          return {
            url: `users/removeuserfromstore`,
            method: 'DELETE',
            body: data,
          };
        },
      }),
      updateUserPermissionsForStore: builder.mutation({
        invalidatesTags: ['BasicUserInfo'],
        query: ({ data }: any) => {
          return {
            url: `users/updateuserpermissionsforstore`,
            method: 'PATCH',
            body: data,
          };
        },
      }),
      createMLRedirectUrl: builder.mutation({
        invalidatesTags: ['BasicUserInfo'],
        query: ({data}) => {
          return {
            url: 'users/createmlredirecturl',
            method: 'POST',
            body: data,
          };
        },
      }),
    };
  },
});

export const {
  useFetchUserInfoQuery,
  useUpdateBasicUserInfoMutation,
  useCreateUserForStoreMutation,
  useRemoveUserFromStoreMutation,
  useUpdateUserPermissionsForStoreMutation,
  useCreateMLRedirectUrlMutation
} = usersApi;
export { usersApi };
