import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LabelModal from './LabelModal';

import { useSearchLabelsManual } from 'hooks/useSearchLabels';
import { useEditLabelManual } from 'hooks/useEditLabels';
import { useAddLabelManual } from 'hooks/useAddLabel';
import { useDeleteLabelManual } from 'hooks/useDeleteLabels';
import AlertMessage from 'components/utils/AlertMessage';
import { DANGERMSGALERT } from 'components/utils/constants';
import searchIcon from '../images/Search.svg';
import EditIcon from 'components/utils/iconComponents/EditIcon';
import TrashIcon from 'components/utils/iconComponents/TrashIcon';
import LabelIcon from 'components/utils/iconComponents/LabelIcon';

function Main() {
  const [dataLabel, setDataLabel] = useState([]);
  const [editData, setEditData] = useState({});
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [labelError, setLabelError] = useState();
  const [newData, setNewData] = useState([]);

  const navigate = useNavigate();

  const {
    searchLabels,
    loading: loadingSearchLabels,
    error: errorSearchLabels,
  } = useSearchLabelsManual();
  const {
    addLabel,
    loading: loadingAddLabel,
    error: errorAddLabel,
  } = useAddLabelManual();
  const {
    editLabel,
    loading: loadingEditLabel,
    error: errorEditLabel,
  } = useEditLabelManual();

  const {
    deleteLabel,
    loading: loadingDeleteLabel,
    error: errorDeleteLabel,
  } = useDeleteLabelManual();

  useEffect(() => {
    const fetchDataLabels = async () => {
      const { data } = await searchLabels({
        variables: {
          query: '',
          limit: 20,
          offset: 0,
        },
      });

      if (errorSearchLabels) {
        let error = 'Error';
        if (errorSearchLabels.httpError) {
          error = `${errorSearchLabels.httpError.statusText} - para obtener las etiquetas`;
        }

        setLabelError(error);
      }

      if (data) {
        setDataLabel(data.searchLabels.labels);
      }
    };
    fetchDataLabels();
  }, []);

  useEffect(() => {
    setNewData(dataLabel);
  }, [dataLabel]);

  const handlerDataLabel = (info) => {
    setDataLabel([...dataLabel, info]);
    setShowLabelModal(false);
  };

  const handlerEdit = (id) => {
    setShowLabelModal(true);
    const newData = dataLabel.filter((obj) => obj.id === id);
    newData.map((d) => {
      setEditData({
        id: d.id,
        title: d.title,
        description: d.description,
        module: d.module,
      });
    });
  };

  const handlerSaveEdit = (info) => {
    const updatedDataIndex = dataLabel.findIndex((d) => d.id === info.id);
    dataLabel[updatedDataIndex] = info;
    setDataLabel([...dataLabel]);
    setShowLabelModal(false);
  };

  const handlerDelete = async (id) => {
    const confirm = window.confirm('Estas seguro de eliminar el registro?');

    if (confirm) {
      const { data } = await deleteLabel({
        variables: {
          id,
        },
      });

      if (errorDeleteLabel) {
        let error = 'Error';
        if (errorDeleteLabel.httpError) {
          error = `${errorDeleteLabel.httpError.statusText} - para eliminar la etiqueta`;
        }

        setLabelError(error);
      }

      if (data) {
        const resultId = dataLabel.findIndex((obj) => obj.id === id);

        if (resultId > -1) {
          dataLabel.splice(resultId, 1);
          setDataLabel([...dataLabel]);
        }
      }
    }
  };

  const handlerBackButton = () => {
    // navigate('/mensajes');
    navigate(-1);
  }

  const handlerAddLabel = () => {
    setEditData({});
    setShowLabelModal(true);
  };

  const handlerSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setNewData(
      dataLabel.filter(
        (d) =>
          d.title.toLowerCase().includes(query) ||
          d.description.toLowerCase().includes(query)
      )
    );
  };

  return (
    <div>
      <div className='w-full h-[60px] flex justify-between'>
        <div className='w-[3%] h-[60px] flex justify-start p-2 items-center'>
          <LabelIcon />
        </div>
        <div className='w-[50%] h-[60px] flex justify-start'>
          <div className='self-stretch pl-0 pt-2.5 pb-2.5 pr-2.5 justify-start items-center gap-2 flex'>
            <div className='grow shrink basis-0 self-stretch justify-start items-center gap-2 flex'>
              <div className='px-3 py-2 rounded-md justify-center items-center gap-2 flex'>
                <div className="text-gray-500 text-sm font-semibold font-['Inter'] leading-tight">
                  Etiquetas
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-[47%] h-[60px] flex justify-end items-center'>
          <div
            className={`text-black bg-white border border-gray-200 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md  transition ease-in-out duration-150' w-[210px] h-[44px] justify-center mr-2.5`}
            onClick={handlerBackButton}
          >
            <div>Cancelar</div>
          </div>
          <div
            className={`text-white bg-primary-700 border border-primary-700 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md  transition ease-in-out duration-150' w-[210px] h-[44px] justify-center mr-2.5`}
            onClick={handlerAddLabel}
          >
            <div>Agregar</div>
          </div>
          <div className='shrink basis-0 self-stretch flex-col justify-center items-end gap-3 flex inline-flex'>
            <div className='w-80 h-11 flex-col justify-start items-start gap-1.5 flex'>
              <div className='self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex'>
                <div className='grow shrink basis-0 h-6 justify-start items-center gap-2 flex'>
                  <div className="text-gray-500 text-base font-normal font-['Inter'] leading-normal">
                    <div className='flex flex-inline'>
                      <img src={searchIcon} alt='Search' />{' '}
                      <input
                        className={`outline-0 ml-2 border-0 focus:outline-none focus:ring-0`}
                        type='text'
                        placeholder='Buscar...'
                        onChange={handlerSearch}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full self-stretch h-11 px-6 py-3 bg-gray-100 shadow border border-b-0 border-gray-200 justify-start items-center gap-3 inline-flex rounded-t-xl'>
        <div className='w-full justify-start items-center gap-1 flex'>
          <div className="w-[15%] text-slate-600 text-xs font-medium font-['Inter'] leading-[18px] text-gray-600">
            Titulo
          </div>
          <div className="w-[65%] text-slate-600 text-xs font-medium font-['Inter'] leading-[18px] text-gray-600">
            Valor
          </div>
          <div className="w-[10%] flex justify-center text-slate-600 text-xs font-medium font-['Inter'] leading-[18px] text-gray-600">
            Modulo
          </div>
          <div className="w-[10%] flex justify-center text-slate-600 text-xs font-medium font-['Inter'] leading-[18px] text-gray-600">
            Accion
          </div>
        </div>
      </div>
      <div
        className='w-full flex-col justify-start items-start gap-6 inline-flex'
        style={{ height: 'calc(100vh - 130px)' }}
      >
        <div className='self-stretch h-[833px] bg-white flex-col justify-start items-start flex rounded-t-0 rounded-b-xl'>
          <div className='w-full h-full bg-white rounded-t-0 rounded-b-xl shadow border border-gray-200 flex-col justify-start items-start flex overflow-y-auto'>
            <div className='self-stretch bg-white justify-start items-start inline-flex'>
              <div
                className='w-full flex-col justify-start items-start inline-flex'
                style={{ height: 'calc(100vh - 130px)' }}
              >
                {newData.length > 0 &&
                  newData.map((info) => (
                    <div
                      key={info.id}
                      className='w-full px-4 py-2 border-b hover:bg-gray-50 border-gray-200 text-justify items-center gap-3 inline-flex'
                    >
                      <div className='w-[15%]'>
                        <div className="text-gray-600 text-sm font-normal font-['Inter'] leading-tight">
                          {info.title}
                        </div>
                      </div>
                      <div className='w-[65%]'>
                        <div className="text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                          {info.description}
                        </div>
                      </div>
                      <div className='w-[10%] flex justify-center'>
                        <div className="text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
                          {info.module}
                        </div>
                      </div>
                      <div className='w-[10%] flex justify-center'>
                        <div className='pl-4 pr-1 py-0.5 rounded-2xl justify-left items-center gap-1 flex'>
                          <div onClick={() => handlerEdit(info.id)}>
                            <EditIcon title='Editar etiqueta' />
                          </div>
                          <div onClick={() => handlerDelete(info.id)}>
                            <TrashIcon title='Eliminar etiqueta' />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {(errorSearchLabels || errorDeleteLabel) && (
        <AlertMessage
          message={labelError}
          title='Error: '
          type={DANGERMSGALERT}
        />
      )}
      {(loadingSearchLabels || loadingDeleteLabel) && <span>cargando...</span>}
      {showLabelModal && (
        <LabelModal
          handlerDataLabel={handlerDataLabel}
          setShowLabelModal={setShowLabelModal}
          editData={editData}
          handlerSaveEdit={handlerSaveEdit}
          addLabel={addLabel}
          loadingAddLabel={loadingAddLabel}
          errorAddLabel={errorAddLabel}
          loadingEditLabel={loadingEditLabel}
          errorEditLabel={errorEditLabel}
          editLabel={editLabel}
        />
      )}
    </div>
  );
}

export default Main;
