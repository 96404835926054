import { useQuery, useManualQuery } from 'graphql-hooks';

const GetAnswerSuggestionsPostSale = `
  query GetAnswerSuggestionsPostSale($title: String, $message: String) {
    getAnswerSuggestionPostSale(title: $title, message: $message)
  }
`;

export const useGetAnswerSuggestionsPostSale = (title, message) => {
  const { loading, error, data } = useQuery(GetAnswerSuggestionsPostSale, {
    variables: {
      title,
      message
    },
  });
  return { loading, error, data };
};

export const useGetAnswerSuggestionsPostSaleManual = () => {
  const [getAnswerSuggestionPostSale, { loading, error, data }] = useManualQuery(
    GetAnswerSuggestionsPostSale
  );
  return { getAnswerSuggestionPostSale, loading, error, data };
};

export { GetAnswerSuggestionsPostSale };
