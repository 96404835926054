import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IconType, MessageType } from 'components/utils/PromptDialog';
import { ElementType } from 'react';

interface PromptState {
  showMsg: boolean;
  messageType: MessageType;
  iconType: IconType;
  content: string | ElementType;
  handleConfirm: Function;
  handleCancel: Function;
}

interface PromptData {
  showMsg?: boolean;
  messageType: MessageType;
  iconType?: IconType;
  content: string | ElementType;
  handleConfirm?: () => void;
  handleCancel?: () => void;
}

const initialState: PromptState = {
  showMsg: false,
  messageType: MessageType.NONE,
  iconType: IconType.NONE,
  content: '',
  handleConfirm: () => {},
  handleCancel: () => {},
};

const promptSlice = createSlice({
  name: 'prompt',
  initialState,
  reducers: {
    showMessage(state, action: PayloadAction<PromptData>) {
      state.content = action.payload.content;
      state.iconType = action.payload.iconType || IconType.INFO;
      state.messageType = action.payload.messageType;
      state.handleCancel = action.payload.handleCancel || (() => {});
      state.handleConfirm = action.payload.handleConfirm || (() => {});
      state.showMsg = true;
    },
    hideMessage(state) {
      state.content = initialState.content;
      state.iconType = initialState.iconType;
      state.messageType = initialState.messageType;
      state.showMsg = initialState.showMsg;
      state.handleCancel = initialState.handleCancel;
      state.handleConfirm = initialState.handleConfirm;
    },
  },
});

export const { showMessage, hideMessage } = promptSlice.actions;

export { promptSlice };
