import { useQuery, useManualQuery } from 'graphql-hooks';

const DeleteLabelMutation = `
mutation DeleteLabelMutation($id: String) {
  deleteLabel(id: $id) {
    id
    error
  }
}
`;

const useDeleteLabel = () => {
  const { loading, error, data: labelResponse } = useQuery(DeleteLabelMutation);
  return { loading, error, labelResponse };
};

const useDeleteLabelManual = () => {
  const [deleteLabel, { loading, error, data: labelResponse }] =
    useManualQuery(DeleteLabelMutation);
  return { deleteLabel, loading, error, labelResponse };
};

export { DeleteLabelMutation, useDeleteLabel, useDeleteLabelManual };
