import { useState } from 'react';

export const DelayedSwitch = ({ value, onChange, checked, id, name }) => {

  return (
      <div className='flex items-center justify-center'>
        <label className='relative flex items-center text-xl'>
          <input
            type='checkbox'
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            className='absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md cursor-pointer'
            checked={checked} />
          <span className={`w-10 h-5 flex items-center flex-shrink-0 p-1 bg-gray-300 rounded-full cursor-pointer after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 ${checked && 'peer-checked:bg-primary-700 peer-checked:after:translate-x-4'}`}></span>
        </label>
      </div>
  );
}

export const Switch = ({ value, onChange, defaultChecked, id, name }) => {
  const [checked, setChecked] = useState(value);

  return (
      <div className='flex items-center justify-center'>
        <label className='relative flex items-center text-xl'>
          <input
            type='checkbox'
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            className='absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md cursor-pointer'
            defaultChecked={defaultChecked} />
          <span className='w-10 h-5 flex items-center flex-shrink-0 p-1 bg-gray-300 rounded-full cursor-pointer duration-300 ease-in-out peer-checked:bg-primary-700 after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-4'></span>
        </label>
      </div>
  );
};
