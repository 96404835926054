import { useManualQuery } from 'graphql-hooks';

const Query = `
query GetShippingPreferences($categoryId: String!) {
  getShippingPreferences(categoryId: $categoryId) {
    logistics {
      mode
      types
    }
  }
}`;

export const useGetShippingPreferencesManual = () => {
  const [getShippingPreferences, { loading, error, data }] = useManualQuery(Query);
  return { getShippingPreferences, loading, error, data };
}
