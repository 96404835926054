const { useSubscription } = require('graphql-hooks');

export const query = `
subscription manualProductFormCopilotSubscription($productIdentifier: String!) {
    manualProductFormCopilotSuggestion(productIdentifier: $productIdentifier) {
        progress
        totalWork
        status
        suggestion {
            title
            description
            price
            currency
            images {
              variant
              link
              width
              height
            }
            categoryPath
            attributes {
                name
                value
            }
        }
    }
}
`;

export enum ManualProductFormCopilotStatus {
    IN_PROGRESS = 'in progress',
    DONE = 'done',
}

export const useManualProductCopilotSubscription = (productIdentifier: string, onData: (data: any) => void) => {
    useSubscription({
        query,
        variables: { productIdentifier }
    }, (response: any) => {
        console.log('useManualProductCopilotSubscription', response);
        const data = response.data;
        if(data) onData(data.manualProductFormCopilotSuggestion);
    });
};