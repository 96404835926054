import React from 'react';

const UnreadMessageFilterIconComponent = ({
  unreadMessageFilterChecked,
  handlerClickUnreadMessageFilter,
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill={unreadMessageFilterChecked ? '#6941C6' : 'none'}
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      className={`w-6 h-6 cursor-pointer ${
        unreadMessageFilterChecked && 'text-white'} `}
      onClick={handlerClickUnreadMessageFilter}
    >
      <title>
        {unreadMessageFilterChecked
          ? 'Remover filtro de ordenes con mensajes no leidos'
          : 'Mostrar ordenes con mensajes no leidos'}
      </title>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
      />
    </svg>
  );
};

export default UnreadMessageFilterIconComponent;
