import { useState } from 'react';
import { DelayedSwitch } from 'components/utils/Switch';

const NewModalAlwaysWin = () => {
  const [isActive, setIsActive] = useState(false);

  const handlerActiveSwitch = () => {
    setIsActive((prevState) => !prevState);
  };

  return (
    <div className='flex justify-between border border-gray-300 py-[10px] px-[18px] rounded-lg mt-8 items-center'>
      <span className='text-base font-semibold text-gray-700'>
        Permitir a Atibo realizar cambios para Ganar siempre
      </span>
      <span className='flex py-0'>
        {' '}
        <DelayedSwitch
          id='alwaysWin'
          name='alwaysWin'
          value={true}
          onChange={handlerActiveSwitch}
          checked={isActive}
        />
      </span>
    </div>
  );
};

export default NewModalAlwaysWin;
