const { useQuery, useManualQuery } = require('graphql-hooks');

const query = `
query getProductByAsin($asin: String!) {
    getProductByAsin(asin: $asin) {
        id
        name
        domainId
        gtin
        price
    }
}
`;

export const useGetProductByAsin = (asin: string) => {
    const { loading, error, data } = useQuery(query, {
        variables: { asin },
    });

    return {
        loading,
        error,
        data,
    };
};

export const useGetProductByAsinManual = () => {
    const [getProductByAsin, { loading, error, data }] = useManualQuery(query);

    return {
        getProductByAsin,
        loading,
        error,
        data,
    };
}