import { useManualQuery } from 'graphql-hooks';

const SearchProductInfoByIdentifierQuery = `
query SearchProductInfo($productIdentifier: String!) {
  searchProductByIdentifier(productIdentifier: $productIdentifier) {
      inCatalog
    }
  }
`;

const useSearchProductInfoByIdentifier = () => {
  const [loadProductInfoByIdentifierQuery, { loading, error, data }] = useManualQuery(SearchProductInfoByIdentifierQuery);
  const inCatalog = data?.searchProductByIdentifier.inCatalog || false;

  return { loadProductInfoByIdentifierQuery, inCatalog, loading };
};

export { useSearchProductInfoByIdentifier, SearchProductInfoByIdentifierQuery };
