import React from 'react';

interface CustomLabelProps {
  id: string;
  label: string;
  isRequired?: boolean;
}

interface CustomInputProps {
  id: string;
  inputProps: any;
  isTextArea?: boolean;
}

function CustomLabel({ id, label, isRequired = true }: CustomLabelProps) {
  return (
    <label
      className='block text-gray-700 text-sm font-bold mb-2'
      htmlFor={id}
    >
      {label}
      {isRequired && <span className='text-red-700'>*</span>}
    </label>
  );
}

function CustomInput({ id, inputProps, isTextArea = false }: CustomInputProps) {
  const className = `shadow appearance-none border rounded w-full py-2 px-3 
  text-gray-700 leading-tight focus:outline-none focus:shadow-outline`;
  if (isTextArea) {
    return <textarea className={className} id={id} {...inputProps}></textarea>;
  }
  return (
    <input className={className} id={id} {...inputProps} autoComplete='off' />
  );
}

function CustomField({
  id,
  label,
  inputProps,
  isTextArea = false,
  isRequired = true,
}: CustomInputProps & CustomLabelProps) {
  return (
    <>
      <CustomLabel id={id} label={label} isRequired={isRequired} />
      <CustomInput id={id} inputProps={inputProps} isTextArea={isTextArea} />
    </>
  );
}

export { CustomLabel, CustomInput, CustomField };
