import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useAttachFileManual } from 'hooks/useAttachFile';
import { useSendMessageBuyerManual } from 'hooks/useSendMessageBuyer';
import { SendMessageIcon } from 'components/utils/iconComponents';

const ModalUploadedFile = ({
  openModal,
  imageURLs,
  setImageURLs,
  setOpenModal,
  textMessage,
  setTextMessage,
  base64File,
  orderInfo,
  messagesBuyer,
  setMessagesBuyer,
  fileInfo,
}) => {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const fileSize = fileInfo.size / 1024;
  const fileSizeFinal =
    fileSize > 1000 ? fileSize.toFixed(0) + 'MB' : fileSize.toFixed(0) + 'KB';

  const { attachFile } = useAttachFileManual();
  const { sendMessageToBuyer, loading: loadingSentMessage } =
    useSendMessageBuyerManual();

  useEffect(() => {
    openModal && setOpen(true);
  }, [openModal]);

  const handlerClickEnviar = async () => {
    setOpen(false);
    setOpenModal(false);
    setTextMessage('');
    setImageURLs([]);
    const response = await attachFile({
      variables: {
        file: base64File,
        fileName: fileInfo.name,
      },
    });

    // console.info('reponse image...', response);
    if (response?.data?.attachFile?.id != '') {
      const pack_id_message = orderInfo.pack_id
        ? orderInfo.pack_id
        : orderInfo.order_id;
      const dataSent = await sendMessageToBuyer({
        variables: {
          orderId: pack_id_message,
          buyerId: orderInfo.buyer.id.toString(),
          dataInput: textMessage,
          attachments: response.data.attachFile.id,
        },
      });

      if (dataSent) {
        setMessagesBuyer([
          ...messagesBuyer,
          dataSent.data.sendMessageToBuyer.messages,
        ]);
      }
    }
  };

  const handlerClickCloseModal = () => {
    setOpen(false);
    setOpenModal(false);
    setTextMessage('');
    setImageURLs([]);
  };

  const handlerChangeMessage = (e) => {
    setTextMessage(e.target.value);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={cancelButtonRef}
        onClose={() => null}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg'>
                <div className='bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
                  <div className='sm:flex sm:items-start'>
                    <div className='w-full mt-3 text-center sm:ml-0 sm:mt-0 sm:text-left'>
                      <Dialog.Title
                        as='h3'
                        className='text-base font-semibold leading-6 text-gray-900'
                      >
                        <span
                          className='mt-3 inline-flex w-full justify-center rounded-full py-2 text-sm font-semibold text-gray-900 sm:mt-0 sm:w-auto cursor-pointer'
                          onClick={handlerClickCloseModal}
                          title='Cerrar'
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth='1.5'
                            stroke='currentColor'
                            className='w-6 h-6'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M6 18 18 6M6 6l12 12'
                            />
                          </svg>
                        </span>
                      </Dialog.Title>
                      <div
                        className={`${
                          fileInfo.type == 'image/png' ||
                          fileInfo.type == 'image/jpeg' ||
                          (fileInfo.type == 'image/jpeg' &&
                            'flex justify-center')
                        }mt-5`}
                      >
                        {imageURLs &&
                          imageURLs.map((imageSrc) =>
                            fileInfo.type == 'image/png' ||
                            fileInfo.type == 'image/jpeg' ||
                            fileInfo.type == 'image/jpeg' ? (
                              <img key={imageSrc} src={imageSrc} />
                            ) : (
                              <div>
                                <div
                                  className={`w-full justify-between p-0 rounded-lg`}
                                >
                                  <div>
                                    <div className='h-16 p-1.5 flex border-2 border-gray-50 shadow-md rounded-md mt-2'>
                                      <div className='w-[15%]'>
                                        <span className='h-full bg-primary-100 rounded-full shadow-md shadow-primary-200 flex justify-center p-3'>
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            fill='none'
                                            viewBox='0 0 24 24'
                                            strokeWidth='1.5'
                                            stroke='currentColor'
                                            className='w-6 h-6 text-primary-700'
                                          >
                                            <path
                                              strokeLinecap='round'
                                              strokeLinejoin='round'
                                              d='M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m.75 12 3 3m0 0 3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z'
                                            />
                                          </svg>
                                        </span>
                                      </div>
                                      <div className='w-[85%] grid ml-4'>
                                        <span className='text-gray-500 text-lg truncate'>
                                          {fileInfo.name}
                                        </span>
                                        <div className='flex justify-between'>
                                          <span className='text-gray-500 text-sm'>
                                            {fileSizeFinal}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                  <button
                    type='button'
                    className={`inline-flex w-full justify-center rounded-lg px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto ${
                      textMessage
                        ? 'bg-primary-700 cursor-pointer'
                        : 'bg-primary-200 cursor-not-allowed'
                    }`}
                    onClick={() => {
                      textMessage && handlerClickEnviar();
                    }}
                  >
                    <span className='flex items-center'>
                      <SendMessageIcon />
                    </span>
                  </button>
                  <div className='self-stretch bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex w-full'>
                    <input
                      type='text'
                      placeholder='Escriba el mensaje'
                      className='p-2 w-full text-black rounded-lg outline-none ml-2'
                      value={textMessage}
                      onChange={handlerChangeMessage}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalUploadedFile;
