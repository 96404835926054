import { useState, useEffect } from 'react';

export const useBottomReached = () => {
    const [isBottomReached, setIsBottomReached] = useState(false);
    const handleScroll = () => {
        const isBottomReached = window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight;
        setIsBottomReached(isBottomReached);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, []);

    return isBottomReached;
}