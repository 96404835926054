import userImagePlaceholderSVG from './images/UserImagePlaceholder.svg';
import logoutButton from './images/LogoutButton.svg';
import { useDispatch } from 'react-redux';
import { clearSession } from 'store';
import { UsageViewer } from './UsageViewer/UsageViewer';

const BottomMenu = ({ userInfo, open }) => {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(clearSession());
    setTimeout(() => {
      window.location = '/';
    }, 0);
  };

  return (
    <div>
      <UsageViewer usage={10} limit={10000} collapsed={!open} resetLimitDate="11 ago" />
      <div className='w-full flex cursor-pointer' onClick={logout}>
        <div className='w-[20%]'>
          {open && (
            <img
              className={`mr-3  ${!open && 'scale-0'}`}
              src={userImagePlaceholderSVG}
              alt='User'
            />
          )}
        </div>
        <div className='w-[80%]'>
          <div className='truncate text-gray-700'>
            {open && (
              <span
                className={`leading-6 text-gray-700 text-base mt-2 mr-3  ${!open && 'scale-0'
                  }`}
              >
                {userInfo?.username}
              </span>
            )}
          </div>
          <div className={`flex  ${!open && 'justify-center'}`}>
            <img className={`object-right-bottom ${open && 'w-3.5'}`} src={logoutButton} alt='Logout' />{open && <span className={`ml-2 text-gray-700 ${open && 'text-xs'}`}>Salir</span>}
          </div>
        </div>
        {/* <div className='fixed bottom-8 left-6 font-sans font-semibold'>
      <div
        className='flex flex-row cursor-pointer align-middle'
        onClick={logout}
      >
        {open && <img className={`mr-3  ${!open && 'scale-0'}`} src={userImagePlaceholderSVG} alt='User' />}
         {open && <span className={`leading-6 text-gray-700 text-base mt-2 mr-3  ${!open && 'scale-0'}`}>
          {userInfo?.username}
        </span>}
        <img className='object-right-bottom' src={logoutButton} alt='Logout' />
      </div>
    </div> */}
      </div>
    </div>
  );
};

export default BottomMenu;
