import AlertMessage from 'components/utils/AlertMessage';
import { DANGERMSGALERT } from 'components/utils/constants';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

function LabelModal({
  handlerDataLabel,
  setShowLabelModal,
  editData,
  handlerSaveEdit,
  addLabel,
  editLabel,
  loadingAddLabel,
  errorAddLabel,
  loadingEditLabel,
  errorEditLabel,
}) {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [labelError, setLabelError] = useState();

  useEffect(() => {
    setValue('title', editData.title);
    setValue('description', editData.description);
    setValue('module', editData.module);
  }, [editData]);

  const onSubmit = async (formData) => {
    const { title, description, module } = formData;

    if (Object.keys(editData).length === 0) {
      const { data } = await addLabel({
        variables: {
          title,
          description,
          module,
        },
      });

      if (errorAddLabel) {
        let error = 'Error';
        if (errorAddLabel.httpError) {
          error = `${errorAddLabel.httpError.statusText} - para obtener las etiquetas`;
        }

        setLabelError(error);
      }

      // console.log('data', data);

      if (data) {
        const newData = {
          id: data.addLabel.label.id,
          title: data.addLabel.label.title,
          description: data.addLabel.label.description,
          module: data.addLabel.label.module,
        };
        handlerDataLabel(newData);
      } else {
        console.log('error al salvar la etiqueta');
      }
    } else {
      const { data } = await editLabel({
        variables: {
          id: editData.id,
          title,
          description,
          module,
        },
      });

      if (errorEditLabel) {
        let error = 'Error';
        if (errorEditLabel.httpError) {
          error = `${errorEditLabel.httpError.statusText} - para modificar etiqueta`;
        }

        setLabelError(error);
      }

      if (data) {
        // console.log('updatedData', data);
        const updateData = {
          id: data.editLabel.label.id,
          title: data.editLabel.label.title,
          description: data.editLabel.label.description,
          module: data.editLabel.label.module,
        };
        handlerSaveEdit(updateData);
      } else {
        console.log('error al modificar la etiqueta');
      }
    }
  };
  // console.log('editData', editData);
  return (
    <Fragment>
      <div className='p-3'>
        <div className='fixed top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-50 flex justify-center items-center backdrop-blur-sm'>
          <div className='w-full flex justify-center'>
            <div className='w-9/12 bg-white h-auto p-16'>
              {(errorAddLabel || errorEditLabel) && (
                <AlertMessage
                  message={labelError}
                  title='Error: '
                  type={DANGERMSGALERT}
                />
              )}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='text-xl font-bold mb-4 text-center'>
                  Agregar Etiqueta
                </div>
                <div>
                  {(loadingAddLabel || loadingEditLabel) && (
                    <span>cargando...</span>
                  )}
                  <div className='sm:flex justify-between gap-5'>
                    <div className='w-full'>
                      <div className='mb-4'>
                        <label
                          className='block text-gray-700 text-sm font-bold mb-2'
                          htmlFor='title'
                        >
                          Titulo
                          <span className='text-red-700'>*</span>
                        </label>
                        <input
                          className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                          id='title'
                          {...register('title', { required: true })}
                          autoComplete='off'
                        />
                        {errors.title && (
                          <p className='text-red-500 text-xs italic'>
                            Ingrese un título para la etiqueta
                          </p>
                        )}
                      </div>
                      <div className='mb-4'>
                        <label
                          className='block text-gray-700 text-sm font-bold mb-2'
                          htmlFor='description'
                        >
                          Valor
                          <span className='text-red-700'>*</span>
                        </label>
                        <input
                          className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                          id='description'
                          {...register('description', { required: true })}
                          autoComplete='off'
                        />
                        {errors.description && (
                          <p className='text-red-500 text-xs italic'>
                            Ingrese un valor para el titulo
                          </p>
                        )}
                      </div>
                      <div className='mb-4'>
                        <label
                          className='block text-gray-700 text-sm font-bold mb-2'
                          htmlFor='description'
                        >
                          Modulo
                          <span className='text-red-700'>*</span>
                        </label>
                        <select
                          name='module'
                          id='module'
                          className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                          {...register('module', { required: true })}
                        >
                          <option value='mensajes'>Mensajes</option>
                          <option value='ordenes'>Ordenes</option>
                        </select>
                        {errors.module && (
                          <p className='text-red-500 text-xs italic'>
                            Selecciona un modulo
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='flex items-center justify-end gap-3'>
                    <button
                      type='button'
                      className='bg-red-200 border border-red-200 hover:border-red-500 inline-flex items-center px-4 py-2 ml-1 mr-2.5 font-semibold leading-6 text-sm shadow rounded-md text-red-700 transition ease-in-out duration-150'
                      onClick={() => {
                        setShowLabelModal(false);
                      }}
                    >
                      Cancelar
                    </button>
                    <button
                      className={`bg-primary-100 border border-primary-100 hover:border-primary-500 text-primary-700 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md  transition ease-in-out duration-150`}
                      type='submit'
                    >
                      {Object.keys(editData).length === 0
                        ? 'Guardar'
                        : 'Editar'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default LabelModal;
