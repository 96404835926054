import React, { useEffect, useState } from 'react';

const NewModalCompetitors = ({ product }) => {
  const [colors, setColors] = useState([]);

  const shippingType = [
    'Mercado envios envio a tu cargo',
    'Mercado envios envio a cargo del comprador',
    'Mercado envio flex envio a tu cargo',
    'Mercado envios flex envio a cargo del comprador',
    'Envio a coordinar',
    'No especificado',
  ];

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    let generatedColors = [];
    for (let index = 0; index < 15; index++) {
      generatedColors.push(getRandomColor());
    }

    setColors(generatedColors);
  }, []);

  return (
    <div className='h-[287px] mt-8'>
      <div className='h-[20px] flex items-center'>
        <span className='text-sm font-medium text-gray-700'>Competidores</span>
      </div>
      <div className='h-[261px] mt-[5px] border border-gray-200 shadown shadow-sm rounded-xl'>
        <div className='w-full flex bg-gray-50 rounded-t-xl border border-x-0 border-t-0 border-b-gray-200'>
          <span className='w-[52%] h-full py-[12px] px-[24px] flex items-center text-xs font-medium text-gray-600'>
            Vendedor
          </span>
          <span className='w-[20%] h-full py-[12px] px-[24px] flex items-center text-xs font-medium text-gray-600'>
            Precio
          </span>
          <span className='w-[35%] h-full py-[12px] px-[24px] flex items-center text-xs font-medium text-gray-600'>
            Forma de entrega
          </span>
        </div>
        <div className='h-[217px] overflow-y-auto'>
          {product?.competition.length > 0 &&
            product.competition.map((competitor, idx) => (
              <div
                className='h-[72px] py-0 w-full flex border border-x-0 border-t-0 border-b-gray-200'
                key={idx}
              >
                <div className='h-full w-[48px] flex justify-center items-center ml-1'>
                  <span
                    className={`w-full h-[48px] p-4 rounded-full border border-1 border-gray-200 bg-[${colors[idx]}] flex justify-center items-center font-bold`}
                  >
                    {competitor.nickname.charAt(0)}
                  </span>
                </div>
                <div className='w-[45%] h-full py-[16px] px-[18px] items-center'>
                  <div className='w-full'>
                    <span className='w-full text-sm font-normal text-gray-900'>
                      {competitor.nickname}
                    </span>
                  </div>
                  <div className='w-full'>
                    <span className='w-full text-xs font-normal text-gray-600'>
                      +500 VENTAS
                    </span>
                  </div>
                </div>
                <span className='w-[20%] h-full py-[16px] px-[24px] flex items-center text-sm font-normal text-gray-900'>
                  {competitor.currency_id} {competitor.price}
                </span>
                <span className='w-[35%] h-full py-[16px] px-[24px] flex items-center text-sm font-normal text-gray-900'>
                  {(competitor.shipping.mode === 'me2' &&
                    competitor.shipping.tags?.includes('self_service_in') &&
                    competitor.shipping.free_shipping &&
                    shippingType[2]) ||
                    (competitor.shipping.mode === 'me2' &&
                      competitor.shipping.tags?.includes('self_service_in') &&
                      !competitor.shipping.free_shipping &&
                      shippingType[3]) ||
                    (competitor.shipping.mode === 'me2' &&
                      !competitor.shipping.tags.includes('self_service_in') &&
                      competitor.shipping.free_shipping &&
                      shippingType[0]) ||
                    (competitor.shipping.mode === 'me2' &&
                      !competitor.shipping.tags?.includes('self_service_in') &&
                      !competitor.shipping.free_shipping &&
                      shippingType[1]) ||
                    (competitor.shipping.mode === 'custom' &&
                      shippingType[4]) ||
                    (competitor.shipping.free_shipping && shippingType[5])}
                </span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default NewModalCompetitors;
