import React, { useEffect, useRef, useState } from 'react';
import { Provider } from './Provider';

const ChevronLeft = () => <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5 11L1.5 6L6.5 1" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
</svg>

const ChevronRight = () => <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 11L6.5 6L1.5 1" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
</svg>

const ChevronDown = () => <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L6 6L11 1" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
</svg>

const ChevronUp = () => <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 7L6 2L11 7" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
</svg>

// TODO: change this icon
// Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
const AddSymbol = () => <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" /></svg>

const Spinner = () => <div role="status">
    <svg aria-hidden="true" className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="gray" />
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.0830 38.2159 91.5421 39.6781 93.9676 39.0409Z" fill="rgb(105 65 198)" />
    </svg>
    <span className="sr-only">Loading...</span>
</div>

export interface AddProductOnDemandProps {
    search: string;
    list: AddProductOnDemandListItem[];
    loading: boolean;
    onSelected: (item: AddProductOnDemandListItem) => void;
    onCancel: () => void;
    onSearch: (query: string) => void;
}

export interface AddProductOnDemandListItem {
    id: string | null;
    catalogProductId?: string | null;
    name: string;
    images: string[];
    price?: number | null;
    provider: Provider,
}

const ProductLink = ({ selectedItem }: { selectedItem: AddProductOnDemandListItem }) => {
    if (selectedItem.provider === Provider.MERCADOLIBRE) {
        return <a className="text-primary-700 ml-4" href={selectedItem?.id ? `https://articulo.mercadolibre.com.uy/${selectedItem?.id?.replace('MLU', 'MLU-')}` : `https://mercadolibre.com.uy/p/${selectedItem.catalogProductId}`} target='_blank'>Ver en MercadoLibre</a>
    } else if (selectedItem.provider === Provider.AMAZON) {
        return <a className="text-primary-700 ml-4" href={`https://www.amazon.com/dp/${selectedItem.id}`} target='_blank'>Ver en Amazon</a>
    } else {
        return null
    }
}

const AddProductOnDemand = (props: AddProductOnDemandProps) => {
    const maxListItemsVisible = 6;
    const [search, setSearch] = useState<string>(props.search);
    const [selectedItem, setSelectedItem] = useState<AddProductOnDemandListItem>(props.list[0]);
    const [leftListFirstItem, setLeftListFirstItem] = useState(0);
    const [rightListFirstItem, setRightListFirstItem] = useState(0);

    const mercadolibreList = props.list.filter(item => item && item.provider === Provider.MERCADOLIBRE);
    const amazonList = props.list.filter(item => item && item.provider === Provider.AMAZON);

    useEffect(() => {
        console.log('Props List Changed:', props.list);
        setSelectedItem(props.list[0]);
    }, [props.list]);

    useEffect(() => {
        handleOnSearch(props.search);
    }, []);

    const handleOnSearch = (query: string) => {
        setSearch(query);
        typeof props.onSearch === 'function' && props.onSearch(query);
    }

    const handleSelect = () => {
        console.log('Selected Item on Select:', selectedItem);
        props.onSelected(selectedItem);
    };

    return <div className="fixed top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-50 flex justify-center items-center backdrop-blur-sm">
        {!props.loading && <div className='h-[calc(100%-8rem)]'>
            <div>
                <div className={`w-20 relative left-0 p-1 pr-0 rounded-l-md text-xs text-center bg-white font-bold`} >En ML</div>
                <div className={`bg-white mt-1 p-1 rounded-l-md select-none font-bold`} onClick={() => leftListFirstItem > 0 && setLeftListFirstItem(leftListFirstItem - 1)}><div className={`w-full flex items-center justify-center ${leftListFirstItem < 1 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}><ChevronUp /></div></div>
                {
                    mercadolibreList.slice(leftListFirstItem, leftListFirstItem + maxListItemsVisible).map((item, index) => (
                        <div key={item.id || item.catalogProductId} className={`relative w-20 p-1 pr-0 mb-1 mt-1 rounded-l-md ${item === selectedItem ? "bg-primary-700" : 'bg-white'}`} >
                            {item.catalogProductId ? <div className="text-xs w-full text-center rounded-sm  bg-primary-700 text-white">En catálogo</div> : <div className='text-xs w-full text-center rounded-sm rounded-b-none bg-rose-400'>En tienda</div>}
                            <img className={`h-20 rounded-bl-md ${item.catalogProductId ? 'grayscale opacity-50' : ''}`} src={item.images[0]} onMouseEnter={() => setSelectedItem(item)} />
                            {item.catalogProductId ? <div className='absolute bottom-6 left-1/2 opacity-80 -ml-4 shadow-md rounded-3xl bg-white text-center m-auto text-4xl font-bold'></div> : ''}
                        </div>
                    ))
                }
                <div className={`bg-white p-1 rounded-l-md select-none font-bold`} onClick={() => leftListFirstItem + maxListItemsVisible < mercadolibreList.length && setLeftListFirstItem(leftListFirstItem + 1)}><div className={`w-full flex items-center justify-center ${leftListFirstItem + maxListItemsVisible >= mercadolibreList.length ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}><ChevronDown /></div></div>
            </div>
        </div>}
        <div className="bg-white p-8 shadow-xl rounded-md w-1/2 h-[calc(100%-4rem)]">
            {props.loading ?
                <div className='grid place-content-center h-full'><Spinner /></div> :
                <div className='relative pb-16 flex flex-col h-full'>
                    <input className='flex-none w-full border-2 border-gray-300 focus:outline-none focus:border-primary-700 rounded-md p-2 mb-8' placeholder='Buscar' value={search} onChange={event => setSearch(event.target.value)} onKeyDown={event => event.key === 'Enter' && handleOnSearch(search)} />
                    {selectedItem && <>
                        <div className="flex-grow">
                            <ImageSelector item={selectedItem} />
                            <div className='p-2 mt-4'>{selectedItem.name} <ProductLink selectedItem={selectedItem} /></div>
                        </div>
                        <div className="absolute flex flex-row space-x-4 right-0 -bottom-2">
                            <div className='select-none mx-auto cursor-pointer rounded-md w-32 bg-primary-700 m-2 p-1 text-white text-center' onClick={() => props.onCancel()}>Cancelar</div>
                            <div className='select-none mx-auto cursor-pointer rounded-md w-32 bg-primary-700 m-2 p-1 text-white text-center' onClick={handleSelect}>Seleccionar</div>
                        </div>
                    </>}
                </div>
            }
        </div>
        {!props.loading && <div className='h-[calc(100%-8rem)]'>
            <div className={`w-20 relative right-0 p-1 pl-0 rounded-r-md text-xs text-center bg-white font-bold`} >En Amazon</div>
            <div className={`bg-white mt-1 p-1 rounded-r-md select-none font-bold`} onClick={() => rightListFirstItem > 0 && setRightListFirstItem(rightListFirstItem - 1)}><div className={`w-full flex items-center justify-center  ${rightListFirstItem < 1 ? 'opacity-50 cursor-not-allowed' : 'text-black cursor-pointer'}`}><ChevronUp /></div></div>
            {
                amazonList.slice(rightListFirstItem, rightListFirstItem + maxListItemsVisible).map((item) => (
                    <div className={`relative w-20 p-1 pl-0 rounded-r-md bg-white mb-1 mt-1 ${item === selectedItem ? "bg-primary-700" : ''}`} >
                        {item.catalogProductId ? <div className="text-xs w-full text-center rounded-sm  bg-pink-500 text-white">En catálogo</div> : <div className='text-xs w-full text-center rounded-sm rounded-b-none bg-sky-500'>Para agregar</div>}
                        <img className={`h-20 rounded-bl-md grayscale opacity-50`} src={item.images[0]} onMouseEnter={() => setSelectedItem(item)} />
                        <div className='absolute bottom-6 left-1/2 opacity-80 -ml-4 shadow-md rounded-3xl bg-white text-center m-auto text-4xl font-bold'></div>
                    </div>
                ))
            }
            <div className={`bg-white p-1 rounded-r-md select-none font-bold `} onClick={() => rightListFirstItem + maxListItemsVisible < amazonList.length && setRightListFirstItem(rightListFirstItem + 1)}><div className={`w-full flex items-center justify-center ${rightListFirstItem + maxListItemsVisible >= amazonList.length ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'} `}><ChevronDown /></div></div>
        </div>}
    </div>
}

interface ImageSelectorProps {
    item: AddProductOnDemandListItem;
}

const ImageSelector = (props: ImageSelectorProps) => {
    const [selectedImage, setSelectedImage] = useState<number>(props.item.images.length - 1);

    useEffect(() => {
        setSelectedImage(0);
    }, [props.item]);

    const handleBackClick = (index: number) => {
        setSelectedImage(index <= 0 ? props.item.images.length - 1 : index - 1);
    }

    const handleNextClick = (index: number) => {
        setSelectedImage(index >= props.item.images.length - 1 ? 0 : index + 1);
    }

    return <div className='snap-top relative w-80 mx-auto shadow-xl'>
        <div className='absolute top-1/2 inline-block select-none cursor-pointer rounded-xl bg-white m-1 p-2 shadow-md' onClick={() => handleBackClick(selectedImage)}><ChevronLeft /></div>
        <div className='absolute top-1/2 right-0 inline-block select-none cursor-pointer rounded-xl bg-white m-1 p-2 shadow-md' onClick={() => handleNextClick(selectedImage)}><ChevronRight /></div>
        <img className='w-80' src={props.item.images[selectedImage]} />
        <div className='text-center relative'>
        </div>
    </div>
}

export default AddProductOnDemand;