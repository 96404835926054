const { useQuery, useManualQuery } = require('graphql-hooks');

const DeleteQuestionMutation = `
mutation DeleteQuestionMutation($questionId: String) {
    deleteQuestion(questionId: $questionId) {
        status
        error
    }
}
`;

const useDeleteQuestion = () => {
  const {
    loading,
    error,
    data: deleteQuentionResponse,
  } = useQuery(DeleteQuestionMutation);
  return { loading, error, deleteQuentionResponse };
};

const useDeleteQuestionManual = () => {
  const [deleteQuestion, { loading, error, data: deleteQuentionResponse }] =
    useManualQuery(DeleteQuestionMutation);
  return { deleteQuestion, loading, error, deleteQuentionResponse };
};

export { DeleteQuestionMutation, useDeleteQuestion, useDeleteQuestionManual };
