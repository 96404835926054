import { useQuery, useManualQuery } from 'graphql-hooks';

const bidOnProductQuery = `
mutation BidOnProduct ($productId: String!, $catalogProductId: String!, $minPrice: Float!, $maxPrice: Float!, $currency: String!, $active: Boolean!) {
    bidOnProduct(productId: $productId, catalogProductId: $catalogProductId, minPrice: $minPrice, maxPrice: $maxPrice, currency: $currency, active: $active) {
        productId
        catalogProductId
        minPrice
        maxPrice
        currency
        active
    }
}`;

export const useBidOnProduct = (productId, catalogProductId, minPrice, maxPrice, currency, active) => {
    const { data, loading, error } = useQuery(bidOnProductQuery, {
        variables: { productId, catalogProductId, minPrice, maxPrice, currency, active }
    });

    return { data, loading, error };
}

export const useBidOnProductManual = () => {
    const [bidOnProduct, { data, loading, error }] = useManualQuery(bidOnProductQuery);
    return { bidOnProduct, data, loading, error };
}