import { useQuery, useManualQuery } from 'graphql-hooks';

const query = `
query GetCatalogProductByAsin($asin: String!) {
    getCatalogProductByAsin(asin: $asin) {
        id
        name
        status
        domain_id
        pictures {
            id
            url
            max_width
            max_height
        }
    }
}
`

export const useGetCatalogProductByAsin = (asin: string) => {
    const { loading, error, data } = useQuery(query, {
        variables: {
            asin
        }
    });
    return {
        loading,
        error,
        data
    }
}

export const useGetCatalogProductByAsinManual = () => {
    const [getCatalogProductByAsin, { loading, data, error }] = useManualQuery(query);
    return {
        loading,
        error,
        data,
        getCatalogProductByAsin
    }
}