import { useQuery, useManualQuery } from 'graphql-hooks';

export const PublishProductMutation = `
  mutation publishProductFromCatalog($productId: String!, $price: Float!, $stock: Int, $currency: String!, $categoryId: String!, $sku: String) {
    publishProductFromCatalog(productId: $productId, price: $price, stock: $stock, currency: $currency, categoryId: $categoryId, sku: $sku) {
      id,
      permalink,
      missingFields {
        id,
        name,
        value_type,
        value_max_length
      },
      error
    }
  }
`;

const usePublishProduct = () => {
  const {
    loading,
    error,
    data: publishProductResponse,
  } = useQuery(PublishProductMutation);
  return { loading, error, publishProductResponse };
};

const usePublishProductManual = () => {
  const [publishProduct, { loading, error, data: publishProductResponse }] =
    useManualQuery(PublishProductMutation);
  return { publishProduct, loading, error, publishProductResponse };
};

export { usePublishProduct, usePublishProductManual };
