import { useManualQuery } from 'graphql-hooks';

const SearchItemByAsinQuery = `
  query SearchItemByAsin($asin: String!) {
    searchItemByAsin (asin: $asin) {
        products {
            id
            title
            image
            price
            available_quantity
            sold_quantity
            currency_id
            status
            start_time
            permalink
            seller_custom_field
            descriptions
            pictures{
                link
                variant
                width
                height
                id
            }
            asinUrl
            attributes {
                name
                value
            }
            categoryPath
            catalog_listing
          }
    }
  }
`;

const useSearchItemByAsinManual = () => {
  const [searchItemByAsin, { loading, error, data }] = useManualQuery(
    SearchItemByAsinQuery
  );
  const { products } = data ? data.searchItemByAsin : { products: [] };
  return { searchItemByAsin, loading, error, products };
};

export { useSearchItemByAsinManual };
