import { useSubscription } from "graphql-hooks";

export const query = `
subscription SyncStoreProgress {
    getStoreSyncProgress {
        total
        progress
    }
}
`;

export interface StoreSyncProgress {
    total: number;
    progress: number;
}

export const useGetStoreSyncProgressSubscription = (onData: (data: StoreSyncProgress) => void) => {
    useSubscription({
        query,
        variables: {}
    }, (response: any) => {
        console.log('useGetStoreSyncProgressSubscription', response);
        const data = response.data;
        if(data) onData(data.getStoreSyncProgress);
    });
};
