import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import {
  usersApi,
  useFetchUserInfoQuery,
  useUpdateBasicUserInfoMutation,
  useCreateUserForStoreMutation,
  useRemoveUserFromStoreMutation,
  useUpdateUserPermissionsForStoreMutation,
} from './api/usersApi';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { authSlice, clearSession, setSession } from './slices/authSlice';
import { promptSlice, showMessage, hideMessage } from './slices/promptSlice';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: setSession,
  effect: async (action) => {
    localStorage.setItem(
      'session',
      JSON.stringify({ sessionInfo: action.payload })
    );
  },
});

listenerMiddleware.startListening({
  actionCreator: clearSession,
  effect: async () => {
    localStorage.setItem('session', '');
  },
});

const store = configureStore({
  reducer: {
    [usersApi.reducerPath]: usersApi.reducer,
    auth: authSlice.reducer,
    prompt: promptSlice.reducer,
  },
  middleware: (getDefMiddleware) => {
    return getDefMiddleware({ serializableCheck: false })
      .prepend(listenerMiddleware.middleware)
      .concat(usersApi.middleware);
  },
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export {
  useFetchUserInfoQuery,
  useUpdateBasicUserInfoMutation,
  useCreateUserForStoreMutation,
  useRemoveUserFromStoreMutation,
  useUpdateUserPermissionsForStoreMutation,
  store,
};
export { clearSession, showMessage, hideMessage };
