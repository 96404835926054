import React, { useState } from 'react';
import { LineMdUploadingLoop } from './LineMdUploadingLoop';

const ImageWithFallback = ({ className, src, alt }: { className: string, src: string, alt: string }) => {
    const [error, setError] = useState(false);

    const handleError = () => {
        if (!error) {
            setError(true);
        }
    };

    return error ? <LineMdUploadingLoop /> : <div className='w-[8%]'>
        <img className={className} src={src} onError={handleError} alt={alt} />
    </div>;
};

export { ImageWithFallback };