import { CustomField, CustomInput } from 'components/MLForm/form-utils';
import React from 'react';
import { useForm } from 'react-hook-form';
import {
  useFetchUserInfoQuery,
  useUpdateBasicUserInfoMutation,
} from 'store/api/usersApi';

interface ProfileUserInfo {
  id: string;
  email: string;
  username: string;
  firstname: string;
  lastname: string;
  isAdmin: boolean;
  storeId: string;
}

interface EditUserInfoFormValues {
  username: string;
  firstname: string;
  lastname: string;
}

interface EditUserInfoProps {
  userInfo: any;
}

const formDefaultValues: EditUserInfoFormValues = {
  username: '',
  firstname: '',
  lastname: '',
};

function EditUserInfo({ userInfo }: EditUserInfoProps) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: formDefaultValues,
  });

  const [updateBasicUserInfo] = useUpdateBasicUserInfoMutation();
  const { data: userData } = useFetchUserInfoQuery({});
  const profileUserInfo: ProfileUserInfo = userData as ProfileUserInfo;

  const onSubmitForm = async (formData: EditUserInfoFormValues) => {
    try {
      await updateBasicUserInfo({
        userId: profileUserInfo.id,
        data: formData,
      }).unwrap();
      //TODO: Optional: After complete user info, Atibo can navigate to remove token info from URL
    } catch (err: any) {
      if (err && err.status === 409) {
        // username already exists
        setError(
          'username',
          { type: 'custom', message: err.data.message },
          { shouldFocus: true }
        );
      }
    }
  };

  return (
    <div className='fixed top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-50 flex justify-center items-center backdrop-blur-sm'>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className='w-screen flex justify-center max-h-screen overflow-y-auto'>
          <div className='max-w-screen-xl w-full md:mx-32 lg:mx-32'>
            <div className='bg-white shadow-md rounded px-8 pt-12 pb-8 mb-4'>
              <div className='w-full'>
                <h2 className='text-4xl font-semibold text-center mb-6'>
                  Para continuar actualiza tus datos
                </h2>

                <div className='mb-4'>
                  <CustomInput
                    id='email'
                    inputProps={{ disabled: true, value: userInfo?.email }}
                  />
                </div>
                <div className='mb-4'>
                  <CustomField
                    id='username'
                    label='Nombre de Usuario'
                    inputProps={{
                      ...register('username', { required: true }),
                    }}
                  />
                  {errors.username && (
                    <p className='text-red-500 text-xs italic'>
                      {errors.username.message
                        ? errors.username.message
                        : 'Ingrese un nombre de usuario'}
                    </p>
                  )}
                </div>
                <div className='mb-4'>
                  <CustomField
                    id='firstname'
                    label='Nombre'
                    inputProps={{
                      ...register('firstname', { required: true }),
                    }}
                  />
                  {errors.firstname && (
                    <p className='text-red-500 text-xs italic'>
                      Ingrese un nombre
                    </p>
                  )}
                </div>
                <div className='mb-4'>
                  <CustomField
                    id='lastname'
                    label='Apellido'
                    inputProps={{
                      ...register('lastname', { required: true }),
                    }}
                  />
                  {errors.lastname && (
                    <p className='text-red-500 text-xs italic'>
                      Ingrese un apellido
                    </p>
                  )}
                </div>
              </div>
              <div className='flex items-center justify-end gap-3'>
                <button
                  className={`text-white rounded-md px-2 py-1 mr-2 bg-primary-700`}
                  type='submit'
                  disabled={isSubmitting}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditUserInfo;
