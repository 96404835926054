import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSearchCatalogProductsManual } from '../hooks/useSearchCatalogProducts';
import { BidOnProductModal } from './BidOnProductModal';
import { useBottomReached } from '../hooks/useBottomReached';
import { SearchBox } from './SearchBox';
import { useEditBidStatusManual } from 'hooks/useEditBidStatus';
import { DelayedSwitch } from './utils/Switch';
import ArticleIcon from './utils/iconComponents/ArticleIcon';
import { SpinnerIcon } from './MlCatalogProductList/SpinnerIcon';
import { ExclamationIcon } from './MlCatalogProductList/ExclamationIcon';
import DotMenuIcon from './utils/iconComponents/DotMenuIcon';
import { BidOnProductNewModal } from './BidOnProductNewModal';
import LinkArrow from './images/LinkArrow.svg';
import { Scrollbar } from 'react-scrollbars-custom';

export const CatalogProductsList = ({ setSelectedTabNumber }) => {
  const [query, setQuery] = useState('');
  const [bidOnProductModalVisible, setBidOnProductModalVisible] =
    useState(false);
  const [bidOnProductNewModalVisible, setBidOnProductNewModalVisible] =
    useState(false);

  const {
    loadSearchCatalogProducts,
    loadingSearchCatalogProducts,
    searchCatalogProductsError,
    products,
    total,
  } = useSearchCatalogProductsManual();

  const {
    updateBidStatus,
    loading: loadingBidStatus,
    error: errorBidStatus,
    bidResponse,
  } = useEditBidStatusManual();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [catalogProducts, setCatalogProducts] = useState(products);
  const [page, setPage] = useState(0);
  const bottomOfScreenReached = useBottomReached();
  const [loadedProducts, setLoadedProducts] = useState([]);
  const pageSize = 20;
  const lastPage = Math.floor(total / pageSize);
  const [reachedTable, setReachedTable] = useState(false);

  useEffect(() => {
    setPage(0);
    setLoadedProducts([]);
    loadSearchCatalogProducts({
      variables: { query, offset: page * pageSize, limit: pageSize },
    });
  }, [query]);

  useEffect(() => {
    // loadSearchCatalogProducts({
    //   variables: { query, offset: page * pageSize, limit: pageSize },
    // });
  }, [page]);

  useEffect(() => {
    setCatalogProducts(products);
  }, [products]);

  useEffect(() => {
    if (
      (bottomOfScreenReached || reachedTable) &&
      products &&
      !loadingSearchCatalogProducts &&
      page < lastPage
    ) {
      // setReachedTable(false);
      // loadSearchCatalogProducts({
      //   variables: { query, offset: (page + 1) * pageSize, limit: pageSize },
      // });
      // setPage(page + 1);
    }
  }, [bottomOfScreenReached, reachedTable]);

  useEffect(() => {
    if (catalogProducts) {
      setLoadedProducts((loadedProducts) =>
        loadedProducts.concat(catalogProducts)
      );
    }
  }, [catalogProducts]);

  const handleBidOnProduct = (bid) => {
    const newLoadedProducts = loadedProducts.map((product) => {
      if (product.id === bid.data.bidOnProduct.productId) {
        return { ...product, currentBid: bid.data.bidOnProduct };
      }
      return product;
    });
    setLoadedProducts([...newLoadedProducts]);
    setBidOnProductNewModalVisible(false);
  };

  const handlerPujaOnChange = async (e) => {
    if (e.target.value) {
      const { data } = await updateBidStatus({
        variables: {
          id: e.target.value,
          active: e.target.checked,
        },
      });

      if (data) {
        const newLoadedProducts = loadedProducts.map((product) => {
          if (product.id === data.updateBidStatusById.productId) {
            product.currentBid.active = data.updateBidStatusById.active;
          }
          return product;
        });

        setLoadedProducts([...newLoadedProducts]);
      }
    }
  };

  const handlerScroll = (e) => {
    console.log(e.contentScrollHeight, e.scrollTop, e.clientHeight);
    if (e.contentScrollHeight - e.scrollTop === e.clientHeight) {
      loadSearchCatalogProducts({
        variables: { query, offset: (page + 1) * pageSize, limit: pageSize },
      });
      setPage(page + 1);
    }
  };

  return (
    <div>
      {/* {loadingSearchCatalogProducts && <div>Cargando...</div>} */}
      <div className='w-full h-[60px] flex justify-between mb-2'>
        <div className='w-[3%] h-[60px] flex justify-start p-2 items-center'>
          <ArticleIcon />
        </div>
        <div className='w-[57%] h-[60px] flex justify-start p-[10px]'>
          <div className='w-full h-[40px] flex gap gap-4'>
            <div
              className='h-[36px] rounded-md content-center cursor-pointer'
              onClick={() => setSelectedTabNumber(0)}
            >
              <span className='px-[12px] py-[8px] text-sm font-semibold text-gray-500'>
                Administrar artículos
              </span>
            </div>
            <div
              className='h-[36px] rounded-md content-center cursor-pointer'
              onClick={() => setSelectedTabNumber(1)}
            >
              <span className='px-[12px] py-[8px] text-sm font-semibold text-gray-500'>
                Artículos elegibles
              </span>
            </div>
            <div className='h-[36px] rounded-md content-center'>
              <span className='px-[12px] py-[8px] text-sm font-semibold text-primary-700'>
                Estrategia de optimización
              </span>
            </div>
            <div
              className='h-[36px] rounded-md content-center cursor-pointer'
              onClick={() => setSelectedTabNumber(3)}
            >
              <span className='px-[12px] py-[8px] text-sm font-semibold text-gray-500'>
                Todos los artículos
              </span>
            </div>
          </div>
        </div>
        <div className='w-[40%] h-[60px] flex justify-end'>
          <div className='grow shrink basis-0 self-stretch flex-col justify-center items-end gap-3 inline-flex'>
            <div className='w-80 h-11 flex-col justify-start items-start gap-1.5 flex'>
              <div className='h-[44px] self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex'>
                <div className='grow shrink basis-0 h-6 justify-start items-center gap-2 flex'>
                  <div className="text-gray-500 text-base font-normal font-['Inter'] leading-normal"></div>
                  <SearchBox
                    query={query}
                    onQueryChanged={_.debounce(setQuery, 1000)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full h-11 py-3 bg-gray-50 shadow border border-b-0 border-gray-200 justify-start items-center rounded-t-xl'>
        <div className='flex items-center px-6'>
          <div className='w-[75px] flex justify-left text-xs font-normal text-gray-600'>
            Imagen
          </div>
          <div className='flex-1 px-2 flex justify-center text-xs font-normal text-gray-600'>
            Titulo
          </div>
          <div className='w-[110px] flex justify-center text-xs font-normal text-gray-600'>
            ID
          </div>
          <div className='w-[90px] flex justify-center text-xs font-normal text-gray-600'>
            SKU
          </div>
          <div className='w-[120px] flex justify-center text-xs font-normal text-gray-600'>
            ID Catálogo
          </div>
          <div className='w-[100px] flex justify-center text-xs font-normal text-gray-600'>
            Precio
          </div>
          <div className='w-[170px] flex justify-center text-xs font-normal text-gray-600'>
            Precio dinámico
          </div>
          <div className='w-[95px] flex justify-center text-xs font-normal text-gray-600'>
            Estado
          </div>
          <div className='w-[170px] flex justify-center text-xs font-normal text-gray-600'>
            Competidores
          </div>
          <div className='w-[95px] flex justify-center items-center text-xs font-normal text-gray-600'>
            <div className='w-[60px] pl-6 text-center'>Activar</div>
          </div>
          <div className='w-[32px]'></div>
        </div>
      </div>
      <div
        className='w-full flex-col justify-start items-start gap-6 inline-flex'
        style={{ height: 'calc(100vh - 130px)' }}
      >
        <div className='self-stretch bg-white flex-col justify-start items-start flex rounded-t-0 rounded-b-xl' style={{ height: 'calc(100vh - 162px)' }}>
          <div className='w-full h-full bg-white rounded-t-0 rounded-b-xl shadow border border-gray-200 flex-col justify-start items-start flex'>
            <Scrollbar 
              onScroll={handlerScroll} 
              style={{ width: '100%', height: '100%' }}
              trackYProps={{
                style: {
                  width: '8px',
                  background: '#f1f1f1',
                  borderRadius: '4px',
                  marginTop: '-10px',
                  height: '100%',
                  padding: 0
                }
              }}
              thumbYProps={{
                style: {
                  background: '#c1c1c1',
                  borderRadius: '4px'
                }
              }}
            >
              <div className='w-full self-stretch bg-white justify-start items-start inline-flex'>
                <div className='w-full flex-col justify-start items-start inline-flex'>
                  {loadedProducts.map((catalogProduct, idx) => (
                    <div
                      className='flex w-full items-center px-1 py-1 border-b hover:bg-gray-50 border-gray-200'
                      key={idx}
                    >
                      <div className='flex items-center justify-center'>
                        <img
                          className='w-[75px] h-[75px] object-cover rounded-[10px] border border-gray-200'
                          src={catalogProduct.thumbnail}
                          alt={catalogProduct.title}
                        />
                      </div>
                      <div className='flex-1 px-2 text-sm text-gray-600' title={catalogProduct.title}>
                        {catalogProduct.title}
                      </div>
                      <div className='w-[110px] flex justify-center text-xs text-gray-900 px-1'>
                        <a
                          href={catalogProduct.permalink}
                          target='_blank'
                          rel='noreferrer'
                          className='text-primary-700 flex items-center'
                        >
                          <span>{catalogProduct.id}</span>
                          <img
                            src={LinkArrow}
                            alt='Arrow'
                            className='ml-1 w-2 h-2'
                          />
                        </a>
                      </div>
                      <div className='w-[90px] flex justify-center text-xs text-gray-900 px-1'>
                        {catalogProduct?.seller_custom_field && (
                          <a
                            href={`https://amazon.com/dp/${catalogProduct.seller_custom_field}`}
                            target='_blank'
                            rel='noreferrer'
                            className='text-primary-700 flex items-center'
                          >
                            <span>{catalogProduct.seller_custom_field}</span>
                            <img
                              src={LinkArrow}
                              alt='Arrow'
                              className='ml-1 w-2 h-2'
                            />
                          </a>
                        )}
                      </div>
                      <div className='w-[120px] flex justify-center text-xs text-gray-900'>
                        {catalogProduct?.catalog_product_id && (
                          <a href={`https://mercadolibre.com.uy/p/${catalogProduct.catalog_product_id}`} target='_blank' rel='noreferrer' className='text-primary-700 flex items-center justify-center'>
                            <span className='text-center'>{catalogProduct.catalog_product_id}</span>
                            <img src={LinkArrow} alt='Arrow' className='ml-1 w-2 h-2' />
                          </a>
                        )}
                      </div>
                      <div className='w-[100px] px-2 text-xs text-gray-600'>
                        {catalogProduct.currency_id} {catalogProduct.price}
                      </div>
                      <div className='w-[170px] px-2 text-xs text-gray-600'>
                        {catalogProduct.currentBid &&
                          catalogProduct?.currentBid?.active === true && (
                            <>
                              {catalogProduct.currency_id}{' '}
                              {catalogProduct.currentBid.minPrice} -{' '}
                              {catalogProduct.currency_id}{' '}
                              {catalogProduct.currentBid.maxPrice}
                            </>
                          )}
                      </div>
                      <div className='w-[95px]'>
                        <span
                          className={`w-full flex justify-center rounded-2xl px-[2px] py-[2px] ${
                            catalogProduct.winnerStatus == 'Ganando'
                              ? 'bg-green-600'
                              : 'bg-orange-500'
                          } text-white text-xs h-[22px] w-[72px] inline-block text-center`}
                        >
                          {catalogProduct.winnerStatus}
                        </span>
                      </div>
                      <div className='w-[170px] flex justify-center'>
                        <div className='flex -space-x-2 overflow-hidden'>
                          {catalogProduct.competition.map(
                            (competitor, idx) =>
                              idx <= 2 && (
                                <span
                                  className={`inline-block h-10 w-10 rounded-full ring-2 ring-white text-white content-center text-center font-bold cursor-default ${
                                    (idx == 0 && 'bg-[#A2A8CD]') ||
                                    (idx == 1 && 'bg-[#C7B9DA]') ||
                                    (idx == 2 && 'bg-[#AA9C75]')
                                  }`}
                                  title={competitor.nickname}
                                >
                                  {competitor.nickname.charAt(0)}
                                </span>
                              )
                          )}

                          {catalogProduct.competition.length > 3 && (
                            <span
                              className='inline-block h-10 w-10 rounded-full ring-2 ring-white bg-gray-100 text-gray-600 content-center text-center font-bold cursor-default'
                              title='Otros competidores'
                            >
                              +{catalogProduct.competition.length - 4}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className='w-[95px] flex justify-center'>
                        <div className='w-[60px] flex justify-center'>
                          <DelayedSwitch
                            id='puja'
                            name='puja'
                            value={
                              catalogProduct?.currentBid?.active
                                ? catalogProduct.currentBid.id
                                : ''
                            }
                            onChange={(e) => {
                              if (catalogProduct?.currentBid?.active) {
                                handlerPujaOnChange && handlerPujaOnChange(e);
                              } else {
                                setSelectedProduct(catalogProduct);
                                setBidOnProductNewModalVisible(true);
                              }
                            }}
                            checked={catalogProduct?.currentBid?.active}
                          />
                        </div>
                      </div>
                      <div className='w-[32px]'>
                        <span
                          className='flex justify-end cursor-pointer h-[25px] pr-4'
                          onClick={() => {
                            setSelectedProduct(catalogProduct);
                            setBidOnProductNewModalVisible(true);
                          }}
                        >
                          <DotMenuIcon />
                        </span>
                      </div>
                    </div>
                  ))}
                  {loadingSearchCatalogProducts && (
                    <div className='w-full h-[36px] flex-col justify-start items-start inline-flex'>
                      <div className='w-full mt-2 flex justify-center'>
                        <SpinnerIcon />
                      </div>
                    </div>
                  )}
                  {searchCatalogProductsError && (
                    <div className='mt-4'>
                      <ExclamationIcon message={searchCatalogProductsError} />
                    </div>
                  )}
                </div>
              </div>
            </Scrollbar>
          </div>
        </div>
      </div>
      {bidOnProductModalVisible && (
        <BidOnProductModal
          product={selectedProduct}
          onCancel={() => setBidOnProductModalVisible(false)}
          onBid={handleBidOnProduct}
        />
      )}
      {bidOnProductNewModalVisible && (
        <BidOnProductNewModal
          product={selectedProduct}
          onCancel={() => setBidOnProductNewModalVisible(false)}
          onBid={handleBidOnProduct}
        />
      )}
    </div>
  );
};
