import { useQuery, useManualQuery } from 'graphql-hooks';

const SearchMlCatalogProductsQuery = `
query SearchMlCatalogProducts($query: String!, $offset: Int, $limit: Int) {
  searchMlCatalogProducts (query: $query, offset: $offset, limit: $limit) {
    offset
    total
    products {
      id
      domain_id
      name
      thumbnail
      permalink
      pictures {
        id
        url
      }
      pickers {
        name
        options {
          productId
          label
          thumbnail
          permalink
        }
      }
      parent_id
    }
  }
}
`;


const useSearchMlCatalogProducts = (query) => {
  const { loading, error, data } = useQuery(SearchMlCatalogProductsQuery, {
    variables: {
      query
    }
  });
  return { loading, error, data }; 
}

const useSearchMlCatalogProductsManual = () => {
  const [searchMlCatalogProducts, { loading, error, data }] = useManualQuery(SearchMlCatalogProductsQuery);
  const { products, offset, total } = data ? data.searchMlCatalogProducts : { products: [], offset: 0, total: 0 };
  return { searchMlCatalogProducts, loading, error, products, offset, total };
}

export {
  useSearchMlCatalogProducts,
  useSearchMlCatalogProductsManual,
  SearchMlCatalogProductsQuery,
};