import { useManualQuery } from 'graphql-hooks';

const DeleteItem = `
mutation DeleteItem($itemId: String){
    deleteItem(itemId: $itemId){
      status
    }
  }
`;

const useDeleteItemManual = () => {
  const [deleteItem, { loading, error, data: deleteItemResponse }] =
    useManualQuery(DeleteItem);
  return { deleteItem, loading, error, deleteItemResponse };
};

export { DeleteItem, useDeleteItemManual };
