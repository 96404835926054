import { useEffect, useState } from 'react';
import { useGetElegibleCatalogSuggestionsManual } from "../hooks/useGetElegibleCatalogSuggestions";
import ChevronLeft from './images/ChevronLeft.svg';
import ChevronRight from './images/ChevronRight.svg';
import { useOptinToCatalogManual } from '../hooks/useOptinToCatalog';
import uniq from 'lodash/uniq';
import { useGetCatalogSuggestionsByAsinManual } from '../hooks/useGetCatalogSuggestionsByAsin';
import { useGetCatalogSuggestionsByUpcManual } from '../hooks/useGetCatalogSuggestionsByUpc';

const ProductDetails = ({ suggestion, onCancel, onOptin }) => {
    return <div className="min-h-full p-4">
        <div className='flex flex-row'>
            <div className="w-60 h-48 flex-none relative">
                <PictureSelector pictures={suggestion.pictures} />
            </div>
            <div className="shrink p-2">
                {suggestion.name}
            </div>
        </div>
        <div className="grid w-full">
            <div className="grid grid-cols-2 gap-2">
                {
                    suggestion?.attributes?.filter(Boolean)?.map((attribute, index) => {
                        return <div key={attribute.id}>
                            <div className="text-sm font-semibold">{attribute.name}</div>
                            <div className="text-sm">{attribute.value_name}</div>
                        </div>
                    })
                }
            </div>
        </div>
        <div className='flex justify-end space-x-2'>
            <button className="bg-primary-700 text-white font-semibold text-sm px-4 py-2 rounded-lg hover:bg-primary-600" onClick={onCancel}>Cancelar</button>
            <button className="bg-primary-700 text-white font-semibold text-sm px-4 py-2 rounded-lg hover:bg-primary-600" onClick={() => onOptin(suggestion)}>Agregar</button>
        </div>
    </div>;
};

const PictureSelector = ({ pictures }) => {
    const [selectedPicture, setSelectedPicture] = useState(0);

    const handlePreviousClick = (event) => {
        setSelectedPicture(selectedPicture > 0 ? selectedPicture - 1 : pictures.length - 1);
        event.stopPropagation();
    }

    const handleNextClick = (event) => {
        setSelectedPicture(selectedPicture < pictures.length - 1 ? selectedPicture + 1 : 0);
        event.stopPropagation();
    }

    return <div onClick={e => e.preventDefault()}>
        {
            pictures?.length > 1 && <>
                <div className="absolute top-1/2 left-7 -m-3 bg-white w-9 h-9 rounded-full grid place-content-center z-10 select-none cursor-pointer" onClick={handlePreviousClick}>
                    <img src={ChevronLeft} alt="previous" />
                </div>
                <div className="absolute top-1/2 right-7 -m-3 bg-white  z-1  w-9 h-9 rounded-full grid place-content-center z-10 select-none cursor-pointer" onClick={handleNextClick}>
                    <img src={ChevronRight} alt="next" />
                </div>
            </>
        }
        {pictures?.length > 0 && <img className="object-contain w-60 h-48" src={pictures[selectedPicture]?.url || ''} />}
    </div>
}


const CatalogListSuggestion = ({ suggestion, key, isSelected, onSuggestionSelected = () => null }) => {
    return <div key={key} className={`${isSelected ? 'border-primary-700' : 'border-white'} bg-white z-10 font-semibold text-xl p-5  border-2 rounded-xl`} onClick={() => onSuggestionSelected(suggestion)}>
        <div className='flex flex-row'>
            <div className="flex-initial w-60 h-48 relative" >
                <PictureSelector onClick={event => event.preventDefault()} pictures={suggestion.pictures} />
            </div>
            <div className='font-normal flex-1 text-sm text-primary-700 pl-4'>{suggestion.name}</div>
        </div>
    </div>
}

export const CatalogProductsList = ({ id, catalogProduct, sellerCustomField, onSuggestionSelected }) => {
    const { getElegibleCatalogSuggestions, loading: loadingElegibleCatalogSuggestionData, error, data: elegibleCatalogSuggestionData } = useGetElegibleCatalogSuggestionsManual();
    const { getCatalogSuggestionsByAsin, loading: loadingCatalogSuggestionByAsinData, data: catalogSuggestionByAsinData } = useGetCatalogSuggestionsByAsinManual();
    const { getCatalogSuggestionsByUpc, loading: loadingCatalogSuggestionByUpcData, data: catalogSuggestionByUpcData } = useGetCatalogSuggestionsByUpcManual();
    const list = uniq([catalogProduct, ...(catalogSuggestionByAsinData?.getCatalogSuggestionsByAsin || []), ...(catalogSuggestionByUpcData?.getCatalogSuggestionsByUpc || []), ...(elegibleCatalogSuggestionData?.getElegibleCatalogSuggestions || [])].filter(Boolean), 'id');

    useEffect(() => {
        const isAsin = sellerCustomField?.match(/^B0[A-Z0-9]{8}$/);
        if (sellerCustomField && isAsin) {
            getCatalogSuggestionsByAsin({
                variables: {
                    asin: sellerCustomField
                }
            });
        } else if (sellerCustomField && !isAsin) {
            getCatalogSuggestionsByUpc({
                variables: {
                    upc: sellerCustomField
                }
            });
        } else if (id) {
            getElegibleCatalogSuggestions({
                variables: {
                    id
                }
            });
        }
    }, [id, sellerCustomField]);

    if (loadingElegibleCatalogSuggestionData || loadingCatalogSuggestionByAsinData) return "cargando...";
    if (error) return "error";

    return <div className='select-none cursor-pointer'>
        <div className='font-semibold text-xl'>Selecciona el artículo</div>
        <div className='font-normal text-sm mt-1 text-gray-600'>Estos son los articulos encontrados en MercadoLibre, selecciona el tuyo para incluirlo en el catalogo.</div>
        {
            list?.length > 0 && list.map(suggestion => <div key={suggestion.id} className='p-6'>
                <CatalogListSuggestion suggestion={suggestion} onSuggestionSelected={() => onSuggestionSelected(suggestion)} />
            </div>)
        }
    </div>
};

export const CompareElegibleProductModal = ({ product: selectedProduct, sellerCustomField, catalogProduct, onClose }) => {
    const [selectedSuggestion, setSelectedSuggestion] = useState(null);
    const { optinToCatalog, loading: optingIn, errors, data: publishedItem } = useOptinToCatalogManual();

    const permalinkId = selectedProduct?.details?.permalink?.split('/').at(-1).split('-')[1];

    const handleOptin = (suggestion) => {
        optinToCatalog({
            variables: {
                productId: permalinkId ? 'MLU' + permalinkId : selectedProduct.id,
                catalogProductId: suggestion.id
            }
        });
    }

    return <div className='fixed top-0 left-0 bottom-0 w-full backdrop-blur-sm bg-gray-500 bg-opacity-50' onClick={() => onClose()}>
        <div className='fixed top-0 left-1/4 w-3/4 bottom-0 bg-white inline-flex' onClick={event => event.stopPropagation()}>
            <div className="w-1/2 p-6">
                <div className='font-semibold text-xl'>Tu artículo</div>
                <div className='font-normal text-sm mt-1 text-gray-600'>Compara tu artículo con las opciones que da Mercado Libre para agregarlo al catálogo correctamente.</div>
                <img className="mt-6" src={selectedProduct.details.pictures?.[0]?.url} />
                <div className='font-semibold text-xl mt-6'>{selectedProduct.details.title}</div>
            </div>
            <div className="w-1/2 min-h-screen overflow-scroll">
                {selectedProduct && !selectedSuggestion && !errors && <CatalogProductsList id={selectedProduct?.details?.catalog_product_id} catalogProduct={selectedProduct?.catalogProduct} sellerCustomField={sellerCustomField || selectedProduct?.sellerCustomField} onSuggestionSelected={suggestion => setSelectedSuggestion(suggestion)} />}
                {selectedSuggestion && !optingIn && !publishedItem && !errors && <ProductDetails suggestion={selectedSuggestion} onCancel={() => setSelectedSuggestion(null)} onOptin={handleOptin} />}
                {optingIn && <div className='flex justify-center items-center h-full'>Publicando...</div>}
                {publishedItem?.optinToCatalog?.id && <div className='flex justify-center items-center h-full'><a href={`https://articulo.mercadolibre.com.uy/${publishedItem.optinToCatalog.id.replace("MLU", "MLU-")}`} target="_blank" rel="noreferrer">Ir al artículo publicado</a></div>}
                {errors && <div className='flex justify-center items-center h-full'>Error: {JSON.stringify(errors)}</div>}
            </div>
        </div>
    </div>
}
