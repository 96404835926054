import { useQuery, useManualQuery } from 'graphql-hooks';

export const GET_GRID_OPTIONS = `
query GetGridOptions ($domainId: String!) {
  getGridOptions(domainId: $domainId) {
    gridId
    options {
      id
      name
    }
  }
}
`;

export const useGetGridOptions = (variables: { domainId: string }) => {
  const { loading, data } = useQuery(GET_GRID_OPTIONS, {
    variables
  });

  return { loading, data };
}

export const useGetGridOptionsManual = () => {
  const [getGridOptions, { loading, data }] = useManualQuery(GET_GRID_OPTIONS);

  return { getGridOptions, loading, data };
}
