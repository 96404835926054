import React from 'react';

export const CounterViewer = ({ count, onContinue = () => null }: { count: number; onContinue: () => void; }) => {
    return <div className='w-auto h-auto'>
        <img src='http://dev.atibo.io/counter.png' />
        <div className="float font-bold" style={{ marginLeft: '270px', marginTop: '-271px', transform: 'rotate(-3deg)' }}>{count}/500</div>
        <div className="bg-primary-700 color-white text-center cursor-pointer" onClick={onContinue}>
            Continuar castoreando
        </div>
    </div>;
};
