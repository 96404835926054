const PauseIcon = ({ title }) => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth='1.5'
        stroke='currentColor'
        className='w-6 h-6 cursor-pointer text-primary-700'
      >
        <title>{title}</title>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M15.75 5.25v13.5m-7.5-13.5v13.5'
        />
      </svg>
    </>
  );
};

export default PauseIcon;
