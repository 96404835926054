import React from 'react';
import { PlanCard, ConnectedPlanCard } from './PlanCard';
import { InvoiceList, Invoice } from './InvoiceList';

const basicFeatures = [
  { text: "100 productos compitiendo en catálogo", included: true },
  { text: "Un usuario administrador", included: true },
  { text: "Usuarios individuales", included: false },
  { text: "Corrección automática de publicaciones", included: false },
  { text: "Sugerencias inteligentes en respuestas", included: false },
  { text: "Análisis de datos de ventas y marketing", included: false }
];

const premiumFeatures = [
  { text: "300 productos compitiendo en catálogo", included: true },
  { text: "Un usuario administrador", included: true },
  { text: "Hasta 5 usuarios individuales", included: true },
  { text: "Corrección automática de publicaciones", included: true },
  { text: "Sugerencias inteligentes en respuestas", included: false },
  { text: "Análisis de datos de ventas y marketing", included: false }
];

const enterpriseFeatures = [
  { text: "500 productos compitiendo en catálogo", included: true },
  { text: "Hasta 5 usuarios administradores", included: true },
  { text: "Hasta 25 usuarios individuales", included: true },
  { text: "Corrección automática de publicaciones", included: true },
  { text: "Sugerencias inteligentes en respuestas", included: true },
  { text: "Análisis de datos de ventas y marketing", included: true }
];

const sampleInvoices: Invoice[] = [
  {
    id: 'INV-001',
    date: '2024-09-01',
    status: 'Pago',
    amount: 590,
    plan: 'Premium'
  },
  {
    id: 'INV-002',
    date: '2024-08-01',
    status: 'Pago',
    amount: 590,
    plan: 'Premium'
  },
  {
    id: 'INV-003',
    date: '2024-07-01',
    status: 'Pago',
    amount: 590,
    plan: 'Premium'
  }
];

export const PaymentsPage = ({ defaultPlan = 'premium', invoices = sampleInvoices }: { defaultPlan: string, invoices?: Invoice[] }) => {
	const [currentPlan, setCurrentPlan] = React.useState(defaultPlan);

	const getCardType = (planName: string) => {
		return planName === currentPlan ? ConnectedPlanCard : PlanCard;
	};

	const getUpgradeText = (planName: string) => {
		if (planName === currentPlan) return "Tu plan actual";
		const plans = ['basic', 'premium', 'enterprise'];
		const currentIndex = plans.indexOf(currentPlan);
		const planIndex = plans.indexOf(planName);
		return planIndex > currentIndex ? "Mejorar plan" : "Cambiar plan";
	};

	return (
		<div className="m-8" style={{ minWidth: '400px' }}>
			<div className="mb-8">
				<div className="text-4xl font-semibold">Pagos</div>
				<div className="text-gray-500 mt-2">Administra tus pagos y planes</div>
			</div>
			<div className="grid gap-6 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
				{getCardType('basic')({
					title: "Plan Básico",
					description: "Cobrado mensualmente",
					price: 240,
					totalCredits: 10000,
					creditsUsed: 5000,
					features: basicFeatures,
					upgradeText: getUpgradeText('basic'),
				})}
				{getCardType('premium')({
					title: "Plan Premium",
					description: "Cobrado mensualmente",
					price: 590,
					totalCredits: 25000,
					creditsUsed: 12500,
					features: premiumFeatures,
					upgradeText: getUpgradeText('premium'),
				})}
				{getCardType('enterprise')({
					title: "Plan Empresarial",
					description: "Cobrado mensualmente",
					price: 890,
					totalCredits: 50000,
					creditsUsed: 25000,
					features: enterpriseFeatures,
					upgradeText: getUpgradeText('enterprise'),
				})}
			</div>
			<InvoiceList invoices={invoices} />
		</div>
	);
}
