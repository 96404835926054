const { useManualQuery } = require('graphql-hooks');

const AttachFile = `mutation AttachFile($file: String, $fileName: String){
  attachFile(file: $file, fileName: $fileName){
    id
  }
}`;

const useAttachFileManual = () => {
  const [attachFile, { loading, error, data }] = useManualQuery(AttachFile);

  const { id } = data
    ? data.attachFile
    : { id : '' };

  return { attachFile, loading, error, imageId: id };
};

export { useAttachFileManual };
