import React, { useState, useEffect, useRef } from 'react';
import { useSearchMessageBuyerManual } from 'hooks/useSearchMessageBuyer';
import { useSendMessageBuyerManual } from 'hooks/useSendMessageBuyer';
import MessageBox from './MessageBox';
import UploadFileIcon from './UploadFileIcon';
import AddProductOnDemand from '../AnswerSection/addProductOnDemand/AddProductOnDemand';
import { useSearchProductAlternativesManual } from 'hooks/useSearchProductAlternatives';
import { ProviderOptions } from '../AnswerSection/addProductOnDemand/Provider';
import { SelectCatalogOrCopilot } from '../addProduct/manual/SelectCatalogOrCopilot';
import { SendMessageIcon } from 'components/utils/iconComponents';
import { CatalogIcon } from 'components/AnswerSection/icons/CatalogIcon';
import { AIAnswerIcon } from 'components/AnswerSection/icons/AIAnswerIcon';
import { useGetAnswerSuggestionsManual } from 'hooks/useGetAnswerSuggestions';
import { AddCatalogProduct } from 'components/addProduct/catalog/AddCatalogProduct';
import { useGetAnswerSuggestionsPostSaleManual } from 'hooks/useGetAnswerSuggestionsPostSale';

const OrderMessage = ({ orderInfo, selectedLabel, setSelectedLabel }) => {
  const divMessageBox = useRef(null);
  const [counterText, serCounterText] = useState(0);
  const [nickname, setNickname] = useState();
  const [messagesBuyer, setMessagesBuyer] = useState([]);
  const [statusMessage, setStatusMessage] = useState('');
  const [inputMessage, setInputMessage] = useState();
  const [price, setPrice] = useState(0);
  const [gtin, setGtin] = useState(null);
  const [showCopilot, setShowCopilot] = useState(false);
  const [showManualProductDialog, setShowManualProductDialog] = useState(false);
  const { searchMessageBuyer } = useSearchMessageBuyerManual();
  const { sendMessageToBuyer, loading: loadingSentMessage } =
    useSendMessageBuyerManual();
  const [showAddProductOnDemand, setShowAddProductOnDemand] = useState(false);
  const {
    searchProductAlternatives,
    data: productAlternativesData,
    loading: loadingProductAlternatives,
  } = useSearchProductAlternativesManual();
  const { getAnswerSuggestions, data: answerSuggestions } =
    useGetAnswerSuggestionsManual();
  const [selectedProductAlternative, setSelectedProductAlternative] = useState(
    []
  );
  const [catalogProductToAdd, setCatalogProductToAdd] = useState(null);
  const [showAddCatalogProductDialog, setShowAddCatalogProductDialog] =
    useState(false);
  const [productOptions, setProductOptions] = useState({
    products: [],
    searchText: '',
  });

  const { getAnswerSuggestionPostSale } =
    useGetAnswerSuggestionsPostSaleManual();

  useEffect(() => {
    setSelectedProductAlternative(null);
    if (!productAlternativesData?.searchProductAlternatives?.error) {
      setProductOptions(productAlternativesData?.searchProductAlternatives);
    }
  }, [productAlternativesData]);

  useEffect(() => {
    if (orderInfo) {
      setNickname(orderInfo.buyer.nickname);
      setInputMessage('');
      setMessagesBuyer([]);
      setSelectedLabel('');
      setStatusMessage('');
      async function messageBuyerQuery() {
        const pack_id_message = orderInfo.pack_id
          ? orderInfo.pack_id
          : orderInfo.order_id;
        const dataMessages = await searchMessageBuyer({
          variables: {
            orderId: pack_id_message,
          },
        });

        if (dataMessages) {
          setMessagesBuyer(dataMessages.data?.searchMessageBuyer?.messages);
          setStatusMessage(dataMessages.data?.searchMessageBuyer?.status);
        }
      }
      messageBuyerQuery();
    }
  }, [orderInfo]);

  useEffect(() => {
    selectedLabel && setInputMessage(selectedLabel);
  }, [selectedLabel]);

  useEffect(() => {
    if (answerSuggestions?.getAnswerSuggestions) {
      setInputMessage(answerSuggestions.getAnswerSuggestions);
    }
  }, [answerSuggestions]);

  useEffect(() => {
    if (messagesBuyer.length) {
      divMessageBox.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [messagesBuyer.length]);

  useEffect(() => {
    if (inputMessage == '++') {
      setShowAddProductOnDemand(true);
    } else {
      setShowAddProductOnDemand(false);
    }
  }, [inputMessage]);

  useEffect(() => {
    if (
      selectedProductAlternative?.id &&
      selectedProductAlternative?.id.startsWith('MLU')
    ) {
      setInputMessage(
        'https://articulo.mercadolibre.com.uy/MLU-' +
          selectedProductAlternative?.id.replace('MLU', '')
      );
    } else if (
      selectedProductAlternative?.catalogProductId &&
      selectedProductAlternative.provider === ProviderOptions.MERCADOLIBRE
    ) {
      setCatalogProductToAdd({
        id: selectedProductAlternative.catalogProductId,
        name: selectedProductAlternative.name,
        domainId: selectedProductAlternative.domainId,
      });
      setShowAddCatalogProductDialog(true);
    } else if (selectedProductAlternative?.id && !showManualProductDialog) {
      setShowCopilot(true);
    }
  }, [selectedProductAlternative]);

  const answerWithCatalog = (orderInfo) => {
    if (orderInfo) {
      setInputMessage('cargando...');

      const lastMessageBuyer = messagesBuyer.findLast(
        (message) => message.from.user_id !== orderInfo.seller_id
      );

      searchProductAlternatives({
        variables: {
          productTitle: orderInfo.item.title,
          question: lastMessageBuyer?.text || '',
        },
      });
    }
  };

  const answerWithAI = async (orderInfo) => {
    if (orderInfo) {
      setInputMessage('cargando...');

      const lastMessageBuyer = messagesBuyer.findLast(
        (message) => message.from.user_id !== orderInfo.seller_id
      );

      const data = await getAnswerSuggestionPostSale({
        variables: {
          title: orderInfo.item.title,
          message: lastMessageBuyer?.text || 'Cuanto se demora la entrega?',
        },
      });

      console.info(
        'response from ia... -->',
        data?.data?.getAnswerSuggestionPostSale
      );
      setInputMessage(data?.data?.getAnswerSuggestionPostSale || '');
    }
  };

  const handlerSendClick = async () => {
    // setMessagesBuyer([...messagesBuyer, dataResponse]);
    const pack_id_message = orderInfo.pack_id
      ? orderInfo.pack_id
      : orderInfo.order_id;
    const dataSent = await sendMessageToBuyer({
      variables: {
        orderId: pack_id_message,
        buyerId: orderInfo.buyer.id.toString(),
        dataInput: inputMessage,
        attachments: '',
      },
    });

    if (dataSent) {
      setMessagesBuyer([
        ...messagesBuyer,
        dataSent.data.sendMessageToBuyer.messages,
      ]);
    }
    setInputMessage('');
  };

  return (
    <>
      {showAddCatalogProductDialog && (
        <AddCatalogProduct
          product={catalogProductToAdd}
          visible={true}
          onPublish={(newId) => {
            console.log('onPublish', newId);
            setInputMessage(
              'Hola. LE ADJUNTO LINK: https://articulo.mercadolibre.com.uy/MLU-' +
                newId.replace('MLU', '') +
                '. Saluda atentamente, Juan por Kasver UY'
            );
            setShowAddCatalogProductDialog(false);
          }}
          closeButtonLabel='Enviar link'
          asinPrice={price}
          gtin={gtin}
          onCancel={() => setShowAddCatalogProductDialog(false)}
        />
      )}
      {showCopilot && !showManualProductDialog && (
        <SelectCatalogOrCopilot
          identifier={selectedProductAlternative?.id}
          onCancel={() => {
            setShowCopilot(false);
            setSelectedProductAlternative(null);
          }}
          onPublish={(newId) => {
            console.log('onPublish', newId);
            const mercadoLibreLink = `https://articulo.mercadolibre.com.uy/MLU-${newId.replace(
              'MLU',
              ''
            )}`;
            const amazonLink = `https://www.amazon.com/dp/${newId}`;
            const answerText = `Hola. LE ADJUNTO LINK: ${
              newId.startsWith('MLU') ? mercadoLibreLink : amazonLink
            }. Saluda atentamente, Juan por Kasver UY`; // TODO: make this configurable
            setInputMessage(answerText);
            setShowCopilot(false);
          }}
        />
      )}
      {!selectedProductAlternative &&
        productOptions &&
        productOptions?.products?.length > 0 && (
          <AddProductOnDemand
            list={productOptions.products}
            search={productOptions.searchText}
            loading={loadingProductAlternatives}
            onSelected={(item) => setSelectedProductAlternative(item)}
            onCancel={() => {
              setSelectedProductAlternative(null);
              setProductOptions(null);
            }}
            onSearch={(text) =>
              searchProductAlternatives({
                variables: {
                  productTitle: text,
                  question: 'tienen este producto?',
                },
              })
            }
          />
        )}
      <div className='self-stretch bg-white flex-col justify-start items-start flex h-full rounded-xl'>
        <div className='w-full h-[10%] flex justify-end py-2 space-x-4'>
          <button
            className={`bg-primary-50 text-primary-700 border-2 border-primary-200 font-bold rounded-md px-4 py-2 text-sm  ${
              orderInfo ? 'cursor-pointer' : 'cursor-not-allowed'
            }`}
            onClick={() => (orderInfo ? answerWithAI(orderInfo) : null)}
          >
            <div className='flex items-center'>
              <AIAnswerIcon />
              <span className='ml-2'>Respuesta IA</span>
            </div>
          </button>
          <button
            className={`bg-primary-50 text-primary-700 border-2 border-primary-200 font-bold rounded-md px-4 py-2 text-sm ${
              orderInfo ? 'cursor-pointer' : 'cursor-not-allowed'
            }`}
            onClick={() => (orderInfo ? answerWithCatalog(orderInfo) : null)}
          >
            <div className='flex items-center'>
              <CatalogIcon />
              <span className='ml-2'>Venta cruzada</span>
            </div>
          </button>
        </div>
        <div className='w-full h-[90%] bg-white rounded-xl shadow border border-gray-200 flex-col justify-start items-start flex'>
          <div className='block p-4 ext-sm font-normal font-["Inter"] leading-tight w-full h-full'>
            <div className='h-[6%] flex justify-between border-2 border-white items-center'>
              <span className='text-primary-700'>{nickname}</span>
              <span
                className={`rounded-full px-4 py-0.5 font-medium text-white ${
                  statusMessage === 'Bloqueado' ? 'bg-red-500' : 'bg-green-600'
                } ${orderInfo ? 'visible' : 'hidden'}`}
              >
                {statusMessage}
              </span>
            </div>
            <div className='h-[74%] border border-[#D0D5DD] overflow-y-auto rounded-lg'>
              <MessageBox messagesBuyer={messagesBuyer} orderInfo={orderInfo} />
              <div ref={divMessageBox} />
            </div>
            <div className='mt-2 flex'>
              <div className='w-[8%] h-auto mr-2'>
                <div
                  className={`w-full h-[70%] shadow border border-gray-300 rounded-lg flex justify-center p-2 items-center`}
                >
                  <UploadFileIcon
                    orderInfo={orderInfo}
                    messagesBuyer={messagesBuyer}
                    setMessagesBuyer={setMessagesBuyer}
                  />
                </div>
              </div>
              <div className='grow shrink basis-0 self-stretch flex justify-start items-end gap-3 w-[80%]'>
                <textarea
                  className='border border-gray-300 resize-none rounded-lg shadow outline-none p-2 w-full'
                  rows={3}
                  placeholder='Escriba su mensaje'
                  onChange={(e) => {
                    setInputMessage(e.target.value);
                    serCounterText(e.target.value.length);
                  }}
                  value={inputMessage}
                  disabled={!orderInfo ? 'disabled' : ''}
                  maxLength={500}
                ></textarea>
              </div>
              <div className='w-[20%]'>
                <div className='w-full ml-2 h-full flex'>
                  <div className={`w-full`}>
                    <div
                      className={`w-full h-[70%] text-sm font-semibold text-white rounded-lg flex justify-center p-2 items-center ${
                        orderInfo && inputMessage
                          ? 'bg-primary-700 cursor-pointer'
                          : 'bg-primary-200 cursor-not-allowed'
                      }`}
                      onClick={() => {
                        inputMessage && handlerSendClick();
                      }}
                    >
                      <span className='px-1'>
                        <SendMessageIcon />
                      </span>
                      <span className='px-1'>Responder</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-1 flex'>
              <div className='w-[8%] h-auto mr-2'></div>
              <div className='grow shrink basis-0 self-stretch flex justify-end items-end gap-3 w-[80%]'>
                <span>{counterText} / 500</span>
              </div>
              <div className='w-[20%]'></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderMessage;
