import { useQuery, useManualQuery } from 'graphql-hooks';

const SAVE_ATTRIBUTE_TRAINING_EXAMPLE_MUTATION = `
mutation SaveAttributeTrainingExample($json: String!) {
    saveAttributeTrainingExample(json: $json) {
        count
    }
}
`;

const useSaveAttributeTrainingExampleManual = () => {
    const [saveAttributeTrainingExample, { loading, data, error }] = useManualQuery(SAVE_ATTRIBUTE_TRAINING_EXAMPLE_MUTATION);
    return {
        loading,
        error,
        data,
        saveAttributeTrainingExample
    }
}

export {
    SAVE_ATTRIBUTE_TRAINING_EXAMPLE_MUTATION,
    useSaveAttributeTrainingExampleManual
}