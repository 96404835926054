import { useSubscription } from 'graphql-hooks';

export const query = `
subscription UserCreditsSubscription {
    getUserCredits {
        total
        used
        nextResetDate
    }
}
`;

export interface UserCredits {
  total: number;
  used: number;
  nextResetDate: string;
}

export const useUserCreditsSubscription = (onData: (data: UserCredits) => void) => {
  useSubscription({
    query,
    variables: {}
  }, (response: any) => {
    console.log('useUserCreditsSubscription', response);
    const data = response.data;
    if (data) onData(data.getUserCredits);
  });
};
