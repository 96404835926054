const NewModalPerfil = ({ product }) => {
  return (
    <div className='h-[145px] mt-4 flex justify-between bg-white'>
      <div className='w-[30%] flex rounded-[10px] border border-gray-200'>
        <img
          src={product.thumbnail}
          alt='perfil'
          height='145px'
          width='100%'
          className='rounded-[10px]'
        />
      </div>
      <div className='w-[65%]'>
        <div className='h-[50px] flex'>
          <span className='text-base font-semibold text-gray-600 truncate'>
            {product.title}
          </span>
        </div>
        <div className='h-[48px] flex items-end'>
          <div className='w-[50%]'>
            <div className='w-full flex'>
              <span className='text-sm text-gray-600 font-medium'>
                Vendidos
              </span>
            </div>
            <div className='w-full flex'>
              <span className='text-sm text-gray-600'>{product.sold_quantity || 0}</span>
            </div>
          </div>
          <div className='w-[50%]'>
            <div className='w-full flex'>
              <span className='text-sm text-gray-600 font-medium'>
                Disponibles
              </span>
            </div>
            <div className='w-full flex'>
              <span className='text-sm text-gray-600'>
                {product.available_quantity}
              </span>
            </div>
          </div>
        </div>
        <div className='h-[48px] flex justify-between items-end'>
          <span className='text-lg text-gray-600'>
            {product.currency_id + ' ' + product.price}
          </span>
          <span className='h-[24px] rounded-2xl bg-orange-500 px-[12px] text-white font-medium'>
            {product.winnerStatus}
          </span>
        </div>
      </div>
    </div>
  );
};

export default NewModalPerfil;
