import { ExclamationIcon } from './ExclamationIcon';
import { ActionButton } from './ActionButton';

export const ItemInfoAndActions = ({
  dataLoaded,
  mlItem,
  catalogProduct,
  amazonProduct,
  onPublishCatalogProduct,
  onQuickOptin,
  onPublishFromAmazon,
  onConvertToCatalog,
  className,
}) => {
  if (!dataLoaded) return null;
  onPublishCatalogProduct = onPublishCatalogProduct || (() => {});
  onQuickOptin = onQuickOptin || (() => {});
  onPublishFromAmazon = onPublishFromAmazon || (() => {});
  if (!mlItem && catalogProduct && !catalogProduct?.currentBid)
    return (
      <ActionButton
        className={className}
        onClick={() => onPublishCatalogProduct(catalogProduct)}
        message='Publicar desde catálogo'
      />
    );
  if (mlItem?.catalog_listing || catalogProduct?.currentBid)
    return (
      <ExclamationIcon
        className={className}
        message='Este producto ya se encuentra asociado al catálogo'
      />
    );
  if (
    mlItem &&
    !mlItem.catalog_listing &&
    catalogProduct &&
    (!mlItem?.item_relations || mlItem.item_relations.length < 1) &&
    mlItem?.tags?.includes('catalog_listing_eligible')
  )
    return (
      <ActionButton
        className={className}
        onClick={onConvertToCatalog}
        message='Convertir a catálogo'
      />
    );
  if (
    mlItem &&
    !mlItem.catalog_listing &&
    catalogProduct &&
    (!mlItem?.item_relations || mlItem.item_relations.length < 1) &&
    !mlItem?.tags?.includes('catalog_listing_eligible')
  )
    return (
      <ActionButton
        className={className}
        onClick={() => onQuickOptin(mlItem, amazonProduct?.asin)}
        message='Combinar con catálogo'
      />
    );
  if (
    mlItem &&
    !mlItem.catalog_listing &&
    catalogProduct &&
    mlItem?.item_relations?.length > 0
  )
    return (
      <ExclamationIcon
        className={className}
        message='Este producto encuentra asociado a una publicación de catálogo'
      />
    );
  if (mlItem && !catalogProduct)
    return (
      <ExclamationIcon
        className={className}
        message='Este producto ya se encuentra asociado a la cuenta pero no al catálogo'
      />
    );
  if (!mlItem && !catalogProduct && amazonProduct)
    return (
      <ActionButton
        className={className}
        onClick={() => onPublishFromAmazon(amazonProduct.asin)}
        message='Publicar desde Amazon'
      />
    );
  return null;
};
