import NestedSelector from 'components/MLForm/NestedSelector';
import { fetchCategoriesList } from 'hooks/useGetCategoriesFromML';
import { useEffect, useState } from 'react';

const MeliModal = ({ setIsOpenModal, setFinalPath, selectedCaregoryId }) => {
  const [selectedPathCategory, setSelectedPathCategory] = useState([]);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (selectedCaregoryId || selectedCaregoryId === "") {
      fetchCategoriesList(selectedCaregoryId).then((res) => {
        setInitialValues(res?.path_from_root?.map((item) => item.id) || []);
      });
    }
  }, [selectedCaregoryId]);

  const handlerAddFinalPath = () => {
    setFinalPath(selectedPathCategory);
    setIsOpenModal(false);
  };
  
  return (
    <div className='p-3'>
      <div className='fixed top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-50 flex justify-center items-center backdrop-blur-sm'>
        <div className='w-full flex justify-center'>
          <span className='flex absolute w-6/12 justify-end pr-8 pt-4'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='w-6 h-6 cursor-pointer text-gray-400 hover:text-black'
              onClick={() => setIsOpenModal(false)}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6 18 18 6M6 6l12 12'
              />
            </svg>
          </span>
          <div className='w-6/12 bg-white h-auto p-16 rounded'>
            { initialValues && 
              <NestedSelector
                initialValues={initialValues}
                onCategoryPathChanged={(path) => {
                  setSelectedPathCategory(path);
                }}
              />
            }
            <div className='flex justify-end pt-2.5'>
              <button
                className={`bg-primary-100 border border-primary-100 hover:border-primary-500 text-primary-700 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md  transition ease-in-out duration-150`}
                onClick={handlerAddFinalPath}
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeliModal;
