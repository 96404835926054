import { useEffect, useState } from 'react';
import { RangeSelector } from '../RangeSelector';
import { AddRowButtonComponent } from '../utilsComponents/AddRowButtonComponent';
import { RangeSelectorShippingCost } from '../RangeSelectorShippingCost';
import SaveButtonComponent from '../utilsComponents/SaveButtonComponent';

const ShippingMargins = ({
  ranges,
  rangesKg,
  setRangesKg,
  setRanges,
  onSaveSettings,
  onValidatedChanged,
  isValidated,
  onValidatedChangedKg,
  isValidatedKg,
  loading,
}) => {
  const [addRowIconTitle, setAddRowIconTitle] = useState('Agregar fila');

  useEffect(() => {
    const hasEmptyRange = ranges
      .slice(0, -1)
      .some((range, idx) => +range.max !== +ranges[idx + 1].min);
    onValidatedChanged(!hasEmptyRange);
  }, [ranges]);

  useEffect(() => {
    const hasEmptyRangeKg = rangesKg
      .slice(0, -1)
      .some((range, idx) => +range.max !== +rangesKg[idx + 1].min);
    onValidatedChangedKg(!hasEmptyRangeKg);
  }, [rangesKg]);

  const onAddRow = (min, max, position) => {
    const newRanges = [...ranges];
    newRanges.splice(position + 1, 0, { min, max, percentage: 0 });
    setRanges(newRanges);
  };

  const onRemoveRow = (position) => {
    const newRanges = [...ranges];
    newRanges.splice(position, 1);
    setRanges(newRanges);
  };

  const onMinChange = (idx, newValue) => {
    const newRanges = [...ranges];
    newRanges[idx] = {
      ...newRanges[idx],
      min: newValue,
    };
    setRanges(newRanges);
  };

  const onMaxChange = (idx, newValue) => {
    const newRanges = [...ranges];
    newRanges[idx] = {
      ...newRanges[idx],
      max: newValue,
    };
    setRanges(newRanges);
  };

  const onPercentageChange = (idx, newValue) => {
    const newRanges = [...ranges];
    newRanges[idx] = {
      ...newRanges[idx],
      percentage: +newValue,
    };
    setRanges(newRanges);
  };

  // Funciones de costo de envio
  const onAddRowShippingCost = (min, max, position) => {
    const newRanges = [...rangesKg];
    newRanges.splice(position + 1, 0, {
      min,
      max,
      kilograms: 0,
      coin: '0',
    });
    setRangesKg(newRanges);
  };

  const onRemoveRowShippingCost = (position) => {
    const newRanges = [...rangesKg];
    newRanges.splice(position, 1);
    setRangesKg(newRanges);
  };

  const onMinChangeShippingCost = (idx, newValue) => {
    const newRanges = [...rangesKg];
    newRanges[idx] = {
      ...newRanges[idx],
      min: newValue,
    };
    setRangesKg(newRanges);
  };

  const onMaxChangeShippingCost = (idx, newValue) => {
    const newRanges = [...rangesKg];
    newRanges[idx] = {
      ...newRanges[idx],
      max: newValue,
    };
    setRangesKg(newRanges);
  };

  const onPercentageChangeShippingCost = (idx, newValue) => {
    const newRanges = [...rangesKg];
    newRanges[idx] = {
      ...newRanges[idx],
      kilograms: +newValue,
    };
    setRangesKg(newRanges);
  };

  const onCoinChangeShippingCost = (idx, newValue) => {
    const newRanges = [...rangesKg];
    newRanges[idx] = {
      ...newRanges[idx],
      coin: +newValue,
    };
    setRangesKg(newRanges);
  };
  return (
    <div className='flex flex-col justify-between h-full'>
      <div className='w-full flex max-h-[580px] h-[85%]'>
        <div className='w-[50%] border-2 border-r-primary-100 border-x-white border-y-white overflow-auto'>
          <div className='mb-2'>
            <span>
              <h2>% ganancia sobre el valor del producto en Amazon</h2>
            </span>
          </div>
          {ranges.map((range, idx) => (
            <>
              <RangeSelector
                min={+range.min}
                max={+range.max}
                percentage={range.percentage}
                idx={idx}
                ranges={ranges}
                onMinChange={(newValue) => onMinChange(idx, newValue)}
                onMaxChange={(newValue) => onMaxChange(idx, newValue)}
                onPercentageChange={(newValue) =>
                  onPercentageChange(idx, newValue)
                }
                onRemoveRow={onRemoveRow}
              />
              {idx < ranges.length - 1 &&
                +range.max !== +ranges[idx + 1].min && (
                  <AddRowButtonComponent
                    position={idx}
                    ranges={ranges}
                    onAddRow={onAddRow}
                    title={addRowIconTitle}
                  />
                )}
            </>
          ))}
        </div>
        <div className='w-[50%] ml-4 overflow-auto'>
          <div className='mb-2'>
            <span>
              <h2>Costo del envio por Kg del envio del courrier</h2>
            </span>
          </div>
          {rangesKg.map((rangeKg, idx) => (
            <>
              <RangeSelectorShippingCost
                min={+rangeKg.min}
                max={+rangeKg.max}
                kilograms={rangeKg.kilograms}
                coin={rangeKg.coin}
                idx={idx}
                ranges={rangesKg}
                onMinChange={(newValue) =>
                  onMinChangeShippingCost(idx, newValue)
                }
                onMaxChange={(newValue) =>
                  onMaxChangeShippingCost(idx, newValue)
                }
                onPercentageChange={(newValue) =>
                  onPercentageChangeShippingCost(idx, newValue)
                }
                onCoinChange={(newValue) =>
                  onCoinChangeShippingCost(idx, newValue)
                }
                onRemoveRow={onRemoveRowShippingCost}
              />
              {idx < rangesKg.length - 1 &&
                +rangeKg.max !== +rangesKg[idx + 1].min && (
                  <AddRowButtonComponent
                    position={idx}
                    ranges={rangesKg}
                    onAddRow={onAddRowShippingCost}
                    title={addRowIconTitle}
                  />
                )}
            </>
          ))}
        </div>
      </div>
      <div className='w-full flex mt-2 justify-end'>
        <SaveButtonComponent
          onSaveSettings={onSaveSettings}
          isValidated={isValidated}
          isValidatedKg={isValidatedKg}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ShippingMargins;
