import React from 'react';

export const ActionButton = ({
  onClick,
  message,
}: {
  onClick: () => void;
  message: string;
}) => {
  onClick = onClick || (() => {});
  message = message || 'Publicar';
  return (
    <div className='pl-2 pt-2 pb-2 pr-6'>
        <div
          className={`text-white bg-primary-700 border border-primary-700 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md  transition ease-in-out duration-150'`}
          onClick={onClick}
        >
          <div>
            {message}
          </div>
        </div>
    </div>
  );
};
