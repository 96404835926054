import { useManualQuery } from 'graphql-hooks';

const EditBidStatusMutation = `
mutation updateBidStatus ($id: String, $active:Boolean){
    updateBidStatusById(id:$id, active:$active){
      id
      active
      minPrice
      maxPrice
      currency
      productId
    }
  }
`;
  
  const useEditBidStatusManual = () => {
    const [updateBidStatus, { loading, error, data: bidResponse }] = useManualQuery(EditBidStatusMutation);
    return { updateBidStatus, loading, error, bidResponse };
  };
  
  export {
    EditBidStatusMutation,
    useEditBidStatusManual,
  };