import { useManualQuery } from 'graphql-hooks';

export const CHANGE_SHIPPING_OPTION_MUTATION = `
mutation ChangeShippingOption($productId: String!, $shippingOptionId: ShippingOption!) {
  changeShippingOption(productId: $productId, shippingOptionId: $shippingOptionId) {
    success
    message
  }
}
`;

export const useChangeShippingOptionManual = () => {
  const [changeShippingOption, { loading, error, data }] = useManualQuery(CHANGE_SHIPPING_OPTION_MUTATION);
  return {
    loading,
    error,
    data,
    changeShippingOption,
  };
}
