import { useQuery, useManualQuery } from 'graphql-hooks';

const UpdateArticleStatusMutation = `
  mutation UpdateArticleStatus($item:String, $status:String) {
    updateArticleStatus (item: $item, status:$status) {
      status
    }
  }
`;

const useUpdateArticleStatusManual = () => {
  const [updateArticleStatus, { loading, error, data }] = useManualQuery(
    UpdateArticleStatusMutation
  );
  const { status } = data
    ? data.updateArticleStatus
    : { status : '' };
  return { updateArticleStatus, loading, error, status };
};

export { useUpdateArticleStatusManual };
