import React from 'react';
import { ProgressBarNew } from './ProgressBarNew';

export const ProgressDialogNew = ({
  title,
  progress,
  onCancel,
}: {
  title: string;
  progress: number;
  onCancel: () => void;
}) => {
  const boundedProgress = Math.max(0, Math.min(progress, 100));
  return (
    <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50'>
      <div className='bg-white pt-12 pr-4 pb-4 pl-4 rounded-md shadow-sm w-[52%] h-40 flex flex-col items-center justify-center'>
        <div className='text-center font-bold text-lg mb-4'>{title}</div>
        <ProgressBarNew progress={boundedProgress} />
        <div className='flex justify-end mt-4 w-full'></div>
      </div>
    </div>
  );
};
