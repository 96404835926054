import React, { Fragment, useState, useEffect } from 'react';
import AlertMessage from 'components/utils/AlertMessage';
import AnswerLabel from './labels/AnswerLabel';
import { DANGERMSGALERT } from 'components/utils/constants';
import { useAnswerQuestionManual } from 'hooks/useAnswerQuestion';
import { useGetAnswerSuggestionsManual } from 'hooks/useGetAnswerSuggestions';
import { useSearchProductAlternativesManual } from 'hooks/useSearchProductAlternatives';
import { AnswerLoading } from 'components/utils';
import AddProductOnDemand from './addProductOnDemand/AddProductOnDemand';
import { AddCatalogProduct } from 'components/addProduct/catalog/AddCatalogProduct';
import { SelectCatalogOrCopilot } from 'components/addProduct/manual/SelectCatalogOrCopilot';
import { ProviderOptions } from './addProductOnDemand/Provider';
import QuestionCard from './QuestionCard';

function AnswerSection({
  item,
  userEnteredAnswers,
  loadingSearchLabels,
  errorSearchLabels,
  labelError,
  labels,
  handlerLabelAnswer,
  handlerDeleteQuestion,
  setAsnwerQuestionId,
  handlerShowWindow,
  setUserEnteredAnswers,
  loadedMessages,
  setLoadedMessages,
  showAnswerQuestionButton,
}) {
  const { getAnswerSuggestions, data: answerSuggestions } = useGetAnswerSuggestionsManual();
  const { searchProductAlternatives, data: productAlternativesData, loading: loadingProductAlternatives } = useSearchProductAlternativesManual();
  const [selectedProductAlternative, setSelectedProductAlternative] = useState([]);
  const [showAddCatalogProductDialog, setShowAddCatalogProductDialog] = useState(false);
  const [showManualProductDialog, setShowManualProductDialog] = useState(false);
  const [catalogProductToAdd, setCatalogProductToAdd] = useState(null);
  const [productOptions, setProductOptions] = useState({
    products: [],
    searchText: '',
  });
  const [showCopilot, setShowCopilot] = useState(false); // TODO: remove this line and the next one
  const [price, setPrice] = useState(0);
  const [gtin, setGtin] = useState(null);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);

  useEffect(() => {
    setSelectedProductAlternative(null);
    if (!productAlternativesData?.searchProductAlternatives?.error) {
      setProductOptions(productAlternativesData?.searchProductAlternatives);
    }
  }, [productAlternativesData]);


  useEffect(() => {
    if (selectedProductAlternative?.id && selectedProductAlternative?.id.startsWith('MLU')) {

      setUserEnteredAnswers({
        ...userEnteredAnswers,
        [item.id]: 'https://articulo.mercadolibre.com.uy/MLU-' + selectedProductAlternative?.id.replace('MLU', ''),
      });
    } else if (selectedProductAlternative?.catalogProductId && selectedProductAlternative.provider === ProviderOptions.MERCADOLIBRE) {
      setCatalogProductToAdd({ id: selectedProductAlternative.catalogProductId, name: selectedProductAlternative.name, domainId: selectedProductAlternative.domainId });
      setShowAddCatalogProductDialog(true);
    } else if (selectedProductAlternative?.id && !showManualProductDialog) {
      setShowCopilot(true);
    }
  }, [selectedProductAlternative]);

  const {
    answerQuestion,
    loading: loadingAnswerQuestion,
  } = useAnswerQuestionManual();

  const answerWithAI = (item) => {
    setUserEnteredAnswers({
      ...userEnteredAnswers,
      [item.id]: 'cargando...',
    });
    const selectedQuestion = loadedMessages.find(
      (question) => question.id === item.id
    );
    setSelectedQuestionId(item.id);
    getAnswerSuggestions({
      variables: { query: item.text, sku: item.skuItem, type: 'answer' },
    });
  }

  const answerWithCatalog = (item) => {
    setUserEnteredAnswers({
      ...userEnteredAnswers,
      [item.id]: 'cargando...',
    });
    const selectedQuestion = loadedMessages.find(
      (question) => question.id === item.id
    );
    searchProductAlternatives({
      variables: { productTitle: item.itemName, question: selectedQuestion.text },
    });
  }

  const handleAnswerChange = async (questionId, value) => {
    if (value[value.length - 1] === '@') {
      if (value[value.length - 2] === ' ' || value.length === 1) {
        const newValue = value.slice(0, -1);
        setAsnwerQuestionId(questionId);
        setSelectedQuestionId(questionId);
        handlerShowWindow(true);
        value = newValue;
      }
    }

    if (value === '**') {
      answerWithAI(item);
    } else if (value === '++') {
      answerWithCatalog(item);
    } else {
      setUserEnteredAnswers({
        ...userEnteredAnswers,
        [questionId]: value,
      });
    }
  };

  useEffect(() => {
    if (answerSuggestions?.getAnswerSuggestions) {
      setUserEnteredAnswers({
        ...userEnteredAnswers,
        [selectedQuestionId]: answerSuggestions.getAnswerSuggestions,
      });
    }
  }, [answerSuggestions, selectedQuestionId]);

  const handleAnswerQuestion = async (questionId) => {
    console.log('Respondiendo la pregunta', questionId);

    const result = await answerQuestion({
      variables: {
        questionId: Number(questionId),
        answer: userEnteredAnswers[questionId],
      },
    });

    if (result.data.answerQuestion.status == 'ok') {
      // remove answer text from userEnteredAnswers
      setUserEnteredAnswers({
        ...userEnteredAnswers,
        [questionId]: '',
      });
      // remove question from loadedMessages
      setLoadedMessages(
        loadedMessages.filter((question) => question.id !== questionId)
      );
    }
  };


  const sendAnswerEnabled = userEnteredAnswers[item.id]?.length;
  return (
    <Fragment>
      {loadingAnswerQuestion && <AnswerLoading />}
      {showAddCatalogProductDialog && <AddCatalogProduct
        product={catalogProductToAdd}
        visible={true}
        onPublish={(newId) => {
          console.log('onPublish', newId);
          setUserEnteredAnswers({
            ...userEnteredAnswers,
            [item.id]: 'Hola. LE ADJUNTO LINK: https://articulo.mercadolibre.com.uy/MLU-' + newId.replace('MLU', '') + '. Saluda atentamente, Juan por Kasver UY', // TODO: make this configurable
          });
          setShowAddCatalogProductDialog(false);
        }}
        closeButtonLabel='Enviar link'
        asinPrice={price}
        gtin={gtin}
        onCancel={() => setShowAddCatalogProductDialog(false)} />}
      {showCopilot && !showManualProductDialog && <SelectCatalogOrCopilot
        identifier={selectedProductAlternative?.id}
        onCancel={() => {
          setShowCopilot(false);
          setSelectedProductAlternative(null);
        }}
        onPublish={(newId) => {
          console.log('onPublish', newId);
          const mercadoLibreLink = `https://articulo.mercadolibre.com.uy/MLU-${newId.replace('MLU', '')}`;
          const amazonLink = `https://www.amazon.com/dp/${newId}`;
          const answerText = `Hola. LE ADJUNTO LINK: ${newId.startsWith('MLU') ? mercadoLibreLink : amazonLink}. Saluda atentamente, Juan por Kasver UY`; // TODO: make this configurable
          setUserEnteredAnswers({
            ...userEnteredAnswers,
            [item.id]: answerText
          });
          setShowCopilot(false);
        }}
      />}
      {!selectedProductAlternative && productOptions &&
        productOptions?.products?.length > 0 && <AddProductOnDemand
          list={productOptions.products}
          search={productOptions.searchText}
          loading={loadingProductAlternatives}
          onSelected={(item) => setSelectedProductAlternative(item)}
          onCancel={() => {
            setSelectedProductAlternative(null);
            setProductOptions(null);
          }}
          onSearch={(text) => searchProductAlternatives({ variables: { productTitle: text, question: "tienen este producto?" } })}
        />
      }
      <div className={`${loadingAnswerQuestion && 'hidden'}`}>
        <QuestionCard
          item={item}
          loadingSearchLabels={loadingSearchLabels}
          errorSearchLabels={errorSearchLabels}
          labelError={labelError}
          labels={labels}
          handlerLabelAnswer={handlerLabelAnswer}
          userEnteredAnswers={userEnteredAnswers}
          handleAnswerChange={handleAnswerChange}
          showAnswerQuestionButton={showAnswerQuestionButton}
          sendAnswerEnabled={sendAnswerEnabled}
          answerWithAI={answerWithAI}
          answerWithCatalog={answerWithCatalog}
          handleAnswerQuestion={handleAnswerQuestion}
        />
      </div>
    </Fragment>
  );
}

export default AnswerSection;
