import { useManualQuery } from 'graphql-hooks';

export const GET_STORE_ITEMS_STATS_QUERY = `
  query GetStoreItemsStats($period: String!) {
    getStoreItemsStats(period: $period) {
      winningInCatalog
      losingInCatalog
      marketplaceEligible
      marketplaceNotEligible
      unconfirmedMarketplace
      unconfirmedCatalog
      totalActive
      timestamp
    }
  }
`;

export const useGetStoreItemsStats = () => {
  const [getStoreItemsStats, { loading, error, data }] = useManualQuery(GET_STORE_ITEMS_STATS_QUERY);

  return {
    getStoreItemsStats,
    loading,
    error,
    data,
  }
}
