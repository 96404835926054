import { FetchData } from 'graphql-hooks';
import React, { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import {
  AddProductBasicFormValues,
  DomainInfo,
} from '../types/AddCatalogProduct.types';
import { AddCatalogProductFormControls } from './AddCatalogProductFormControls';

const formDefaultValues: AddProductBasicFormValues = {
  productCategory: '',
  productDescription: '',
  productName: '',
  currency: 'UYU',
  price: 0,
  stock: 12,
  productImages: [],
  attributes: '',
};

function AddCatalogProductForm({
  setShowCreationProductResult,
  publishProduct,
  handleCancel,
  productName,
  domainInfo,
  productId,
  asinPrice,
  sku,
}: {
  setShowCreationProductResult: (id: boolean) => void;
  publishProduct: FetchData<any, object, object>;
  handleCancel: () => void;
  productName: string;
  domainInfo: DomainInfo[];
  productId: string;
  asinPrice: number;
  sku?: string;
}) {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: { ...formDefaultValues, productName },
  });

  const [productCategory, setProductCategory] = useState<DomainInfo | null>();
  const [currencyOptions, setCurrencyOptions] = useState<string[]>([]);
  const [currencyValue, setCurrencyvalue] = useState<string>('UYU');

  useEffect(() => {
    setProductCategory(domainInfo?.length === 1 ? domainInfo[0] : null);
  }, [domainInfo]);

  useEffect(() => {
    if (productCategory) {
      setCurrencyOptions(productCategory.settings?.currencies);
    }
  }, [productCategory]);

  useEffect(() => {
    if (asinPrice > 0) {
      setValue('price', asinPrice);
      setCurrencyvalue("UYU");
    }
  }, []);

  const onSubmitForm = async (formData: FieldValues) => {
    const { currency, price, stock, productCategory } = formData;

    const categoryId =
      domainInfo.length === 1
        ? domainInfo[0].id
        : productCategory
        ? productCategory
        : null;

    try {
      if (categoryId === null) {
        throw new Error('categoryId null during publishing product');
      }

      const payload = {
        productId: productId,
        currency,
        price: parseFloat(price),
        stock: parseInt(stock),
        categoryId: productCategory || categoryId,
        sku: sku,
      };
      console.log('>> payload: ', payload);

      const { data } = await publishProduct({
        variables: payload,
      });

      console.log('>> responsePublish: ', data);
      const newProductId = data?.publishProductFromCatalog?.id;

      if (newProductId) {
        setShowCreationProductResult(newProductId);
      }
    } catch (errInfo) {
      console.log(`Error - Payload creation error: ${errInfo}`);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <AddCatalogProductFormControls
        productId={productId}
        errors={errors}
        domainInfo={domainInfo}
        productName={productName}
        currencyOptions={currencyOptions}
        productCategory={productCategory}
        register={register}
        clearErrors={clearErrors}
        handleCancel={handleCancel}
        setProductCategory={setProductCategory}
        currencyValue={currencyValue}
      />
    </form>
  );
}

export { AddCatalogProductForm };
